//--- Auth and user management

export const AUTH_TYPE = "AUTH";
export const LOGIN_ACTION = "AUTH_LOGIN";
export const LOGOUT_ACTION = "AUTH_LOGOUT";
export const ACTIVATE_ACTION = "AUTH_ACTIVATE";
export const RE_ACTIVATE_ACTION = "AUTH_RE_ACTIVATE";
export const SET_PASSWORD_ACTION = "AUTH_SET_PASSWORD";
export const RESET_PASSWORD_ACTION = "AUTH_RESET_PASSWORD";
export const RESET_PASSWORD_CONFIRM_ACTION = "AUTH_CONFIRM_RESET_PASSWORD";

export const DOWNLOAD_ALL_ACTION = "DOWNLOAD_ALL";
export const RESET_ACTION = "RESET_DATA";
export const CONTACT_ACTION = "AUTH_CONTACT"; //must be "auth_type" so that middleware handle api call correctly
