import { useMemo } from "react";
import { useSelector } from "react-redux";

import { getLogForDate, getLicence, getZones } from "selectors/entities";
import { getCurrentStats, getDeadlines, getTargets } from "selectors/stats";

export function useLogForDate(date) {
  return useSelector(getLogForDate(date));
}

export function useLicence() {
  return useSelector(getLicence);
}

export function useDeadlines() {
  return useSelector(getDeadlines);
}

export function useTargets() {
  return useSelector(getTargets);
}

export function useLastRenewal() {
  const licence = useLicence();
  return useMemo(() => (licence ? licence.last_renewal : undefined), [licence]);
}

export function useZones() {
  return useSelector(getZones);
}

export function useCurrentStats() {
  return useSelector(getCurrentStats);
}
