import React, { Fragment } from "react";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Container,
  Typography,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Menu } from "mdi-material-ui";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    padding: theme.spacing(3),
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: theme.spacing(4),
  },
  loading: {
    padding: theme.spacing(1),
  },
}));

export default function Loading() {
  const classes = useStyles();
  return (
    <Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              className={classes.menuButton}
            >
              <Menu />
            </IconButton>
            <Typography
              variant="h5"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Icnalog Web
            </Typography>
          </Toolbar>
        </AppBar>

        <main className={classes.content}>
          <Container maxWidth={false} className={classes.container}>
            <CircularProgress className={classes.loading} size={60} />
          </Container>
        </main>
      </div>
    </Fragment>
  );
}
