import React from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { Divider } from "@material-ui/core";

import {
  DELETE_PROFILE_STATE,
  DOWNLOAD_ALL_STATE,
  RESET_ACCOUNT_STATE,
  IMPORT_CSV_STATE,
  EXPORT_STATE,
} from "constants/status";
import { useAuthState } from "hooks";
import {
  delete_me,
  download_all,
  reset,
  import_csv,
  export_as_csv,
} from "actions/user";

import PageContainer from "components/utils/PageContainer";
import StateButton from "components/utils/StateButton";
import { useConfirmationDialog } from "components/utils/ConfirmationDialog";
import { useConfirmationWithPasswordDialog } from "./ConfirmationWithPasswordDialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  danger: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: theme.palette.error.main,
      },
    },
  },
  outlinedDanger: {
    color: theme.palette.error.main,
    border: `1px solid ${fade(theme.palette.error.main, 0.5)}`,
    "&:hover": {
      border: `1px solid ${theme.palette.error.main}`,
      backgroundColor: fade(
        theme.palette.error.main,
        theme.palette.action.hoverOpacity * 0.8
      ),
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  input: {
    display: "none",
  },
}));

const useDeleteAccountConfirmationDialog = (onConfirm) =>
  useConfirmationWithPasswordDialog({
    auth_type: DELETE_PROFILE_STATE,
    title: "Supprimer mon compte",
    message:
      "Etes-vous sûr de vouloir supprimer votre compte ? L'ensemble de vos données sera supprimé DEFINITIVEMENT. Vous pouvez les télécharger au préalable en utilisant le bouton dédié.",
    onConfirm,
    keepOpen: true,
  });

const useResetDataConfirmationDialog = (onConfirm) =>
  useConfirmationWithPasswordDialog({
    auth_type: RESET_ACCOUNT_STATE,
    title: "Réinitialiser mes données",
    message:
      "Etes-vous sûr de vouloir réinitialiser vos données ? Vos logs, vos archives, vos statistiques et les données de votre licence seront supprimés DEFINITIVEMENT. Vous pouvez les télécharger au préalable en utilisant le bouton dédié.",
    onConfirm,
  });

const useCSVImportConfirmationDialog = (onConfirm) => {
  const [file, setFile] = React.useState(null);

  const dialog = useConfirmationDialog({
    title: "Importer depuis ce fichier CSV",
    messages: [
      `Etes-vous sûr de vouloir importer les heures présentes dans le fichier ${
        file && file.name
      } ?`,
      "",
      "Les valeurs doivent être indiquées en heures. Une ligne doit avoir la forme : 'dd/mm/yyyy;<h réel>;<h isp>;<h simu>;<h double>;<total>;<zone 1>;<zone 2>;...'",
      "Par exemple, '10/05/2019;3,25;1,75;0;0;5,0;2;4;'",
      "",
      "Cette opération peut prendre un certain temps, selon la taille du fichier que vous importez. Merci de ne pas fermer votre navigateur avant la fin de l'import.",
    ],
    onConfirm: () => onConfirm(file),
    onCancel: () => setFile(null),
  });

  const open = (e) => {
    try {
      const file = e.target.files[0];
      // no file type or extension check : not reliable on windows
      setFile(file);
      dialog.open();
    } catch (TypeError) {
      setFile(null);
    }
  };
  return { file, setFile, open, dialog };
};

export default function UserManagement(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const deletion_dialog = useDeleteAccountConfirmationDialog((password) =>
    dispatch(delete_me(password))
  );
  const reset_dialog = useResetDataConfirmationDialog((password) =>
    dispatch(reset(password))
  );
  const import_dialog = useCSVImportConfirmationDialog((file) =>
    dispatch(import_csv(file))
  );
  const download_state = useAuthState(DOWNLOAD_ALL_STATE);
  const reset_state = useAuthState(RESET_ACCOUNT_STATE);
  const import_state = useAuthState(IMPORT_CSV_STATE);
  const export_state = useAuthState(EXPORT_STATE);

  return (
    <PageContainer title="Mes données">
      <input
        accept="text/csv"
        className={classes.input}
        id="csv-import"
        type="file"
        onChange={import_dialog.open}
      />
      <label htmlFor="csv-import">
        <StateButton
          state={import_state}
          title="Importer fichier CSV"
          fullWidth
          variant="outlined"
          color="primary"
          className={classes.button}
          component="span"
        />
      </label>
      <StateButton
        state={export_state}
        title="Exporter fichier CSV"
        fullWidth
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={() => dispatch(export_as_csv({}))}
      />
      <Divider />
      <StateButton
        state={download_state}
        title="Télécharger mes données"
        fullWidth
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={() => dispatch(download_all())}
      />
      <Divider />
      <StateButton
        state={reset_state}
        title="Réinitialiser mon compte"
        fullWidth
        variant="outlined"
        color="primary"
        onClick={reset_dialog.open}
        className={clsx(classes.button, classes.outlinedDanger)}
      />
      <StateButton
        state={deletion_dialog.state}
        title="Supprimer mon compte"
        fullWidth
        variant="contained"
        color="primary"
        className={clsx(classes.button, classes.danger)}
        onClick={deletion_dialog.open}
      />
      {deletion_dialog.display}
      {reset_dialog.display}
      {import_dialog.dialog.display}
    </PageContainer>
  );
}
