import { createInstance } from "@datapunt/matomo-tracker-react";
import {
  DEBUG,
  MATOMO_ENABLED,
  MATOMO_SITE_ID,
  MATOMO_DOMAIN,
} from "constants/config";

const configureMatomo = () => {
  if (!DEBUG && MATOMO_ENABLED) {
    const instance = createInstance({
      urlBase: MATOMO_DOMAIN,
      siteId: MATOMO_SITE_ID,
      linkTracking: false,
    });
    return instance;
  }
};

export default configureMatomo;
