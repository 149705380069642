import React from "react";
import { Container, Grid, Tabs, Tab, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import StickySecondaryToolbar from "components/utils/StickySecondaryToolbar";
import ContactForm from "./ContactForm";
import FAQ from "./FAQ";
import AndroidApp from "./AndroidApp";
import { useTab } from "hooks/ui";

const useStyles = makeStyles((theme) => ({
  tabs: {
    width: "100%",
  },
  page: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
}));

export default function HelpPage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const { tab, onChange } = useTab(0);

  return mdUp ? (
    <Container maxWidth="lg" disableGutters>
      <Grid container spacing={3} className={classes.page}>
        <Grid item xs={12} lg={8}>
          <FAQ />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <AndroidApp />
          <ContactForm />
        </Grid>
      </Grid>
    </Container>
  ) : (
    <div>
      <StickySecondaryToolbar>
        <Tabs
          className={classes.tabs}
          variant="fullWidth"
          value={tab}
          onChange={onChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="FAQ" />
          <Tab label="Android" />
          <Tab label="Contact" />
        </Tabs>
      </StickySecondaryToolbar>
      <Container disableGutters maxWidth="xs">
        {tab === 0 && <FAQ />}
        {tab === 1 && <AndroidApp />}
        {tab === 2 && <ContactForm />}
      </Container>
    </div>
  );
}
