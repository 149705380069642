import React from "react";
import { matchPath } from "react-router";
import { Redirect } from "react-router-dom";
import moment from "moment";

import AccountPage from "components/pages/AccountPage";
import LicencePage from "components/pages/LicencePage";
import InputPage from "components/pages/InputPage";
import ListPage from "components/pages/ListPage";
import StatPage from "components/pages/StatPage";
import HelpPage from "components/pages/HelpPage";
import ChangelogPage from "components/pages/ChangelogPage";
import Error404 from "components/Errors";

import SignIn from "components/pages/authentication/SignIn";
import SignUp from "components/pages/authentication/SignUp";
import Activate from "components/pages/authentication/Activate";
import RequestActivation from "components/pages/authentication/RequestActivation";
import ResetPassword from "components/pages/authentication/ResetPassword";
import RequestResetPassword from "components/pages/authentication/RequestResetPassword";

import { DATE_FORMAT_DATA } from "constants/ui";

export const ROUTES = {
  home: {
    path: "/",
    exact: true,
    component: (
      <Redirect
        to={{
          pathname: "/input",
        }}
      />
    ),
  },
  login: {
    path: "/login",
    is_public: true,
    component: <SignIn />,
  },
  register: {
    path: "/register",
    is_public: true,
    component: <SignUp />,
  },
  activate: {
    path: "/activate/:uid/:token",
    is_public: true,
    component: <Activate />,
  },
  re_activate: {
    path: "/activate",
    is_public: true,
    component: <RequestActivation />,
  },
  reset_password_confirm: {
    path: "/reset_password/:uid/:token",
    is_public: true,
    component: <ResetPassword />,
  },
  reset_password: {
    path: "/reset_password",
    is_public: true,
    component: <RequestResetPassword />,
  },
  /* private routes */
  account: {
    path: "/account",
    title: "Mon compte",
    is_private: true,
    component: <AccountPage />,
  },
  licence: {
    path: "/licence",
    title: "Licence",
    is_private: true,
    component: <LicencePage />,
  },
  input: {
    path: "/input/:date",
    title: "Saisie",
    is_private: true,
    component: <InputPage />,
  },
  today: {
    path: "/input",
    title: "Aujourd'hui",
    is_private: true,
    component: (
      <Redirect
        to={{
          pathname: `/input/${moment().format(DATE_FORMAT_DATA)}`,
        }}
      />
    ),
  },
  list: {
    path: "/list",
    title: "Liste",
    is_private: true,
    component: <ListPage />,
  },
  stat: {
    path: "/stats",
    title: "Statistiques",
    is_private: true,
    component: <StatPage />,
  },
  help: {
    path: "/help",
    title: "Aide",
    component: <HelpPage />,
  },
  changelog: {
    path: "/app/changelog",
    title: "Notes de version",
    component: <ChangelogPage />,
  },
};

export const ERROR_ROUTES = {
  error404: {
    path: "*",
    component: <Error404 />,
  },
};

export function isInputPage(path) {
  return Boolean(matchPath(path, ROUTES.input));
}

export function getDateFromPath(path) {
  const match = matchPath(path, ROUTES.input);
  if (match) {
    if (match.params.date) {
      return moment(match.params.date);
    } else {
      return moment();
    }
  } else {
    return null;
  }
}

export function getRouteForPath(path) {
  for (let key in ROUTES) {
    const route = ROUTES[key];
    const match = matchPath(path, route);
    if (match) {
      return route;
    }
  }
  return {};
}
