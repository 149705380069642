import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  CssBaseline,
  Container,
  AppBar,
  useMediaQuery,
} from "@material-ui/core";

import MyToolbar from "./navigation/MyToolbar";
import MyDrawer from "./navigation/MyDrawer";
import Notifier from "./Notifier";

import { DRAWER_WIDTH } from "constants/ui";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    justifyContent: "stretch",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -DRAWER_WIDTH,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    boxShadow: theme.shadows[1],
  },
  drawerHeader: {
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  innerContent: { flexGrow: 1 },
}));

SlideInLayout.propTypes = {
  children: PropTypes.any,
  onDrawerOpen: PropTypes.func,
  onDrawerClose: PropTypes.func,
  open: PropTypes.bool,
};

export default function SlideInLayout({
  open,
  onDrawerToggle,
  children,
  ...props
}) {
  const classes = useStyles();
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <MyToolbar onDrawerOpenClick={onDrawerToggle} />
      </AppBar>

      <MyDrawer
        open={open}
        stay={lgUp}
        onClose={onDrawerToggle}
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        classes={{
          paper: classes.drawerPaper,
        }}
      />
      <Notifier />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Container
          maxWidth={false}
          disableGutters
          className={classes.innerContent}
        >
          {React.cloneElement(children, { drawerIsOpen: open })}
        </Container>
      </main>
    </div>
  );
}
