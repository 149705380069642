import axios from "axios";
import * as apiConfig from "constants/api";
import { DEBUG } from "constants/config";
import {
  computeUrl,
  shouldSendParams,
  computeHeaders,
  computeBody,
  formatBufferResponse,
  shouldIncreaseTimeout,
} from "./helpers";
import * as methods from "constants/methods";

const api = (method, action, token) => {
  const headers = computeHeaders(action, token);
  const url = `${apiConfig.API_URI}/${computeUrl(method, action)}`;
  const responseIsBuffer = action.meta.mimeType === apiConfig.MIMETYPE_PDF;
  const config = { method, url, headers };
  if (method !== methods.GET_METHOD) {
    config.data = computeBody(action);
  }
  if (shouldSendParams(action)) {
    config.params = { ...config.params, ...action.params };
  }
  if (responseIsBuffer) {
    config.responseType = "arraybuffer";
  }
  if (shouldIncreaseTimeout(action)) {
    config.timeout = 120000;
  }
  if (DEBUG) {
    console.info("Calling", url, "with", config.params, "or", config.data);
  }
  return axios
    .request(config)
    .then((response) =>
      responseIsBuffer ? formatBufferResponse(response) : response.data
    );
};

export default api;
