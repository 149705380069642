import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import PageContainer from "components/utils/PageContainer";
import LogDetailedData, {
  SkeletonDetailedData,
} from "components/utils/LogDetailedData";

Values.propTypes = {
  log: PropTypes.object,
  title: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "initial",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function Values({ log, loading, title, ...props }) {
  const classes = useStyles();
  return (
    <PageContainer title={title} className={classes.container} {...props}>
      {!log ? (
        <SkeletonDetailedData disableRightPadding className={classes.content} />
      ) : (
        <LogDetailedData
          log={log}
          disableRightPadding
          className={classes.content}
        />
      )}
    </PageContainer>
  );
}
