import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { FormatListBulleted } from "mdi-material-ui";
import StickySecondaryToolbar from "components/utils/StickySecondaryToolbar";
import ResponsiveButton from "components/utils/ResponsiveButton";
import FilterToolbox from "components/utils/FilterToolbox";
import { ROUTES } from "routes";
import { idFromQuery } from "selectors/queries";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(7),
    },
    boxSizing: "border-box",
  },
  inner: {
    flex: "1",
    display: "inherit",
    margin: "auto",
    padding: 0,
    boxSizing: "border-box",
    maxWidth: `calc(${theme.breakpoints.values.lg}px - ${theme.spacing(11)}px)`,
  },
  toolbarItem: {
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  spacer: {
    flex: "1 1 0%",
  },
}));

export default function SecondaryToolbar({ query = {}, ...props }) {
  const classes = useStyles();

  return (
    <StickySecondaryToolbar className={classes.container}>
      <div className={classes.inner}>
        <FilterToolbox edge="start" rootPath={ROUTES.stat.path} />
        <div className={classes.spacer} />
        <ResponsiveButton
          edge="end"
          component={Link}
          to={{
            pathname: ROUTES.list.path,
            search: idFromQuery({
              page: 1,
              start: query.start,
              end: query.end,
              mark: query.mark,
            }),
          }}
          text="Liste"
          size="small"
          startIcon={<FormatListBulleted />}
          className={classes.toolbarItem}
        />
      </div>
    </StickySecondaryToolbar>
  );
}
