import * as auth from "constants/actions/auth";
import * as api from "constants/api";
import * as methods from "constants/methods";
import * as actions from "constants/actions/entities";
import * as stats from "constants/actions/stats";
import * as entities from "constants/entities";
import {
  isAuth,
  isDownload,
  isUnique,
  isAction,
  isStat,
  isReset,
  isImport,
} from "actions/helpers";

const computeAuthUrl = (action) => {
  if (isAction(action, auth.LOGIN_ACTION)) {
    return api.LOGIN_URI;
  }
  if (isAction(action, auth.LOGOUT_ACTION)) {
    return api.LOGOUT_URI;
  }
  if (isAction(action, auth.ACTIVATE_ACTION)) {
    return api.ACTIVATION_URI;
  }
  if (isAction(action, auth.RE_ACTIVATE_ACTION)) {
    return api.ACTIVATION_RE_URI;
  }
  if (isAction(action, auth.SET_PASSWORD_ACTION)) {
    return api.PASSWORD_SET_URI;
  }
  if (isAction(action, auth.RESET_PASSWORD_CONFIRM_ACTION)) {
    return api.PASSWORD_RESET_CONFIRM_URI;
  }
  if (isAction(action, auth.RESET_PASSWORD_ACTION)) {
    return api.PASSWORD_RESET_URI;
  }
  if (isAction(action, auth.CONTACT_ACTION)) {
    return api.CONTACT_URI;
  }

  throw new Error(`API Service : Auth action ${action.type} has no valid url`);
};

const computeDownloadUrl = ({ file, meta }) => {
  const { mimeType } = meta;
  if (file) {
    return api.IMPORT_URI;
  } else {
    switch (mimeType) {
      case api.MIMETYPE_JSON:
        return api.EVERYTHING_URI;
      case api.MIMETYPE_PDF:
        return api.EXPORT_PDF_URI;
      case api.MIMETYPE_CSV:
        return api.EXPORT_CSV_URI;
      default:
        throw new TypeError("Invalid mimeType");
    }
  }
};

const computeStatUrl = (action) => {
  if (isAction(action, stats.STATS_DEADLINES_ACTION)) {
    return api.STATS_DEADLINES_URI;
  }
  if (isAction(action, stats.STATS_CHARTS_ACTION)) {
    return api.STATS_CHARTS_URI;
  }
  throw new Error(`API Service : Stat action ${action.type} has no valid url`);
};

const uriNeedsPkParam = (method, action) => {
  return (
    method !== methods.POST_METHOD &&
    !isAction(action, actions.LIST_ACTION) &&
    !isUnique(action)
  );
};

export const shouldIncreaseTimeout = (action) =>
  isImport(action) || action.meta.mimeType === api.MIMETYPE_PDF;

/**
 * Computes the url to be called depending on the action
 */
export const computeUrl = (method, action) => {
  if (isAuth(action)) {
    return computeAuthUrl(action);
  } else if (isDownload(action) || isImport(action)) {
    return computeDownloadUrl(action);
  } else if (isStat(action)) {
    return computeStatUrl(action);
  } else if (isReset(action)) {
    return api.EVERYTHING_URI;
  } else if (action.meta.entityName === entities.USER_ENTITY) {
    if (method === methods.POST_METHOD) return api.USER_URI;
    else return `${api.USER_URI}${api.USER_ME_ID}/`;
  } else if (uriNeedsPkParam(method, action)) {
    return `${action.meta.entityName}/${action.params.pk}/`;
  } else {
    return `${action.meta.entityName}/`;
  }
};

export const shouldSendParams = (action) => {
  //if there are params different from 'pk'
  return (
    action.params &&
    Object.keys(action.params).filter((key) => key !== "pk").length > 0
  );
};

export function computeHeaders({ meta, file }, token) {
  const { mimeType } = meta;
  const headers = {
    ...api.HEADERS,
  };
  if (mimeType) {
    headers.Accept = `${mimeType}${api.CONTENT_TYPE_HEADER_SUFFIX}`;
  }
  if (file) {
    headers["Content-Type"] = "multipart/form-data";
  }
  if (token) {
    headers.Authorization = `Token ${token}`;
  }
  return headers;
}

export function computeBody({ meta, file }) {
  if (file) {
    const form = new FormData();
    form.append("file", file);
    return form;
  } else {
    return meta.body;
  }
}

export function formatBufferResponse(response) {
  return {
    buffer: response.data,
    length: response.headers["content-length"],
    filename: response.headers["content-disposition"].match(
      /;filename="(.*)"/
    )[1],
  };
}
