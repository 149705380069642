import moment from "moment";
import { HOURS } from "constants/hours";
import { DATE_FORMAT_DATA, DATE_FORMAT_SHORT } from "constants/ui";
import { ROUTES } from "routes";
import { duration } from "components/utils/HourUtils";

export const maxTimeType = (log) => {
  const max_type = log.hours.reduce((prev, current) =>
    prev.count > current.count ? prev : current
  );
  return `${duration(max_type.count)} en ${HOURS[
    max_type.hour_type
  ].label.toLowerCase()}`;
};

export const maxZone = (log) => {
  const max_zone = log.occurrences.reduce((prev, current) =>
    prev.count > current.count ? prev : current
  );
  return max_zone.count === 0
    ? ""
    : `dont ${max_zone.count} ${max_zone.zone_name}`;
};

export const inputUrl = (log) =>
  ROUTES.input.path.replace(":date", moment(log.date).format(DATE_FORMAT_DATA));

export const totalOcc = (log) => {
  return (
    (log.occurrences &&
      log.occurrences.reduce(
        (prev, current) => (prev ? prev + current.count : current.count),
        0
      )) ||
    0
  );
};

export const totalTime = (log) => duration(log.total_time);

export const formattedDate = (log) =>
  moment(log.date).format(DATE_FORMAT_SHORT);
