import React from "react";
import { useDispatch } from "react-redux";
import { Button, TextField, Grid, Typography, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import ErrorBox from "components/utils/ErrorBox";
import PasswordTextField from "components/utils/PasswordTextField";
import AuthPage from "./AuthPage";
import { ROUTES } from "routes";
import { REGISTER_STATE } from "constants/status";
import { register } from "actions/user";
import { useAuthState } from "hooks";
import { useForm } from "hooks/forms";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  success: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
  successP: {
    marginTop: theme.spacing(1),
  },
  errorAlert: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, errors, success } = useAuthState(REGISTER_STATE);
  const { values, touched, handleUserInput, handleSubmit } = useForm(
    {
      email: "",
      password: "",
      re_password: "",
    },
    () => dispatch(register({ ...values }))
  );

  const success_box = (
    <div className={classes.success}>
      <Typography
        className={classes.successP}
        align="justify"
      >{`Un compte a été créé pour ${values.email}.`}</Typography>
      <Typography className={classes.successP} align="justify">
        Nous vous avons envoyé un e-mail pour validation. Cliquez sur le lien
        fourni dans l'e-mail pour terminer l'inscription.
      </Typography>
      <Typography className={classes.successP} align="justify">
        Si vous ne le recevez pas d'ici quelques minutes,{" "}
        <Link component={RouterLink} to={ROUTES.re_activate.path}>
          cliquez ici pour demander un nouvel envoi.
        </Link>
      </Typography>
    </div>
  );

  const form = (
    <form className={classes.form} noValidate onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="email"
            label="Adresse e-mail"
            name="email"
            value={values.email}
            error={Boolean(errors.email) && !touched.email}
            helperText={errors.email}
            onChange={handleUserInput}
            autoComplete="email"
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordTextField
            variant="outlined"
            required
            fullWidth
            name="password"
            label="Mot de passe"
            id="password"
            autoComplete="new-password"
            value={values.password}
            error={Boolean(errors.password) && !touched.password}
            helperText={errors.password}
            onChange={handleUserInput}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordTextField
            variant="outlined"
            required
            fullWidth
            name="re_password"
            label="Confirmation de mot de passe"
            id="re_password"
            autoComplete="new-password"
            value={values.re_password}
            error={Boolean(errors.re_password) && !touched.re_password}
            helperText={errors.re_password}
            onChange={handleUserInput}
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        S'enregistrer
      </Button>
      <Grid container justify="flex-end">
        <Grid item>
          <Link component={RouterLink} to={ROUTES.login.path} variant="body2">
            Déjà enregistré ? Se connecter
          </Link>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <AuthPage loading={loading} title="S'enregistrer">
      {success && success_box}
      <ErrorBox
        className={classes.errorAlert}
        errorList={[errors.non_field_errors]}
      />
      {!success && form}
    </AuthPage>
  );
}
