import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ChartBar } from "mdi-material-ui";

import StickySecondaryToolbar from "components/utils/StickySecondaryToolbar";
import FilterToolbox from "components/utils/FilterToolbox";
import ResponsiveButton from "components/utils/ResponsiveButton";
import PaginationToolbox from "./PaginationToolbox";
import ExportToolbox from "./ExportToolbox";

import { ROUTES } from "routes";
import { idFromQuery } from "selectors/queries";

const useStyles = makeStyles((theme) => ({
  toolbarItem: {
    marginRight: theme.spacing(1),
  },
  divider: {
    marginRight: theme.spacing(1),
  },
  spacer: {
    flex: "1 1 0%",
  },
}));

export default function SecondaryToolbar({ query, ...props }) {
  const classes = useStyles();

  return (
    <StickySecondaryToolbar>
      <ExportToolbox edge="start" />
      <FilterToolbox
        rootQuery={{ page: 1 }}
        rootPath={ROUTES.list.path}
        noVisibility
      />
      <div className={classes.spacer} />
      <ResponsiveButton
        component={Link}
        to={{
          pathname: ROUTES.stat.path,
          search: idFromQuery({ ...query, page: undefined }),
        }}
        text="Stats"
        size="small"
        startIcon={<ChartBar />}
        className={classes.toolbarItem}
      />
      <Divider orientation="vertical" flexItem className={classes.divider} />
      <PaginationToolbox edge="end" />
    </StickySecondaryToolbar>
  );
}
