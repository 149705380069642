import { combineReducers } from "redux";
import * as actions from "constants/actions/stats";
import {
  ZONE_ENTITY,
  LOG_ENTITY,
  LICENCE_ENTITY,
  TARGET_ENTITY,
} from "constants/entities";
import { DEFAULT_TARGETS } from "constants/targets";
import { isAction, isSuccess, isReadAction, isEntity } from "actions/helpers";
import { idFromQuery } from "selectors/queries";
import { now } from "middlewares/staleness";
import {
  DEFAULT_ERRORS,
  getStatus,
  shouldResetData,
  hasChangedEntities,
  shouldClearStatus,
  clearStatus,
} from "./helpers";

const INITIAL_STATE = {
  loading: false,
  hasErrors: false,
  errors: DEFAULT_ERRORS,
};

const genericStatReducer = (reducer_action) => (
  state = INITIAL_STATE,
  action
) => {
  if (shouldResetData(action)) {
    return INITIAL_STATE;
  }
  if (shouldClearStatus(action)) {
    return clearStatus(state);
  }
  if (isAction(action, reducer_action)) {
    const status = getStatus(action, true);
    if (isSuccess(action)) {
      return { ...state, ...status, data: action.payload, updated_at: now() };
    } else {
      return { ...state, ...status };
    }
  }
  return state;
};

const deadlines = (state = INITIAL_STATE, action) => {
  //deadlines are stale if log or zones or licence changed
  if (
    hasChangedEntities(action, [
      LOG_ENTITY,
      ZONE_ENTITY,
      LICENCE_ENTITY,
      TARGET_ENTITY,
    ])
  ) {
    return {
      ...state,
      updated_at: undefined,
    };
  }
  return genericStatReducer(actions.STATS_DEADLINES_ACTION)(state, action);
};

const targets = (
  state = {
    loading: false,
    hasErrors: false,
    errors: DEFAULT_ERRORS,
    data: DEFAULT_TARGETS,
  },
  action
) => {
  if (shouldClearStatus(action)) {
    return clearStatus(state);
  }
  //get target values in deadlines response
  if (isAction(action, actions.STATS_DEADLINES_ACTION) && isSuccess(action)) {
    const data = action.payload;
    let occ = 24;
    if (Object.values(data.occurrences).size > 0) {
      occ = Object.values(data.occurrences)[0].target;
    }
    return {
      ...state,
      data: {
        ...state.data,
        yearly_total_hours: data.total_hours.target,
        yearly_ojti_percent: data.ojti_part.target,
        yearly_sim_percent: data.sim_part.target,
        yearly_zone_occurrences: occ,
        days_between_logs: data.days_since_last_log.target,
      },
    };
  }
  if (isEntity(action, TARGET_ENTITY) && !isReadAction(action)) {
    return {
      ...state,
      ...getStatus(action),
    };
  }
  return state;
};

const charts = (state = {}, action) => {
  if (shouldResetData(action)) {
    return {};
  }
  //When zones or logs are changed, stats are stale
  if (hasChangedEntities(action, [LOG_ENTITY, ZONE_ENTITY])) {
    return Object.fromEntries(
      Object.entries(state).map(([id, idState]) => ({
        ...idState,
        updated_at: undefined,
      }))
    );
  }
  if (isAction(action, actions.STATS_CHARTS_ACTION)) {
    const params = action.params || action.meta.params || {};
    const query_id = idFromQuery(params);
    const current_query_state = state[query_id] || {};
    return {
      ...state,
      [query_id]: genericStatReducer(actions.STATS_CHARTS_ACTION)(
        current_query_state,
        action
      ),
    };
  }
  return state;
};

const stats = combineReducers({
  deadlines,
  charts,
  targets,
});

export default stats;
