import React from "react";
import { useDispatch } from "react-redux";
import pick from "lodash.pick";
import { Typography, Button, Grid, Link } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link as RouterLink, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import ErrorBox from "components/utils/ErrorBox";
import PasswordTextField from "components/utils/PasswordTextField";
import AuthPage from "./AuthPage";
import { ROUTES } from "routes";
import { useAuthState } from "hooks";
import { useForm } from "hooks/forms";
import { confirm_password_reset } from "actions/auth";
import { CONFIRM_PASSWORD_STATE } from "constants/status";

const useStyles = makeStyles((theme) => ({
  infoBox: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  alert: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  input: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ResetPassword() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { uid, token } = useParams();
  const { loading, success, errors } = useAuthState(CONFIRM_PASSWORD_STATE);
  const { values, touched, handleUserInput, handleSubmit } = useForm(
    {
      new_password: "",
      re_new_password: "",
    },
    (values) =>
      dispatch(
        confirm_password_reset(
          uid,
          token,
          values.new_password,
          values.re_new_password
        )
      )
  );

  const success_box = (
    <div>
      <Alert className={classes.alert} severity="success">
        Votre nouveau mot de passe est enregistré !
      </Alert>
      <Typography className={classes.alert}>
        <Link component={RouterLink} to={ROUTES.login.path}>
          Cliquez ici pour vous connecter
        </Link>
      </Typography>
    </div>
  );

  return (
    <AuthPage loading={loading} title="Changer le mot de passe">
      <div className={classes.infoBox}>
        {success && success_box}
        <ErrorBox
          className={classes.alert}
          errorDict={pick(errors, ["non_field_errors", "uid", "token"])}
          noKeys
        />
      </div>
      {!success && (
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <PasswordTextField
            className={classes.input}
            variant="outlined"
            required
            fullWidth
            name="new_password"
            label="Mot de passe"
            id="new_password"
            autoComplete="new-password"
            autoFocus
            value={values.new_password}
            error={Boolean(errors.new_password) && !touched.new_password}
            helperText={!touched.new_password && errors.new_password}
            onChange={handleUserInput}
          />
          <PasswordTextField
            className={classes.input}
            variant="outlined"
            required
            fullWidth
            name="re_new_password"
            label="Confirmation de mot de passe"
            id="re_new_password"
            autoComplete="new-password"
            value={values.re_new_password}
            error={Boolean(errors.re_new_password) && !touched.re_new_password}
            helperText={!touched.re_new_password && errors.re_new_password}
            onChange={handleUserInput}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Changer le mot de passe
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link
                component={RouterLink}
                to={ROUTES.login.path}
                variant="body2"
              >
                Se connecter
              </Link>
            </Grid>
          </Grid>
        </form>
      )}
    </AuthPage>
  );
}
