import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  FilterOutline,
  Calendar,
  Replay,
  CheckAll,
  MenuDown,
  AccountSupervisor,
  School,
} from "mdi-material-ui";
import DateRangeFilterDialog from "./DateRangeFilterDialog";
import MarkFilterDialog from "./MarkFilterDialog";
import FilterLabel, {
  periodForDisplay,
  markForDisplay,
  typeForDisplay,
} from "./FilterLabel";
import ResponsiveButton from "../ResponsiveButton";

import { useQueryParams } from "hooks/queries";
import { useLicence } from "hooks/entities";
import { readEntity } from "actions/entities";
import { updateQueryParams } from "actions/ui";

import { LICENCE_ENTITY } from "constants/entities";

FilterToolbox.propTypes = {
  rootPath: PropTypes.string,
  rootQuery: PropTypes.object,
  noVisibility: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  toolbox: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    alignContent: "space-between",
    [theme.breakpoints.up("md")]: {
      marginTop: `-${theme.spacing(1)}`, //patch to add spacing between rows when wraping
      marginBottom: `-${theme.spacing(1)}`,
    },
  },
  toolbarItem: {
    marginRight: theme.spacing(1),
    flex: "0 0 auto",
    verticalAlign: "middle",
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  filterLabel: {
    minWidth: "0%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  subToolbox: {
    whiteSpace: "nowrap",
    marginRight: theme.spacing(1),
  },
}));

export default function FilterToolbox({
  rootQuery = {},
  rootPath,
  noVisibility,
  ...props
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const licence = useLicence();
  const query = useQueryParams();
  const [filterMenuAnchor, setFilterMenuAnchor] = React.useState(null);
  const [dateDialogOpen, setDateDialogOpen] = React.useState(false);
  const [markDialogOpen, setMarkDialogOpen] = React.useState(false);

  useEffect(() => {
    //for last renewal date update
    if (!licence.loading) {
      dispatch(readEntity(LICENCE_ENTITY));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isActive = () => {
    //query contains keys other than "page"
    return (
      Object.keys(query).filter((key) => key !== "page" && key.length > 0)
        .length > 0
    );
  };

  const handleFilterClick = (event) => {
    setFilterMenuAnchor(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null);
  };

  const handleDateFilterClick = () => {
    setDateDialogOpen(true);
  };

  const handleDateFilterMenuClose = () => {
    setDateDialogOpen(false);
    handleFilterMenuClose();
  };

  const handleMarkFilterClick = () => {
    setMarkDialogOpen(true);
  };

  const handleMarkFilterMenuClose = () => {
    setMarkDialogOpen(false);
    handleFilterMenuClose();
  };

  const handleOnResetClick = () => {
    dispatch(updateQueryParams(rootPath, rootQuery));
    handleFilterMenuClose();
  };

  const resetParam = (param) => setParam(param, undefined);

  const setParam = (param, value) => {
    dispatch(
      updateQueryParams(rootPath, { ...query, ...rootQuery, [param]: value })
    );
    handleFilterMenuClose();
  };

  const handleDateFilterReset = () => {
    dispatch(
      updateQueryParams(rootPath, {
        ...query,
        ...rootQuery,
        start: undefined,
        end: undefined,
      })
    );
  };

  const handleDateRangePick = (start, end) => {
    dispatch(
      updateQueryParams(rootPath, { ...query, ...rootQuery, start, end })
    );
  };

  const handleSimChange = () => {
    setParam("sim", (!(query.sim !== "false")).toString());
  };

  const handleDualChange = () => {
    setParam("dual", (!(query.dual !== "false")).toString());
  };

  return (
    <Fragment>
      <ResponsiveButton
        text="Filtrer"
        size="small"
        startIcon={<FilterOutline />}
        endIcon={<MenuDown />}
        active={isActive()}
        onClick={handleFilterClick}
        className={classes.toolbarItem}
        {...props}
      />
      <FilterLabel
        query={query}
        onDateFilterClick={handleDateFilterClick}
        onMarkFilterClick={(value) => setParam("mark", value)}
        onDateFilterDelete={handleDateFilterReset}
        onMarkFilterDelete={() => resetParam("mark")}
        onSimFilterDelete={() => resetParam("sim")}
        onDualFilterDelete={() => resetParam("dual")}
        noVisibility={noVisibility}
        className={classes.toolbarItem}
      />
      <Menu
        anchorEl={filterMenuAnchor}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        keepMounted
        open={Boolean(filterMenuAnchor)}
        onClose={handleFilterMenuClose}
      >
        <MenuItem dense onClick={handleDateFilterClick}>
          <ListItemIcon>
            <Calendar />
          </ListItemIcon>
          <ListItemText>
            Période : {periodForDisplay(query.start, query.end)}
          </ListItemText>
        </MenuItem>
        <MenuItem dense onClick={handleMarkFilterClick}>
          <ListItemIcon>
            <CheckAll />
          </ListItemIcon>
          <ListItemText>
            Marquage : {markForDisplay(query.mark, "tout")}
          </ListItemText>
        </MenuItem>
        {!noVisibility && (
          <MenuItem dense onClick={handleSimChange}>
            <ListItemIcon>
              <School />
            </ListItemIcon>
            <ListItemText>
              {`Simu : ${typeForDisplay(query.sim !== "false")}`}
            </ListItemText>
          </MenuItem>
        )}
        {!noVisibility && (
          <MenuItem dense onClick={handleDualChange}>
            <ListItemIcon>
              <AccountSupervisor />
            </ListItemIcon>
            <ListItemText>
              {`Double : ${typeForDisplay(query.dual !== "false")}`}
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem dense onClick={handleOnResetClick}>
          <ListItemIcon>
            <Replay />
          </ListItemIcon>
          <ListItemText>Tout afficher</ListItemText>
        </MenuItem>
      </Menu>
      <DateRangeFilterDialog
        query={query}
        lastLicenceDate={licence.last_renewal}
        onDateRangeChange={handleDateRangePick}
        open={dateDialogOpen}
        onClose={handleDateFilterMenuClose}
      />
      <MarkFilterDialog
        query={query}
        open={markDialogOpen}
        onMarkSelect={(value) => setParam("mark", value)}
        onClose={handleMarkFilterMenuClose}
      />
    </Fragment>
  );
}
