import React from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppIcon from "./utils/AppIcon";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }
}));

export default function Error404() {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <AppIcon />
        <Typography variant="h4">404 Page not found</Typography>
        <Typography variant="subtitle1">
          Nous sommes désolés mais la page que vous recherchez n'existe pas.
        </Typography>
      </div>
    </Container>
  );
}
