import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Collapse,
  Hidden,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { DotsHorizontal } from "mdi-material-ui";
import { Link } from "react-router-dom";
import MarkedAvatar from "./MarkedAvatar";
import LogSummary from "./Summary";
import LogDetailedData from "components/utils/LogDetailedData";

import { inputUrl, formattedDate } from "./utils";

LogListItem.propTypes = {
  log: PropTypes.object,
  onMarkClick: PropTypes.func,
  onNoteClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  props: PropTypes.object,
};

const useStyles = makeStyles((theme) => ({
  row: {
    minWidth: "250px",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: theme.palette.action.hover,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  skeleton: {
    minWidth: "250px",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: theme.palette.action.hover,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  inline: {
    display: "inline",
  },
  summary: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  actions: {},
  collapse: {
    marginTop: theme.spacing(1),
  },
}));

export function SkeletonLogListItem(props) {
  const classes = useStyles();
  return (
    <ListItem
      {...props}
      dense
      alignItems="flex-start"
      divider
      className={classes.skeleton}
    >
      <ListItemAvatar>
        <Skeleton edge="start" variant="circle" width={40} height={40} />
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={<Skeleton width="60%" />}
        secondary={<Skeleton width="80%" />}
      />
      <Hidden mdUp>
        <ListItemSecondaryAction className={classes.actions}>
          <IconButton size="small" disabled>
            <DotsHorizontal />
          </IconButton>
        </ListItemSecondaryAction>
      </Hidden>
    </ListItem>
  );
}

export default function LogListItem({
  log = {},
  selected,
  onMarkClick,
  onNoteClick,
  onDeleteClick,
  ...props
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    return false;
  };

  const handleMenuClose = (event, parent_func) => {
    setAnchorEl(null);
    if (parent_func) {
      parent_func(log);
    }
    event.stopPropagation();
    return false;
  };

  const title_line = (
    <Typography variant="subtitle2">{formattedDate(log)}</Typography>
  );

  const additional_data = (
    <Collapse in={selected} mountOnEnter unmountOnExit>
      <LogDetailedData log={log} className={classes.collapse} />
    </Collapse>
  );

  return (
    <ListItem
      {...props}
      dense
      alignItems="flex-start"
      divider
      selected={selected}
      className={classes.row}
    >
      <ListItemAvatar>
        <MarkedAvatar
          edge="start"
          log={log}
          selected={selected}
          onClick={onMarkClick}
        />
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={title_line}
        secondary={
          <Fragment>
            <LogSummary
              log={log}
              noWrap
              gutterBottom
              className={classes.summary}
            />
            <Hidden mdUp>{additional_data}</Hidden>
          </Fragment>
        }
      />
      <Hidden mdUp>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={(e) => handleMenuClose(e)}
        >
          <MenuItem dense onClick={(e) => handleMenuClose(e, onMarkClick)}>
            Marquer
          </MenuItem>
          <MenuItem dense onClick={(e) => handleMenuClose(e, onNoteClick)}>
            Annoter
          </MenuItem>
          <MenuItem
            dense
            component={Link}
            to={inputUrl(log)}
            onClick={(e) => handleMenuClose(e)}
          >
            Modifier
          </MenuItem>
          <MenuItem dense onClick={(e) => handleMenuClose(e, onDeleteClick)}>
            Supprimer
          </MenuItem>
        </Menu>
        <ListItemSecondaryAction className={classes.actions}>
          <Tooltip title="Actions" arrow>
            <IconButton
              size="small"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleMenuButtonClick}
            >
              <DotsHorizontal />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </Hidden>
    </ListItem>
  );
}
