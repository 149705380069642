import * as actions from "constants/actions/entities";
import { ZONE_ENTITY, LOG_ENTITY } from "constants/entities";
import { isSuccess, isFail, isAction, isEntity } from "actions/helpers";
import { idFromQuery } from "selectors/queries";
import {
  shouldResetData,
  hasChangedEntities,
  getStatus,
  getUpdatedAt,
} from "./helpers";

const INITIAL_STATE = {};

const isListQuery = (action) =>
  isEntity(action, LOG_ENTITY) && isAction(action, actions.LIST_ACTION);

const queries = (state = INITIAL_STATE, action) => {
  const { meta, payload } = action;
  if (shouldResetData(action)) {
    return INITIAL_STATE;
  }
  //when editing zones, logs should be considered stale
  //because zonecounts are being updated on server side.
  //so queries are also stale
  if (hasChangedEntities(action, [ZONE_ENTITY, LOG_ENTITY])) {
    const deleted_pk =
      isAction(action, actions.DESTROY_ACTION) && isEntity(action, LOG_ENTITY);
    return Object.fromEntries(
      Object.entries(state).map(([key, query]) => [
        key,
        {
          ...query,
          updated_at: undefined,
          query: query.query.filter((id) => id !== deleted_pk),
        },
      ])
    );
  }
  if (isListQuery(action) && meta.params) {
    const query_id = idFromQuery(meta.params);
    const status = getStatus(action, true);
    const count = state[query_id] ? state[query_id].count : undefined;
    let query = state[query_id] ? state[query_id].query : {};
    if (isSuccess(action)) query = Object.keys(payload.results);
    if (isFail(action)) query = [];
    return {
      ...state,
      [query_id]: {
        ...(state[query_id] || {}),
        ...status,
        count: isSuccess(action) ? payload.count : count,
        query,
        updated_at: getUpdatedAt(action),
      },
    };
  }
  return state;
};

export default queries;
