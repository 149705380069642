export const DRAWER_WIDTH = 240;

export const LIST_PAGE_SIZE = 30; //see backend settings.py

export const DATE_FORMAT_DATA = "YYYY-MM-DD";
export const DATE_FORMAT_SHORT = "DD/MM/YYYY";
export const DATE_FORMAT_LONG = "ddd Do MMMM YYYY";

export const MARK_FILTER_MARKED = "marked";
export const MARK_FILTER_UNMARKED = "unmarked";
export const MARK_FILTER_ALL = "all";
