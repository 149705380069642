import React from "react";
import clsx from "clsx";
import { Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    //borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: theme.shadows[1],
    background: theme.palette.background.paper,
    position: "sticky",
    zIndex: theme.zIndex.speedDial,
    top: theme.mixins.toolbar.minHeight,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      top:
        theme.mixins.toolbar[
          `${theme.breakpoints.up("xs")} and (orientation: landscape)`
        ].minHeight,
    },
    [theme.breakpoints.up("sm")]: {
      top: theme.mixins.toolbar[theme.breakpoints.up("sm")].minHeight,
    },
  },
}));

export default function StickySecondaryToolbar({
  children,
  className,
  ...props
}) {
  const classes = useStyles();
  return (
    <Toolbar
      variant="dense"
      className={className ? clsx(classes.toolbar, className) : classes.toolbar}
      {...props}
    >
      {children}
    </Toolbar>
  );
}
