import React from "react";
import PropTypes from "prop-types";
import { LocalizationProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ConnectedRouter } from "connected-react-router";
import { MatomoProvider } from "@datapunt/matomo-tracker-react";

import Loading from "./Loading";
import Theming from "./Theming";
import Layout from "./Layout";
import Routing from "./Routing";
import Maintenance from "./Maintenance";

export default function Root({
  store,
  persistor,
  history,
  matomo,
  maintenance,
}) {
  return maintenance ? (
    <Maintenance />
  ) : (
    <Provider store={store}>
      <MatomoProvider value={matomo}>
        <Theming>
          <PersistGate loading={<Loading />} persistor={persistor}>
            <ConnectedRouter history={history}>
              <LocalizationProvider
                dateLibInstance={moment}
                dateAdapter={MomentAdapter}
              >
                <Layout>
                  <Routing />
                </Layout>
              </LocalizationProvider>
            </ConnectedRouter>
          </PersistGate>
        </Theming>
      </MatomoProvider>
    </Provider>
  );
}

Root.propTypes = {
  store: PropTypes.any,
  persistor: PropTypes.any,
  history: PropTypes.any,
};
