import { FRESHNESS_DELAY_IN_SECS, DEBUG } from "constants/config";
import { USER_ENTITY, PREF_ENTITY, LOG_ENTITY } from "constants/entities";
import { GET_METHOD } from "constants/methods";
import { READ_PROFILE_STATE } from "constants/status";
import * as actions from "constants/actions/entities";
import {
  isAction,
  isRequest,
  isDownload,
  isUnique,
  isStat,
} from "actions/helpers";

import { getEntity, getEntities } from "selectors/entities";
import { getCurrentQuery } from "selectors/queries";
import { getAuthState } from "selectors/status";
import { getStatStateForAction } from "selectors/stats";
import { getSimVisibility } from "selectors/ui";

import { getMethod } from "./helpers";

/**
 * Middleware to add a data freshness indicator in meta for request
 */
const stalenessMiddleware = (store) => (next) => (action) => {
  if (isRequest(action)) {
    if (shouldUpdate(action, store.getState())) {
      action = {
        ...action,
        meta: {
          ...action.meta,
          stale: true,
        },
      };
    } else if (DEBUG) {
      //change action type. This is not used in reducer or further middleware.
      //It's only for logging purposes
      action = {
        ...action,
        type: `${action.type}_FRESH`,
      };
    }
  }
  next(action);
};

const shouldUpdate = (action, state) => {
  //check if requested data is still fresh or should be updated from server
  const { meta } = action;
  const { entityName, pk } = meta;
  const method = getMethod(action);

  //should always call api for writing actions and downloads
  if (method !== GET_METHOD || isDownload(action)) {
    return true;
  }
  try {
    let content;
    if (isAction(action, actions.LIST_ACTION)) {
      if (entityName === LOG_ENTITY) {
        content = getCurrentQuery(state);
      } else {
        content = getEntities(entityName)(state);
      }
    }
    if (isAction(action, actions.READ_ACTION)) {
      if (entityName === USER_ENTITY)
        content = getAuthState(state, READ_PROFILE_STATE);
      else if (entityName === PREF_ENTITY) content = getSimVisibility(state);
      //sim visibility is enough because sim and dual are always updated at the same time
      else if (isUnique(action)) content = getEntities(entityName)(state);
      else content = getEntity(state, entityName, pk);
    }
    if (isStat(action)) {
      content = getStatStateForAction(state, action);
    }
    const { loading, updated_at } = content;
    return !loading && isStale(updated_at);
  } catch (TypeError) {
    //entities do not exist yet
    return true;
  }
};

export const isStale = (updated_at) =>
  !updated_at || now() - updated_at > FRESHNESS_DELAY_IN_SECS;

export const now = () => Math.floor(Date.now() / 1000);

export default stalenessMiddleware;
