import { createSelector } from "reselect";
import { ZONE_ENTITY, LICENCE_ENTITY, LOG_ENTITY } from "constants/entities";
import moment from "moment";

export const getPk = (entity) => (entity === LOG_ENTITY ? "date" : "id");

export const getEntities = (entityName) => (state) =>
  state.entities[entityName];

export const getEntity = (state, entityName, pk) => {
  try {
    if (entityName !== LOG_ENTITY) {
      return getEntities(entityName)(state).entities.find(
        (entity) => entity[getPk(entityName)] === pk
      );
    } else {
      return getEntities(entityName)(state).entities[pk];
    }
  } catch (TypeError) {
    return {};
  }
};

export const getLicence = getEntities(LICENCE_ENTITY);

export const getLicenceHolder = createSelector(
  getLicence,
  ({ holder }) => holder
);

export const getZones = getEntities(ZONE_ENTITY);

export const getLogForDate = (date) => (state) => {
  try {
    const pk = moment(date).format("YYYY-MM-DD");
    return getEntities(LOG_ENTITY)(state).entities[pk] || {};
  } catch (TypeError) {
    return {};
  }
};
