import React from "react";
import { Container, Grid, Tabs, Tab, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import StickySecondaryToolbar from "components/utils/StickySecondaryToolbar";
import Licence from "./Licence";
import ZoneManagement from "./ZoneManagement";
import Deadlines from "./Deadlines";
import { useTab } from "hooks/ui";

const useStyles = makeStyles((theme) => ({
  tabs: {
    width: "100%",
  },
  page: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
}));

export default function LicencePage() {
  const classes = useStyles();
  const { tab, onChange } = useTab(0);
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  return mdUp ? (
    <Container maxWidth="lg" disableGutters>
      <Grid container spacing={1} className={classes.page}>
        <Grid item container md={12} spacing={3} justify="center">
          <Grid item md={10}>
            <Deadlines />
          </Grid>
        </Grid>
        <Grid item container md={12} spacing={3} justify="center">
          <Grid item md={6}>
            <Licence />
          </Grid>
          <Grid item md={4}>
            <ZoneManagement />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  ) : (
    <div>
      <StickySecondaryToolbar>
        <Tabs
          className={classes.tabs}
          variant="fullWidth"
          value={tab}
          onChange={onChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Butées" />
          <Tab label="Zones" />
          <Tab label="Licence" />
        </Tabs>
      </StickySecondaryToolbar>
      <Container disableGutters maxWidth="sm">
        {tab === 0 && <Deadlines />}
        {tab === 1 && <ZoneManagement />}
        {tab === 2 && <Licence />}
      </Container>
    </div>
  );
}
