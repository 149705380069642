import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Menu, MenuItem, ListItemText, ListItemIcon } from "@material-ui/core";
import {
  ShareVariant,
  FilePdfBoxOutline,
  MicrosoftExcel,
  MenuDown,
} from "mdi-material-ui";
import StateButton from "components/utils/StateButton";

import { export_as_pdf, export_as_csv } from "actions/user";
import { useQueryParams } from "hooks/queries";
import { useAuthState } from "hooks";
import { EXPORT_STATE } from "constants/status";

const useStyles = makeStyles((theme) => ({
  toolbarItem: {
    marginRight: theme.spacing(1),
    flex: "0 0 auto",
    verticalAlign: "middle",
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}));

const PDF_FORMAT = "pdf";
const CSV_FORMAT = "csv";

export default function ExportToolbox(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useQueryParams();
  const exportState = useAuthState(EXPORT_STATE);
  const [menuAnchor, setMenuAnchor] = React.useState(null);

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleMenuRootClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleExportClick = (format) => {
    const exported_query = {
      start: params.start,
      end: params.end,
      mark: params.mark,
    };
    if (format === PDF_FORMAT) {
      //do not pass page
      dispatch(export_as_pdf(exported_query));
    }
    if (format === CSV_FORMAT) {
      dispatch(export_as_csv(exported_query));
    }
    handleMenuClose();
  };

  return (
    <Fragment>
      <StateButton
        title="Exporter"
        size="small"
        responsive
        state={exportState}
        startIcon={<ShareVariant />}
        endIcon={<MenuDown />}
        onClick={handleMenuRootClick}
        className={classes.toolbarItem}
        {...props}
      />
      <Menu
        anchorEl={menuAnchor}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        <MenuItem dense onClick={() => handleExportClick(PDF_FORMAT)}>
          <ListItemIcon>
            <FilePdfBoxOutline />
          </ListItemIcon>
          <ListItemText>Exporter en PDF</ListItemText>
        </MenuItem>
        <MenuItem dense onClick={() => handleExportClick(CSV_FORMAT)}>
          <ListItemIcon>
            <MicrosoftExcel />
          </ListItemIcon>
          <ListItemText>Exporter en CSV</ListItemText>
        </MenuItem>
      </Menu>
    </Fragment>
  );
}
