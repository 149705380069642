import { USER_ENTITY } from "constants/entities";
import { REQUEST_STATUS } from "constants/status";
import {
  DOWNLOAD_ALL_ACTION,
  RESET_ACTION,
  CONTACT_ACTION,
} from "constants/actions/auth";
import {
  DESTROY_ACTION,
  EXPORT_ACTION,
  IMPORT_ACTION,
} from "constants/actions/entities";
import { MIMETYPE_JSON, MIMETYPE_PDF, MIMETYPE_CSV } from "constants/api";

import { createEntity, readEntity } from "./entities";
import { getReduxType } from "./helpers";
import { force_logout } from "./auth";
import { displayMessage } from "./ui";

export const register = (body) => createEntity(USER_ENTITY, body);

export const read_me = () => readEntity(USER_ENTITY, "");

export const delete_me = (current_password) => ({
  type: getReduxType(REQUEST_STATUS, DESTROY_ACTION),
  body: { current_password },
  meta: {
    body: { current_password },
    entityName: USER_ENTITY,
  },
  options: {
    onSuccess: [
      () => force_logout(),
      () =>
        displayMessage(
          "Votre compte et l'ensemble de vos données ont bien été supprimés."
        ),
    ],
  },
});

export const reset = (current_password) => ({
  type: getReduxType(REQUEST_STATUS, RESET_ACTION),
  body: { current_password },
  meta: {
    body: { current_password },
  },
  options: {
    onSuccess: () =>
      displayMessage("L'ensemble de vos données a été réinitialisé."),
    notifyOnFail: "La réinitialisation de vos données a échoué",
  },
});

export const download_all = () => ({
  type: getReduxType(REQUEST_STATUS, DOWNLOAD_ALL_ACTION),
  meta: {
    extension: "json",
    mimeType: MIMETYPE_JSON,
  },
  options: {
    notifyOnFail: "La génération de votre fichier de données a échoué",
  },
});

export const export_as_pdf = (params) => ({
  type: getReduxType(REQUEST_STATUS, EXPORT_ACTION),
  params,
  meta: {
    extension: "pdf",
    mimeType: MIMETYPE_PDF,
  },
  options: {
    notifyOnFail: "La génération du fichier PDF a échoué",
  },
});

export const export_as_csv = (params) => ({
  type: getReduxType(REQUEST_STATUS, EXPORT_ACTION),
  params,
  meta: {
    extension: "csv",
    mimeType: MIMETYPE_CSV,
  },
  options: {
    notifyOnFail: "La génération du fichier CSV a échoué",
  },
});

export const import_csv = (file) => ({
  type: getReduxType(REQUEST_STATUS, IMPORT_ACTION),
  file,
  meta: {},
  options: {
    notifyOnFail: "L'import du fichier CSV a échoué",
    onSuccess: () => displayMessage("Données importées avec succès"),
  },
});

export const send_message = (title, message) => ({
  type: getReduxType(REQUEST_STATUS, CONTACT_ACTION),
  body: { title, message },
  meta: { body: { title, message } },
});
