import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { Check, Pencil, Text, Delete } from "mdi-material-ui";
import { Link } from "react-router-dom";

import MarkedAvatar from "./MarkedAvatar";
import LogSummary from "./Summary";
import LogDetailedData, {
  SkeletonDetailedData,
} from "components/utils/LogDetailedData";

import { inputUrl, formattedDate } from "./utils";

LogDetailCard.propTypes = {
  log: PropTypes.object,
};

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(3),
    maxWidth: "900px",
  },
  deleteButton: {
    marginLeft: "auto",
  },
}));

export function SkeletonDetailCard({ loading, ...props }) {
  const classes = useStyles();
  const animation = loading ? "pulse" : false;
  return (
    <div {...props}>
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Skeleton
              animation={animation}
              variant="circle"
              width={40}
              height={40}
            />
          }
          title={<Skeleton animation={animation} width="20%" />}
          subheader={<Skeleton animation={animation} width="40%" />}
        />
        <CardContent>
          <SkeletonDetailedData animation={animation} />
        </CardContent>
        <CardActions disableSpacing>
          <IconButton disabled>
            <Check />
          </IconButton>
          <IconButton disabled>
            <Text />
          </IconButton>
          <IconButton disabled>
            <Pencil />
          </IconButton>
          <IconButton disabled className={classes.deleteButton}>
            <Delete />
          </IconButton>
        </CardActions>
      </Card>
    </div>
  );
}

export default function LogDetailCard({
  log,
  onNoteClick,
  onMarkClick,
  onDeleteClick,
  ...props
}) {
  const classes = useStyles();
  return (
    <div {...props}>
      <Card className={classes.card}>
        <CardHeader
          avatar={<MarkedAvatar log={log} onClick={onMarkClick} />}
          title={formattedDate(log)}
          subheader={<LogSummary log={log} />}
        />
        <CardContent>
          <LogDetailedData spacing={2} log={log} />
        </CardContent>
        <CardActions disableSpacing>
          <Tooltip title="Marquer" arrow>
            <IconButton onClick={() => onMarkClick(log)}>
              <Check />
            </IconButton>
          </Tooltip>
          <Tooltip title="Annoter" arrow>
            <IconButton onClick={() => onNoteClick(log)}>
              <Text />
            </IconButton>
          </Tooltip>
          <Tooltip title="Modifier" arrow>
            <IconButton component={Link} to={inputUrl(log)}>
              <Pencil />
            </IconButton>
          </Tooltip>
          <Tooltip title="Supprimer" arrow>
            <IconButton
              onClick={() => onDeleteClick(log)}
              className={classes.deleteButton}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
    </div>
  );
}
