import React from "react";
import PropTypes from "prop-types";
import { Avatar, Icon, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    textAlign: "center",
  },
  avatarImage: {
    height: "100%",
  },
  loading: {
    padding: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
}));

AppIcon.propTypes = {
  loading: PropTypes.bool,
};

export default function AppIcon({ loading }) {
  const classes = useStyles();

  return (
    <Avatar className={classes.avatar}>
      {loading ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <Icon>
          <img
            className={classes.avatarImage}
            src="/static/img/icnalog_appicon.svg"
            alt="Icnalog Web"
          />
        </Icon>
      )}
    </Avatar>
  );
}
