import { LOCATION_CHANGE } from "connected-react-router";
import {
  isRequest,
  isFail,
  isSuccess,
  isAction,
  isEntity,
  isReadAction,
  isImport,
} from "actions/helpers";
import { getPk } from "selectors/entities";
import { LOGOUT_ACTION, RESET_ACTION } from "constants/actions/auth";
import { CLEAR_STATUS_ACTION } from "constants/actions/ui";
import { now } from "middlewares/staleness";

export const DEFAULT_ERRORS = { non_field_errors: "" };

export const getEntityPk = (entity, entityName) => entity[getPk(entityName)];

export const format_errors = ({ error }) =>
  typeof error === "string" ? { non_field_errors: error } : error;

export const isAccountReset = (action) =>
  isSuccess(action) &&
  (isAction(action, LOGOUT_ACTION) || isAction(action, RESET_ACTION));

export const shouldResetData = (action) =>
  isAccountReset(action) || isImport(action);

export const shouldClearStatus = ({ type }) =>
  type === CLEAR_STATUS_ACTION || type === LOCATION_CHANGE;

export const getUpdatedAt = (action) => (isSuccess(action) ? now() : undefined);

export const hasChangedEntities = (action, entityNames) =>
  entityNames.some((entityName) => isEntity(action, entityName)) &&
  !isReadAction(action) &&
  isSuccess(action);

export const getStatus = (action, handle_staleness = false) => {
  return {
    loading: isRequest(action) && (!handle_staleness || action.meta.stale),
    success:
      isRequest(action) || isReadAction(action) ? undefined : isSuccess(action),
    hasErrors: isFail(action),
    errors: isFail(action) ? format_errors(action) : DEFAULT_ERRORS,
  };
};

/**
 * `meta.stale` attribute on request actions indicates that the action has been
 * forwarded to API and waiting for response. For fresh data, `meta.stale` is
 * false and there is no call to API.
 * Reducers should therefore ignore this action
 */
const INITIAL_STATUS = {
  loading: false,
  errors: DEFAULT_ERRORS,
  hasErrors: false,
};

export const clearStatus = (state) => ({
  ...state,
  ...INITIAL_STATUS,
  success: undefined,
});

export const getStatusReducer = (
  actionTest,
  initial_state = INITIAL_STATUS,
  handle_staleness = true
) => (state = initial_state, action) => {
  if (isAccountReset(action)) {
    return initial_state;
  }
  if (shouldClearStatus(action)) {
    return clearStatus(state);
  }
  if (actionTest(action)) {
    return {
      ...state,
      ...getStatus(action, handle_staleness),
    };
  }
  return state;
};

export const getSimpleStatusReducer = (actionName) =>
  getStatusReducer((action) => isAction(action, actionName));
