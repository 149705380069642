import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Link,
  Button,
  IconButton,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link as RouterLink } from "react-router-dom";
import {
  FilterOutline,
  ShareVariant,
  Calendar,
  ChevronDown,
} from "mdi-material-ui";
import { ROUTES } from "routes";
import PageContainer from "components/utils/PageContainer";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  pageTitle: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      paddingBottom: 0,
    },
  },
  title: {},
  question: {
    marginTop: theme.spacing(3),
    fontWeight: "bold",
  },
  firstQuestion: {
    marginTop: 0,
  },
  alert: {
    marginTop: theme.spacing(1),
  },
  answer: {
    marginTop: theme.spacing(1),
  },
  button: {
    padding: 0,
  },
  icon: {
    fontSize: 16,
  },
}));

function Title({ children, ...props }) {
  const classes = useStyles();
  return (
    <Typography
      variant="button"
      align="justify"
      color="secondary"
      className={classes.title}
      {...props}
    >
      {children}
    </Typography>
  );
}

function Question({ children, first, ...props }) {
  const classes = useStyles();
  return (
    <Typography
      variant="body1"
      align="justify"
      className={clsx(classes.question, first && classes.firstQuestion)}
      {...props}
    >
      {children}
    </Typography>
  );
}

function Answer({ children, ...props }) {
  const classes = useStyles();
  return (
    <Typography
      variant="body1"
      align="justify"
      className={classes.answer}
      {...props}
    >
      {children}
    </Typography>
  );
}

function AlertBox({ children, ...props }) {
  const classes = useStyles();
  return (
    <Alert className={classes.alert} {...props}>
      {children}
    </Alert>
  );
}

function ExampleButton({ title, icon, ...props }) {
  const classes = useStyles();
  const small_icon =
    icon && React.cloneElement(icon, { className: classes.icon });
  return title ? (
    <Button
      size="small"
      startIcon={small_icon}
      className={classes.button}
      {...props}
    >
      {title}
    </Button>
  ) : (
    <IconButton size="small" color="inherit">
      {small_icon}
    </IconButton>
  );
}

export default function FAQ(props) {
  const classes = useStyles();
  return (
    <PageContainer className={classes.content}>
      <Typography
        component="h2"
        variant="h6"
        color="primary"
        gutterBottom
        className={classes.pageTitle}
      >
        Foire aux questions
      </Typography>
      <ExpansionPanel defaultExpanded className={classes.panel}>
        <ExpansionPanelSummary expandIcon={<ChevronDown />}>
          <Title>Le site</Title>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>
            <Question first>Qu'est-ce qu'Icnalog ?</Question>

            <Answer>
              Icnalog est un site internet à destination des contrôleurs aériens
              afin de les aider à suivre leur licence et les heures de contrôle.
            </Answer>

            <Question>
              Je peux m'en servir sur mon mobile ou ma tablette ?
            </Question>
            <Answer>
              Tout à fait. Ce site est adapté à l'affichage sur les petits
              écrans (mobile ou tablette). Il existe aussi une application pour
              Android disponible sur le{" "}
              <Link href="https://play.google.com/store/apps/details?id=com.icnalog.icnalog&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                Google Play Store
              </Link>
              .
            </Answer>

            <Question>C'est gratuit ?</Question>

            <Answer>Oui, ni abonnement ni publicités.</Answer>
            <Answer>
              Cependant, si vous le souhaitez, vous pouvez participer aux frais
              d'hébergement du site{" "}
              <Link
                href="https://cagnotte.me/31854-icnalog-web/fr"
                rel="external nofollow noreferrer noopener"
                target="_blank"
              >
                ici
              </Link>{" "}
              (merci !)
            </Answer>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.panel}>
        <ExpansionPanelSummary expandIcon={<ChevronDown />}>
          <Title>Heures et licence</Title>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>
            <Question>Je viens de créer un compte. Par où commencer ?</Question>
            <Answer>
              Rendez-vous sur la page{" "}
              <Link component={RouterLink} to={ROUTES.licence.path}>
                Licence
              </Link>{" "}
              et renseignez les informations concernant votre mention d'unité
              (intitulé, date d'obtention et nom du titulaire) ainsi que les
              zones d'occurrence associées à cette MU.
            </Answer>
            <AlertBox severity="info">
              Si vous avez déjà saisi des infos dans l'application Android, il
              est recommandé de commencer par synchroniser vos données en vous
              connectant à votre compte dans l'application.
            </AlertBox>

            <Question>
              Comment faire pour saisir les heures que j'ai effectué aujourd'hui
              ?
            </Question>
            <Answer>
              Ouvrez la page{" "}
              <Link component={RouterLink} to={ROUTES.today.path}>
                Aujourd'hui
              </Link>{" "}
              pour saisir vos heures et occurrences quotidiennes. Vous pouvez
              ouvrir la page d'une date donnée grâce au bouton{" "}
              <ExampleButton icon={<Calendar />} /> accessible en haut à droite
              (ou, si vous utilisez l'application, sur la date dans la barre
              d'outils en haut de l'écran).
            </Answer>

            <Question>Comment faire pour envoyer mon relevé mensuel ?</Question>
            <Answer>
              Rendez-vous sur la page{" "}
              <Link component={RouterLink} to={ROUTES.list.path}>
                Liste
              </Link>{" "}
              où vous trouverez la liste de tous les jours travaillés que vous
              avez saisi au préalable.
            </Answer>
            <Answer>
              Cliquez sur le bouton{" "}
              <ExampleButton title="Filtrer" icon={<FilterOutline />} /> pour
              sélectionner la période que vous souhaitez extraire (et d'autres
              critères éventuellement).
            </Answer>
            <Answer>
              Enfin, cliquez sur le bouton{" "}
              <ExampleButton title="Exporter" icon={<ShareVariant />} /> et
              sélectionner le format du fichier à générer.
            </Answer>
            <Answer>
              Si vous utilisez l'application, cliquez sur le bouton{" "}
              <ExampleButton icon={<ShareVariant />} /> dans la barre d'outils
              en haut de l'écran.
            </Answer>

            <Question>
              Je suis muté·e. Je peux continuer à utiliser Icnalog ?
            </Question>

            <Answer>
              Vous pouvez réinitialiser vos données depuis la page{" "}
              <Link component={RouterLink} to={ROUTES.account.path}>
                Mon compte
              </Link>{" "}
              pour renseigner votre nouvel MU, comme si vous commenciez un
              nouveau carnet.
            </Answer>
            <AlertBox severity="warning">
              Il est conseillé de télécharger vos données au préalable (toujours
              depuis la page{" "}
              <Link component={RouterLink} to={ROUTES.account.path}>
                Mon compte
              </Link>
              ).
            </AlertBox>
            <Answer>
              Une nouvelle fonctionnalité est en cours de dévelopement, qui vous
              permettra d'archiver vos heures et de garder la trace de vos MU
              antérieures.
            </Answer>

            <Question>
              Le fonctionnement d'Icnalog ne correspond pas à la façon dont
              fonctionne ma mention d'unité. Je ne peux pas suivre mes heures
              correctement !
            </Question>

            <Answer>
              Le site a été conçu en consultant des contrôleurs de plusieurs
              centres et approches mais il est possible qu'on soit passés à côté
              de certains cas particuliers.
            </Answer>
            <Answer>
              Dans ce cas, n'hésitez pas à nous signaler le problème grâce au
              formulaire de contact ou en envoyant un email à{" "}
              <strong>contact [arobase] icnalog [point] com</strong> en
              précisant le problème et votre mention d'unité.
            </Answer>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel className={classes.panel}>
        <ExpansionPanelSummary expandIcon={<ChevronDown />}>
          <Title>Vie privée</Title>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>
            <Question>Comment sont utilisées mes données ?</Question>

            <Answer>
              Toutes les données concernant votre licence et vos heures sont
              privées.
            </Answer>
            <Answer>
              Les renseignements concernant votre licence sont uniquement
              utilisés de manière à générer des relevés d'heures correspondant
              aux spécifications de l'autorité (mention d'unité et nom du
              titulaire doivent être indiqués dans le fichier).
            </Answer>
            <Answer>
              Votre mot de passe n'est jamais stocké en clair nulle part. Si
              vous le perdez, la seule solution est de demander sa{" "}
              <Link component={RouterLink} to={ROUTES.reset_password.path}>
                réinitialisation
              </Link>
              .
            </Answer>
            <Answer>
              Les seules informations vous concernant qui sont utilisées
              autrement que par vous-même concernent votre navigation sur le
              site. Ces données sont utilisées uniquement par l'administrateur à
              des fin de corrections de bug et d'amélioration du site.
            </Answer>

            <Question>Comment récupérer toutes mes données ?</Question>
            <Answer>
              Rendez-vous sur la page{" "}
              <Link component={RouterLink} to={ROUTES.account.path}>
                Mon compte
              </Link>{" "}
              et cliquez sur le bouton <ExampleButton title="Télécharger" />.
              Votre navigateur vous proposera d'enregistrer un fichier au format{" "}
              <Link href="https://fr.wikipedia.org/wiki/JavaScript_Object_Notation">
                JSON
              </Link>
              , c'est-à-dire un fichier contenant vos données sous forme de
              texte structuré. Vous pouvez ouvrir ce fichier avec n'importe quel
              éditeur de texte (comme le Bloc-notes par exemple).
            </Answer>
            <Answer>
              Vous pouvez aussi télécharger vos heures au format{" "}
              <Link href="https://fr.wikipedia.org/wiki/Comma-separated_values">
                CSV
              </Link>
              , pour les manipuler avec Excel. Pour celà, cliquez sur le bouton{" "}
              <ExampleButton title="Exporter CSV" />
            </Answer>

            <Question>Comment supprimer mon compte ?</Question>

            <Answer>
              Rendez-vous sur la page{" "}
              <Link component={RouterLink} to={ROUTES.account.path}>
                Mon compte
              </Link>{" "}
              et cliquez sur le bouton{" "}
              <ExampleButton title="Supprimer mon compte" />
            </Answer>
            <AlertBox severity="info">
              Cette fonctionnalité n'est pas accessible depuis l'application.
            </AlertBox>
            <AlertBox severity="warning">
              Il est conseillé de télécharger vos données au préalable (toujours
              depuis la page{" "}
              <Link component={RouterLink} to={ROUTES.account.path}>
                Mon compte
              </Link>
              ).
            </AlertBox>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel className={classes.panel}>
        <ExpansionPanelSummary expandIcon={<ChevronDown />}>
          <Title>Application Android</Title>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>
            <Question>
              J'utilise l'application Android sur mon mobile. Comment faire pour
              retrouver mes heures sur le site ?
            </Question>

            <Answer>
              Depuis l'application, vous pouvez vous connecter à votre compte
              depuis la page "Mon compte" (accessible via le menu additionnel en
              haut à droite). Vos données se synchroniseront alors
              automatiquement entre le site et l'application.
            </Answer>

            <Question>
              J'utilise l'application Android mais je ne veux pas envoyer mes
              données sur un serveur !
            </Question>

            <Answer>
              L'application Icnalog fonctionne parfaitement sans connexion à
              Icnalog Web. Il vous suffit de ne PAS vous connecter.
            </Answer>
            <AlertBox severity="info">
              Il ne sera pas possible de synchroniser vos données entre
              plusieurs appareils (à moins de le faire manuellement en utilisant
              les fonctionnalités d'import/export).
            </AlertBox>
            <AlertBox severity="warning">
              Vos données ne seront pas sauvegardées ! Elles seront uniquement
              présentes sur votre appareil. Si vous perdez votre appareil ou
              s'il est endommagé, vos données seront définitivement perdues.
            </AlertBox>

            <Question>
              J'ai supprimé des données sur le site mais elles apparaissent
              toujours dans l'application. Comment faire ?
            </Question>
            <Answer>
              Vous pouvez les supprimer manuellement depuis l'application.
            </Answer>
            <Answer>
              Si cela concerne une grande quantité de données, vous pouvez
              utiliser le bouton{" "}
              <ExampleButton title="Forcer synchronisation" /> disponible dans
              le menu "Mon compte" de l'application. Il vous sera proposer de
              supprimer l'ensemble des données locales et de les remplacer par
              les données présentes sur le serveur.
            </Answer>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </PageContainer>
  );
}
