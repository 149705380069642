import React from "react";
import moment from "moment";
import { Chip, Tooltip, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Calendar,
  CheckAll,
  Cancel,
  School,
  AccountSupervisor,
} from "mdi-material-ui";
import * as filters from "constants/ui";
import { DATE_FORMAT_SHORT } from "constants/ui";

export function periodForDisplay(start, end) {
  if (start || end) {
    const start_display = start
      ? moment(start).format(DATE_FORMAT_SHORT)
      : "...";
    const end_display = end ? moment(end).format(DATE_FORMAT_SHORT) : "...";

    return `${start_display} - ${end_display}`;
  } else {
    return "tout";
  }
}

export function markForDisplay(mark, default_text) {
  switch (mark) {
    case filters.MARK_FILTER_MARKED:
      return "marqués";
    case filters.MARK_FILTER_UNMARKED:
      return "non marqués";
    case filters.MARK_FILTER_ALL:
    default:
      return default_text;
  }
}

export function typeForDisplay(type) {
  return type ? "visible" : "non visible";
}

const useStyles = makeStyles((theme) => ({
  filterLabel: {
    minWidth: "0%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginLeft: theme.spacing(1),
    whiteSpace: "nowrap",
  },
  toolbarItem: {
    marginRight: theme.spacing(1),
  },
}));

export default function FilterLabel({
  query,
  onDateFilterClick,
  onDateFilterDelete,
  onMarkFilterClick,
  onMarkFilterDelete,
  onSimFilterDelete,
  onDualFilterDelete,
  noVisibility,
  ...props
}) {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const { start, end, mark, sim, dual } = query;

  function markIcon(mark, ...props) {
    switch (mark) {
      case filters.MARK_FILTER_MARKED:
        return <CheckAll {...props} />;
      case filters.MARK_FILTER_UNMARKED:
        return <Cancel {...props} />;
      case filters.MARK_FILTER_ALL:
      default:
        return undefined;
    }
  }

  function showMarkChip(mark) {
    return (
      mark === filters.MARK_FILTER_MARKED ||
      mark === filters.MARK_FILTER_UNMARKED
    );
  }

  function showDateRangeChip(start, end) {
    return start || end;
  }

  function showSimChip() {
    return !noVisibility && sim !== undefined;
  }

  function showDualChip() {
    return !noVisibility && dual !== undefined;
  }

  return (
    <div className={classes.filterLabel} {...props}>
      {showDateRangeChip(start, end) && (
        <Tooltip title={(!smUp && periodForDisplay(start, end)) || ""} arrow>
          <Chip
            label={smUp && periodForDisplay(start, end)}
            icon={<Calendar />}
            onClick={onDateFilterClick}
            onDelete={onDateFilterDelete}
            className={classes.toolbarItem}
          />
        </Tooltip>
      )}
      {showMarkChip(mark) && (
        <Tooltip title={(!smUp && markForDisplay(mark, "")) || ""} arrow>
          <Chip
            label={smUp && markForDisplay(mark, "")}
            icon={markIcon(mark)}
            onClick={onMarkFilterClick}
            onDelete={onMarkFilterDelete}
            className={classes.toolbarItem}
          />
        </Tooltip>
      )}
      {showSimChip() && (
        <Tooltip
          title={
            (!smUp && `Heures simu : ${typeForDisplay(sim !== "false")}`) || ""
          }
          arrow
        >
          <Chip
            label={smUp && typeForDisplay(sim !== "false")}
            icon={<School />}
            onDelete={onSimFilterDelete}
            className={classes.toolbarItem}
          />
        </Tooltip>
      )}
      {showDualChip() && (
        <Tooltip
          title={
            (!smUp &&
              `Heures en double : ${typeForDisplay(dual !== "false")}`) ||
            ""
          }
          arrow
        >
          <Chip
            label={smUp && typeForDisplay(dual !== "false")}
            icon={<AccountSupervisor />}
            onDelete={onDualFilterDelete}
            className={classes.toolbarItem}
          />
        </Tooltip>
      )}
    </div>
  );
}
