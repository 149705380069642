import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  CardActions,
  IconButton,
  Typography,
  TextField,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { Pencil } from "mdi-material-ui";

NoteInput.propTypes = {
  value: PropTypes.string,
  onEdit: PropTypes.func,
  loading: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    height: "100%",
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: theme.spacing(1),
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  action: {
    padding: 0,
  },
  note: {
    wordBreak: "break-all",
  },
}));

function NoteInput({ value: default_value, loading, onEdited }) {
  const classes = useStyles();
  const [editMode, setEditMode] = React.useState(false);
  const [value, setValue] = React.useState("");
  //TODO form hook ? custom hook ?

  //update state from props
  useEffect(() => {
    setValue(default_value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [default_value]);

  //call onEdited when exiting edit mode
  useEffect(() => {
    if (!editMode && value !== default_value && onEdited) {
      onEdited(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, loading]);

  const handleEditButtonClick = () => {
    setEditMode(!editMode);
  };

  const handleNoteInput = (e) => {
    if (editMode && e.target.value !== value) {
      setValue(e.target.value);
    }
  };

  const handleTextFieldBlurred = (e) => {
    setEditMode(false);
  };

  const skeleton = (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Skeleton className={classes.title} />
        <Skeleton />
      </CardContent>
      <CardActions className={classes.actions}>
        <IconButton disabled>
          <Pencil fontSize="large" />
        </IconButton>
      </CardActions>
    </Card>
  );

  return loading ? (
    skeleton
  ) : (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Typography className={classes.title}>Note</Typography>
        {editMode ? (
          <TextField
            id="note"
            name="note"
            autoFocus
            value={value}
            onChange={handleNoteInput}
            onBlur={handleTextFieldBlurred}
          />
        ) : (
          <Typography component="h5" variant="h5" className={classes.note}>
            {value || "Vide"}
          </Typography>
        )}
      </CardContent>
      <CardActions className={classes.actions}>
        <IconButton onClick={handleEditButtonClick} disabled={editMode}>
          <Pencil fontSize="large" />
        </IconButton>
      </CardActions>
    </Card>
  );
}

export default React.memo(NoteInput);
