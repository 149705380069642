import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  CardActions,
  ButtonGroup,
  Button,
  Typography,
  Box,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { ChevronUp, ChevronDown } from "mdi-material-ui";

import { HOURS } from "constants/hours";
import { HourIcon, label, color, duration } from "components/utils/HourUtils";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    minWidth: "200px",
  },
  indicator: {
    width: theme.spacing(1),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    zIndex: 500,
  },
  typeIconContainer: {
    display: "flex",
    flex: "1 1 0%",
    alignItems: "center",
    justifyContent: "end",
    position: "relative",
    minWidth: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    zIndex: 400,
  },
  typeIcon: {
    color: theme.palette.grey[300],
    fontSize: 50,
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  actions: {
    padding: 0,
  },
  actionButtons: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0.5),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    color: theme.palette.text.secondary,
  },
  actionButtonIcon: {},
}));

function HourInput({ value, type, onStepClick }) {
  const theme = useTheme();
  const classes = useStyles();

  const skeleton = (
    <Card className={classes.card}>
      <Box className={classes.indicator} bgcolor={color(theme, type)} />
      <CardContent className={classes.content}>
        <Typography className={classes.title}>{label(type)}</Typography>
        <Skeleton width={200} />
      </CardContent>
      <CardContent className={classes.typeIconContainer} />
      <CardActions className={classes.actions}>
        <ButtonGroup orientation="vertical" variant="text">
          <Button className={classes.actionButtons} disabled>
            <ChevronUp className={classes.actionButtonIcon} fontSize="large" />
          </Button>
          <Button className={classes.actionButtons} disabled>
            <ChevronDown
              className={classes.actionButtonIcon}
              fontSize="large"
            />
          </Button>
        </ButtonGroup>
      </CardActions>
    </Card>
  );

  return value === undefined ? (
    skeleton
  ) : (
    <Card className={classes.card}>
      <Box className={classes.indicator} bgcolor={color(theme, type)} />
      <CardContent className={classes.content}>
        <Typography className={classes.title}>{label(type)}</Typography>
        <Typography component="h5" variant="h5" noWrap>
          {duration(value)}
        </Typography>
      </CardContent>
      <CardContent className={classes.typeIconContainer}>
        <HourIcon type={type} className={classes.typeIcon} />
      </CardContent>
      <CardActions className={classes.actions}>
        <ButtonGroup orientation="vertical" variant="text">
          <Button className={classes.actionButtons}>
            <ChevronUp
              className={classes.actionButtonIcon}
              fontSize="large"
              onClick={() => onStepClick(1)}
            />
          </Button>
          <Button className={classes.actionButtons}>
            <ChevronDown
              className={classes.actionButtonIcon}
              fontSize="large"
              onClick={() => onStepClick(-1)}
            />
          </Button>
        </ButtonGroup>
      </CardActions>
    </Card>
  );
}

HourInput.propTypes = {
  value: PropTypes.number,
  onPlusClick: PropTypes.func,
  onMinusClick: PropTypes.func,
  type: PropTypes.oneOf(Object.keys(HOURS)),
};

export default React.memo(HourInput);
