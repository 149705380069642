import React, { useState } from "react";
import moment from "moment";
import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";
import { DATE_FORMAT_SHORT } from "constants/ui";
import { useDialog } from "hooks/ui";

function DeleteConfirmDialog({ log, open, onClose }) {
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Supprimer le log du{" "}
        {log && `${moment(log.date).format(DATE_FORMAT_SHORT)}`} ?
      </DialogTitle>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Annuler
        </Button>
        <Button onClick={() => onClose(true)} color="primary" autoFocus>
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const useDeleteConfirmDialog = (onConfirm) => {
  const { isOpen, open, close } = useDialog();
  const [current_log, setCurrentLog] = useState();

  const handleOpen = (log) => {
    setCurrentLog(log);
    open();
  };

  const onClose = (confirmed) => {
    if (confirmed) {
      onConfirm(current_log);
    }
    close();
  };

  const display = (
    <DeleteConfirmDialog log={current_log} open={isOpen} onClose={onClose} />
  );
  return { open: handleOpen, display };
};
