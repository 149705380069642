import React from "react";
import { Container, Grid, Tabs, Tab, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import StickySecondaryToolbar from "components/utils/StickySecondaryToolbar";
import SetPassword from "./SetPassword";
import UserManagement from "./UserManagement";

const useStyles = makeStyles((theme) => ({
  tabs: {
    width: "100%",
  },
  page: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
}));

export default function AccountPage() {
  const classes = useStyles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [tab, setTab] = React.useState(0);

  return mdUp ? (
    <Container maxWidth="lg" disableGutters>
      <Grid container spacing={3} className={classes.page}>
        <Grid item xs={12} md={6} lg={4}>
          <SetPassword />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <UserManagement />
        </Grid>
      </Grid>
    </Container>
  ) : (
    <div>
      <StickySecondaryToolbar>
        <Tabs
          className={classes.tabs}
          variant="fullWidth"
          value={tab}
          onChange={(e, new_tab) => setTab(new_tab)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Mot de passe" />
          <Tab label="Données" />
        </Tabs>
      </StickySecondaryToolbar>
      <Container disableGutters maxWidth="xs">
        {tab === 0 && <SetPassword />}
        {tab === 1 && <UserManagement />}
      </Container>
    </div>
  );
}
