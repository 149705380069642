import { saveAs } from "file-saver";
import moment from "moment";
import { MIMETYPE_JSON, MIMETYPE_PDF, MIMETYPE_CSV } from "constants/api";
import { isSuccess } from "actions/helpers";

const downloadMiddleware = (store) => (next) => (action) => {
  const { meta, payload } = action;
  if (isSuccess(action) && meta && payload) {
    const { mimeType, extension } = meta;
    if (mimeType) {
      const filename = payload.filename || generateFilename(extension);
      let data;
      switch (mimeType) {
        case MIMETYPE_JSON:
          data = JSON.stringify(payload);
          break;
        case MIMETYPE_PDF:
          data = payload.buffer;
          break;
        case MIMETYPE_CSV:
          data = payload;
          break;
        default:
          break;
      }
      const blob = new Blob([data], {
        type: mimeType,
      });
      saveAs(blob, filename);
    }
  }
  next(action);
};

function generateFilename(extension) {
  const timestamp = moment().format("YYYYMMDDhhmmss");
  return `icnalogweb-${timestamp}.${extension}`;
}

export default downloadMiddleware;
