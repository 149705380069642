import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import PageContainer from "components/utils/PageContainer";
import ErrorBox from "components/utils/ErrorBox";
import StateButton from "components/utils/StateButton";

import { useAuthState } from "hooks";
import { useForm } from "hooks/forms";
import { useAuthenticated } from "hooks/user";
import { send_message } from "actions/user";
import { SEND_ADMIN_MESSAGE_STATE } from "constants/status";

const useStyles = makeStyles((theme) => ({
  content: {},
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonProgress: {
    marginLeft: 8,
    color: "inherit",
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  email: {
    fontWeight: "bold",
  },
}));

export default function ContactForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const is_authenticated = useAuthenticated();
  const state = useAuthState(SEND_ADMIN_MESSAGE_STATE);
  const { loading, errors, success } = state;
  const { values, touched, handleUserInput, handleSubmit, reset } = useForm(
    {
      title: "",
      message: "",
    },
    ({ title, message }) => dispatch(send_message(title, message))
  );

  useEffect(() => {
    if (success) {
      reset({
        title: "",
        message: "",
      });
    }
  }, [success, reset]);

  return (
    <PageContainer title="Contact">
      {!is_authenticated ? (
        <div>
          <Typography align="justify" variant="body2">
            La FAQ ne répond pas à votre question ? Vous pensez avoir trouvé un
            bug ?
          </Typography>
          <Typography align="justify" variant="body2" gutterBottom>
            Vous pouvez contacter l'administrateur à l'adresse :{" "}
            <span className={classes.email}>
              contact [arobase] icnalog [point] com
            </span>
          </Typography>
        </div>
      ) : (
        <div>
          <Typography align="justify" variant="body2">
            La FAQ ne répond pas à votre question ? Vous pensez avoir trouvé un
            bug ? Ou tout simplement pour dire bonjour...
          </Typography>
          <Typography align="justify" variant="body2" gutterBottom>
            Envoyez un message à l'administrateur !
          </Typography>
          <ErrorBox errorList={[errors.non_field_errors]} />
          {success && (
            <Alert className={classes.alert} severity="success">
              Votre message a bien été envoyé !
            </Alert>
          )}
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="title"
                  label="Titre"
                  name="title"
                  value={values.title || ""}
                  error={Boolean(errors && errors.title) && !touched.title}
                  helperText={errors && errors.title}
                  onChange={handleUserInput}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="message"
                  label="Message"
                  name="message"
                  multiline
                  rows={8}
                  value={values.message || ""}
                  error={Boolean(errors && errors.message) && !touched.message}
                  helperText={errors && errors.message}
                  onChange={handleUserInput}
                />
              </Grid>
              <Grid item xs={12}>
                <StateButton
                  state={state}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={loading}
                  title="Envoyer"
                  onClick={handleSubmit}
                />
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </PageContainer>
  );
}
