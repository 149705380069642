import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  CircularProgress,
  Tooltip,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AlertCircleOutline, Check } from "mdi-material-ui";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    marginRight: 8,
  },
  success: {
    color: theme.palette.success.main,
  },
}));

StateButton.propTypes = {
  state: PropTypes.shape({
    loading: PropTypes.bool,
    hasErrors: PropTypes.bool,
    success: PropTypes.bool,
  }),
  title: PropTypes.string,
  props: PropTypes.object,
};

export default function StateButton({
  state = {},
  title,
  startIcon,
  endIcon,
  responsive,
  ...props
}) {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const big = (
    <Button
      disabled={state.loading}
      startIcon={
        state.hasErrors ? (
          <AlertCircleOutline color="error" />
        ) : state.success ? (
          <Check className={classes.success} />
        ) : (
          !state.loading && startIcon
        )
      }
      variant="outlined"
      endIcon={endIcon}
      {...props}
    >
      {state.loading && (
        <CircularProgress
          size={16}
          color="inherit"
          className={classes.buttonProgress}
        />
      )}
      {title}
    </Button>
  );

  const small = responsive && (
    <Tooltip title={title} arrow>
      <IconButton disabled={state.loading} {...props}>
        {state.loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          startIcon || endIcon
        )}
      </IconButton>
    </Tooltip>
  );

  return responsive && !smUp ? small : big;
}
