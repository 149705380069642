import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, CircularProgress, TextField } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { Alert } from "@material-ui/lab";

import PageContainer from "components/utils/PageContainer";
import ErrorBox from "components/utils/ErrorBox";

import { readLicence, updateLicence } from "actions";
import { useLicence } from "hooks/entities";
import { useForm } from "hooks/forms";
import { DATE_FORMAT_DATA, DATE_FORMAT_SHORT } from "constants/ui";
import { isStale } from "middlewares/staleness";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  buttonProgress: {
    marginLeft: 8,
    color: "inherit",
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
}));

export default function Licence() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const entity = useLicence();
  const { loading, errors, success, updated_at } = entity;
  const initial_values = {
    title: entity.title || "",
    holder: entity.holder || "",
    date: entity.date || "2020-01-01",
  };
  const { values, touched, handleUserInput, handleSubmit, reset } = useForm(
    initial_values,
    (values) => {
      dispatch(updateLicence(values));
    }
  );

  const hasErrors = (attribute) =>
    Boolean(errors && errors[attribute]) && !touched[attribute];

  //request fresh data on mount
  useEffect(() => {
    if (isStale(updated_at)) {
      dispatch(readLicence());
    }
  }, [dispatch, updated_at]);

  useEffect(() => {
    const { title, holder, date, hasErrors, loading } = entity;
    //update form values if not pending and no errors
    //otherwise current form values are overwritten by server ones
    if (!loading && !hasErrors) {
      reset({ title, holder, date });
    }
  }, [entity, reset]);

  return (
    <PageContainer title="Ma licence">
      <Alert className={classes.alert} severity="info">
        Votre nom sera utilisé uniquement sur les relevés d'heures afin qu'ils
        soient réglementaires.
      </Alert>
      <ErrorBox errorList={[errors.non_field_errors]} />
      {success && (
        <Alert className={classes.alert} severity="success">
          Votre licence est enregistrée !
        </Alert>
      )}
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="title"
              label="Mention d'Unité"
              placeholder="LFZZ"
              name="title"
              value={values.title || ""}
              error={hasErrors("title")}
              helperText={hasErrors("title") && errors.title}
              onChange={handleUserInput}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="holder"
              placeholder="Prénom NOM"
              label="Nom complet du détenteur"
              name="holder"
              value={values.holder || ""}
              error={hasErrors("holder")}
              helperText={hasErrors("holder") && errors.holder}
              onChange={handleUserInput}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              autoOk
              inputformat={DATE_FORMAT_SHORT}
              format={DATE_FORMAT_DATA}
              openTo="year"
              mask="__/__/____"
              value={values.date}
              onChange={(date) =>
                handleUserInput({ target: { name: "date", value: date } })
              }
              renderInput={(props) => (
                <TextField
                  {...props}
                  fullWidth
                  label="Date d'obtention"
                  variant="outlined"
                  helperText={
                    hasErrors("date") && "Ceci n'est pas une date valide."
                  }
                  error={hasErrors("date")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              Enregistrer
              {loading && (
                <CircularProgress
                  size={16}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </PageContainer>
  );
}
