import React from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import {
  deepPurple,
  cyan,
  blue,
  lightBlue,
  purple,
  red,
  deepOrange,
  yellow,
  pink,
  teal,
  green,
} from "@material-ui/core/colors";

const completeColor = (color) => ({
  ...color,
  main: color[500],
  light: color[300],
  dark: color[700],
});

const icnalog_theme = createMuiTheme({
  palette: {
    primary: deepPurple, //already completed by default
    secondary: lightBlue, //already completed by default
    standard: completeColor(deepPurple),
    ojti: completeColor(cyan),
    sim: completeColor(blue),
    dual: completeColor(purple),
    error: completeColor(red), //force default with all variant for progress bar displays
    success: completeColor(green), //force default with all variant for progress bar displays
    dynamic: [
      completeColor(blue),
      completeColor(deepOrange),
      completeColor(yellow),
      completeColor(pink),
      completeColor(teal),
      completeColor(purple),
    ],
  },
});

export const getPalette = (theme, style, index = 0) => {
  if (style !== "dynamic" && theme.palette.hasOwnProperty(style)) {
    return theme.palette[style];
  } else {
    const palette_size = theme.palette.dynamic.length;
    return theme.palette.dynamic[index % palette_size];
  }
};

const Theming = ({ children }) => (
  <ThemeProvider theme={icnalog_theme}>{children}</ThemeProvider>
);

export default Theming;
