import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import { StaticDateRangePicker } from "@material-ui/pickers";

function CalendarDialog({ open, start, end, onClose }) {
  const [range, setDateRange] = React.useState([
    moment(start) || null,
    moment(end) || null,
  ]);

  useEffect(() => {
    setDateRange([moment(start) || null, moment(end) || null]);
  }, [start, end]);

  const handleRangeChange = ([start, end]) => {
    setDateRange([start, end]);
  };

  const handleActionClick = (confirmed) => {
    onClose(confirmed, range);
  };

  return (
    <Dialog open={open} onClose={() => handleActionClick(false)}>
      <StaticDateRangePicker
        label="Sélectionner une période"
        toolbarTitle="Sélectionner une période"
        toText="au"
        startText="Début"
        endText="Fin"
        value={range}
        onChange={handleRangeChange}
      />
      <DialogActions>
        <Button onClick={() => handleActionClick(false)} color="primary">
          Annuler
        </Button>
        <Button
          onClick={() => handleActionClick(true)}
          color="primary"
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DateRangeFilterDialog.propTypes = {
  query: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  lastLicenceDate: PropTypes.string,
  open: PropTypes.bool,
  onDateRangeChange: PropTypes.func,
  onClose: PropTypes.func,
};

export default function DateRangeFilterDialog({
  query,
  lastLicenceDate,
  open,
  onClose,
  onDateRangeChange,
}) {
  const [calendarIsOpen, setCalendarIsOpen] = React.useState(false);
  const { start, end } = query;

  const handleCalendarButtonClick = () => {
    setCalendarIsOpen(true);
    onClose();
  };

  const handleDateRangeSelection = (confirmed, result) => {
    setCalendarIsOpen(false);
    onClose();
    if (confirmed) {
      onDateRangeChange(...result);
    }
  };

  const ranges = [
    {
      label: "Ce mois-ci",
      start: moment().startOf("month"),
      end: moment().endOf("month"),
      enabled: true,
    },
    {
      label: "Le mois dernier",
      start: moment().subtract(1, "month").startOf("month"),
      end: moment().subtract(1, "month").endOf("month"),
      enabled: true,
    },
    {
      label: "90 derniers jours",
      start: moment().subtract(89, "days"),
      enabled: true,
    },
    { label: "Cette année", start: moment().startOf("year"), enabled: true },
    {
      label: "Depuis mention d'unité",
      start: lastLicenceDate,
      enabled: Boolean(lastLicenceDate),
    },
    { label: "Tout", enabled: true },
  ];

  const calendar = (
    <CalendarDialog
      open={calendarIsOpen}
      start={start}
      end={end}
      onClose={handleDateRangeSelection}
    />
  );

  const choices = ranges
    .filter(({ enabled }) => enabled)
    .map(({ label, start, end }, i) => (
      <MenuItem
        key={i}
        onClick={() => handleDateRangeSelection(true, [start, end])}
      >
        {label}
      </MenuItem>
    ));

  return (
    <Fragment>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
        <DialogTitle>Filtrer la période</DialogTitle>
        <MenuList>
          {choices}
          <MenuItem onClick={handleCalendarButtonClick}>Personnalisé</MenuItem>
        </MenuList>
      </Dialog>
      {calendar}
    </Fragment>
  );
}
