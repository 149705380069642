import { createSelector } from "reselect";
import { getQueryParams } from "./router";
import * as actions from "constants/actions/stats";
import { isAction } from "actions/helpers";
import { idFromQuery } from "selectors/queries";

const getStatState = (state, type) => state.stats[type];

const actionTypeToState = (type) => {
  if (type.endsWith(actions.STATS_DEADLINES_ACTION)) {
    return "deadlines";
  }
  if (type.endsWith(actions.STATS_CHARTS_ACTION)) {
    return "charts";
  }
  throw new TypeError(`${type} is not a stat type`);
};

export const getStatStateForAction = (state, action) => {
  if (isAction(action, actions.STATS_CHARTS_ACTION)) {
    const id = idFromQuery(action.params);
    return getStatState(state, actionTypeToState(action.type))[id];
  }
  return getStatState(state, actionTypeToState(action.type));
};

export const getDeadlines = (state) => state.stats.deadlines;

export const getTargets = (state) => state.stats.targets;

export const getCurrentStats = createSelector(
  [getQueryParams, (state) => state.stats.charts],
  (query, charts) => {
    const id = idFromQuery(query);
    return (
      charts[id] || {
        loading: false,
        hasErrors: false,
        errors: { non_field_errors: "" },
      }
    );
  }
);
