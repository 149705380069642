import { LOG_ENTITY } from "constants/entities";
import { getQueryParams } from "selectors/router";

export const idFromQuery = (params) =>
  `?${Object.entries(params)
    .filter(([key, value]) => value)
    .sort(([keya, valuea], [keyb, valueb]) => keya - keyb)
    .map(([key, value]) => `${key}=${value}`)
    .join("&")}`;
//keys must be sorted so that same query has always same id

export const getQueryFromParams = (params) => (state) => {
  const id = idFromQuery({ page: 1, ...params });
  return (
    state.queries[id] || {
      loading: false,
      hasErrors: false,
      count: 0,
      errors: { non_field_errors: "" },
      query: [],
    }
  );
};

export const getQueryCountFromParams = (params) => (state) => {
  return getQueryFromParams(params)(state).count;
};

export const getQueryWithLogFromParams = (params) => (state) => {
  const query = getQueryFromParams(params)(state);
  const data = state.entities[LOG_ENTITY];
  return {
    ...query,
    loading: Boolean(query.loading || data.loading),
    entities: query.query.map((pk) => data.entities[pk]) || [],
  };
};

export const getCurrentQuery = (state) =>
  getQueryFromParams(getQueryParams(state))(state);
