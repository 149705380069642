import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  CircularProgress,
  Fab,
} from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import { Plus, Pencil, Delete } from "mdi-material-ui";

import PageContainer from "components/utils/PageContainer";
import ErrorBox from "components/utils/ErrorBox";
import useZoneCreateDialog from "./ZoneCreateDialog";
import useZoneEditDialog from "./ZoneEditDialog";
import useZoneDeleteDialog from "./ZoneDeleteDialog";

import {
  listEntities,
  createEntity,
  updateEntity,
  destroyEntity,
} from "actions/entities";
import { ZONE_ENTITY } from "constants/entities";
import { useZones } from "hooks/entities";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  page: {},
  content: { paddingTop: theme.spacing(2) },
  item: {
    "&:hover": {
      textDecoration: "none",
      backgroundColor: theme.palette.action.hover,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  fab: {
    position: "absolute",
    margin: theme.spacing(2),
    right: 0,
    bottom: theme.mixins.toolbar.minHeight,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      bottom:
        theme.mixins.toolbar[
          `${theme.breakpoints.up("xs")} and (orientation: landscape)`
        ].minHeight,
    },
    [theme.breakpoints.up("sm")]: {
      bottom: theme.mixins.toolbar[theme.breakpoints.up("sm")].minHeight,
    },
  },
}));

function SkeletonList({ size }) {
  return (
    <List dense>
      {[...Array(size)].map((x, i) => (
        <ListItem key={i}>
          <ListItemText primary={<Skeleton width={220} />} />
          <ListItemSecondaryAction>
            <IconButton disabled>
              <Pencil />
            </IconButton>
            <IconButton edge="end" disabled>
              <Delete />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
}

export default function ZoneManagement() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { entities: zones, loading, errors } = useZones();
  const createDialog = useZoneCreateDialog((name) =>
    dispatch(createEntity(ZONE_ENTITY, { name }))
  );
  const editDialog = useZoneEditDialog((zone, new_name) =>
    dispatch(updateEntity(ZONE_ENTITY, zone.id, { name: new_name }))
  );
  const deleteDialog = useZoneDeleteDialog((zone) =>
    dispatch(destroyEntity(ZONE_ENTITY, zone.id))
  );

  const isLoading = () => loading || zones.some((zone) => zone.loading);

  const DUPLICATE_ERROR =
    "Les champs name, owner doivent former un ensemble unique.";

  //request fresh data on mount
  useEffect(() => {
    dispatch(listEntities(ZONE_ENTITY));
  }, [dispatch]);

  const getErrorDict = (errors) =>
    errors
      ? {
          ...errors,
          non_field_errors:
            errors.non_field_errors === DUPLICATE_ERROR
              ? "Chaque zone doit être unique."
              : errors.non_field_errors,
        }
      : {};

  const title_button = (
    <Tooltip title="Ajouter" arrow placement="top">
      <span>
        <Fab
          color="primary"
          size="small"
          onClick={createDialog.open}
          disabled={isLoading()}
          className={classes.titleButton}
        >
          {isLoading() ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <Plus fontSize="small" />
          )}
        </Fab>
      </span>
    </Tooltip>
  );

  return (
    <PageContainer
      title="Zones d'occurrence"
      titleAddOn={title_button}
      className={classes.page}
    >
      <Grid container spacing={2} className={classes.content}>
        <Grid item xs={12}>
          {/* Errors */}
          <ErrorBox
            className={classes.alert}
            errorDict={getErrorDict(errors)}
          />
          {zones.map((zone) => (
            <ErrorBox
              key={zone.id}
              className={classes.alert}
              errorDict={getErrorDict(zone.errors)}
            />
          ))}
          {/* List */}
          {zones.length === 0 &&
            (isLoading() ? (
              <SkeletonList size={Math.max(2, zones.length + 1)} />
            ) : (
              <Alert severity="warning" className={classes.alert}>
                Aucune zone d'occurrences. Cliquez sur + pour en ajouter.
              </Alert>
            ))}
          {zones.length > 0 && (
            <List dense>
              {zones
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((zone) => (
                  <ListItem key={zone.id} className={classes.item}>
                    <ListItemText primary={zone.name} />
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="edit"
                        onClick={() => editDialog.open(zone)}
                      >
                        <Pencil />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => deleteDialog.open(zone)}
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </List>
          )}
        </Grid>
      </Grid>
      {createDialog.display}
      {editDialog.display}
      {deleteDialog.display}
    </PageContainer>
  );
}
