import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  Tooltip,
  Hidden,
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ChevronLeft, ChevronRight, Calendar } from "mdi-material-ui";
import { StaticDatePicker } from "@material-ui/pickers";

import { useCurrentDate, useDebounce } from "hooks";
import { useDialog } from "hooks/ui";
import { updateDateParam } from "actions/ui";

const DEBOUNCE_DELAY_IN_MS = 500;

const useStyles = makeStyles((theme) => ({
  group: {
    display: "inline-flex",
    alignItems: "center",
  },
  label: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  dialog: {
    overflowX: "hidden",
  },
}));

const useDatePickerDialog = (selected_date, onPicked) => {
  const classes = useStyles();
  const { isOpen, open, close } = useDialog();
  const [date, setDate] = useState(moment(selected_date));

  useEffect(() => {
    if (selected_date) {
      setDate(moment(selected_date));
    }
  }, [selected_date]);

  const onClose = (confirmed) => {
    if (confirmed) {
      onPicked(date);
    }
    close();
  };

  const handleDateChange = (new_date) => {
    setDate(new_date);
  };

  const display = (
    <Dialog open={isOpen} onClose={() => onClose(false)}>
      <StaticDatePicker
        label="Aller au"
        autoOk
        value={date}
        onChange={handleDateChange}
        className={classes.calendar}
      />
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Annuler
        </Button>
        <Button onClick={() => onClose(true)} color="primary" autoFocus>
          Aller
        </Button>
      </DialogActions>
    </Dialog>
  );

  return { open, display };
};

export default function CalendarToolbox(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const current_date = useCurrentDate();
  const navigate = useDebounce(_navigate, DEBOUNCE_DELAY_IN_MS);
  const calendar_picker = useDatePickerDialog(current_date, (date) =>
    navigate(date)
  );

  //must not be an arrow function
  function _navigate(date) {
    dispatch(updateDateParam(date));
  }

  const handlePreviousButtonClick = () => {
    navigate(moment(current_date).subtract(1, "d"));
  };

  const handleNextButtonClick = () => {
    navigate(moment(current_date).add(1, "d"));
  };

  return (
    <Fragment>
      <Hidden xsDown>
        <ButtonGroup className={classes.group} {...props}>
          <Tooltip title="J-1" arrow>
            <Button size="small" onClick={handlePreviousButtonClick}>
              <ChevronLeft />
            </Button>
          </Tooltip>
          <Tooltip title="Choisir une date" arrow>
            <Button size="small" onClick={calendar_picker.open}>
              <Calendar />
            </Button>
          </Tooltip>
          <Tooltip title="J+1" arrow>
            <Button size="small" onClick={handleNextButtonClick}>
              <ChevronRight />
            </Button>
          </Tooltip>
        </ButtonGroup>
      </Hidden>
      <Hidden smUp>
        <Tooltip title="J-1" arrow>
          <IconButton size="small" onClick={handlePreviousButtonClick}>
            <ChevronLeft />
          </IconButton>
        </Tooltip>
        <Tooltip title="Choisir une date" arrow>
          <IconButton size="small" onClick={calendar_picker.open}>
            <Calendar />
          </IconButton>
        </Tooltip>
        <Tooltip title="J+1" arrow>
          <IconButton size="small" onClick={handleNextButtonClick} {...props}>
            <ChevronRight />
          </IconButton>
        </Tooltip>
      </Hidden>
      {calendar_picker.display}
    </Fragment>
  );
}
