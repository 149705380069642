import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Button } from "@material-ui/core";
import { Plus, Pencil } from "mdi-material-ui";

import { Link as RouterLink } from "react-router-dom";
import { ROUTES } from "routes";

const useStyles = makeStyles((theme) => ({
  empty: {
    padding: theme.spacing(3),
    color: theme.palette.text.disabled,
    display: "flex",
    flexDirection: "column",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  emptyItem: {
    marginBottom: theme.spacing(2),
  },
  bigEmptyItem: {
    marginBottom: theme.spacing(4),
  },
}));

export default function EmptyMessage({ count, ...props }) {
  const classes = useStyles();
  return (
    <div {...props}>
      <Container maxWidth="xs" className={classes.empty}>
        {count === 0 ? (
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            className={classes.bigEmptyItem}
          >
            Aucun log à afficher.
          </Typography>
        ) : (
          <Typography
            variant="body2"
            align="center"
            className={classes.emptyItem}
            gutterBottom
          >
            Sélectionner un log dans la liste pour le voir en détail.
          </Typography>
        )}
        <Button
          className={classes.emptyItem}
          variant="outlined"
          size="small"
          component={RouterLink}
          to={ROUTES.today.path}
          startIcon={<Plus />}
        >
          Créer un nouveau log
        </Button>
        <Button
          variant="outlined"
          size="small"
          component={RouterLink}
          to={ROUTES.licence.path}
          startIcon={<Pencil />}
        >
          Editer les zones d'occurrences
        </Button>
      </Container>
    </div>
  );
}
