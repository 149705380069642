import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Container, Grid, useMediaQuery, Tabs, Tab } from "@material-ui/core";

import { useCurrentStats } from "hooks/entities";
import { useQueryParams } from "hooks/queries";
import { readStats } from "actions/stats";

import SecondaryToolbar from "./SecondaryToolbar";
import Values from "./Values";
import TimeBarGraph from "./TimeBarGraph";
import PieGraph from "./PieGraph";
import DayWorkTimeBarGraph from "./DayWorkTimeBarGraph";
import ErrorBox from "components/utils/ErrorBox";
import { label } from "components/utils/HourUtils";
import { isStale } from "middlewares/staleness";

const useStyles = makeStyles((theme) => ({
  page: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
}));

export default function StatPage() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [tab, setTab] = React.useState(0);
  const { loading, updated_at, errors, hasErrors, data } = useCurrentStats();
  const query = useQueryParams();

  useEffect(() => {
    if (isStale(updated_at) && !loading && !hasErrors) {
      dispatch(readStats(query || {}));
    }
  }, [loading, updated_at, hasErrors, query, dispatch]);

  const format_duration = (value, index, values) => {
    const hour = Math.floor(value);
    const minutes = Math.round((value - hour) * 60);
    return `${hour}h${minutes === 0 ? "" : ("00" + minutes).slice(-2)}`;
  };

  const showGraph = (data, min = 1) => {
    try {
      if (Array.isArray(data.datasets)) {
        return data.datasets[0].data.length > min;
      } else {
        return data.data.length > min;
      }
    } catch (e) {
      return data === undefined;
    }
  };

  const total = (
    <Values title="Totaux" log={data ? data.total : undefined} divider />
  );

  const avg = (
    <Values title="Moyennes mensuelles" log={data ? data.average : undefined} />
  );

  const hour_month_bargraph = (
    <TimeBarGraph
      title="Heures par mois"
      data={data ? data.hour_month_bargraph : undefined}
      unit="month"
      format="MMM YYYY"
      formatLabel={label}
      formatValue={format_duration}
      divider
    />
  );

  const hour_year_bargraph = (
    <TimeBarGraph
      title="Heures par an"
      data={data ? data.hour_year_bargraph : undefined}
      unit="year"
      format="YYYY"
      formatLabel={label}
      formatValue={format_duration}
    />
  );

  const hour_piegraph = (
    <PieGraph
      title="Répartition des heures"
      formatLabel={label}
      data={data ? data.hour_piegraph : undefined}
      divider
    />
  );

  const occ_month_bargraph = (
    <TimeBarGraph
      title="Occurrences par mois"
      data={data ? data.occ_month_bargraph : undefined}
      unit="month"
      format="MMM YYYY"
      divider
    />
  );

  const occ_year_bargraph = (
    <TimeBarGraph
      title="Occurrences par an"
      data={data ? data.occ_year_bargraph : undefined}
      unit="year"
      format="YYYY"
    />
  );

  const zone_piegraph = (
    <PieGraph
      title="Répartition des zones"
      data={data ? data.zone_piegraph : undefined}
    />
  );

  const daywork_bargraph = (
    <DayWorkTimeBarGraph
      title="Temps de travail par jour travaillé"
      data={data ? data.time_by_day_bargraph : undefined}
    />
  );

  return (
    <div>
      <SecondaryToolbar query={query} />
      {mdUp ? (
        <Container disableGutters maxWidth="lg">
          <Grid
            spacing={3}
            justify="center"
            alignItems="stretch"
            container
            className={classes.page}
          >
            {hasErrors && (
              <Grid container item xs={12} spacing={1} justify="center">
                <Grid item xs={12}>
                  <ErrorBox errorDict={errors} />
                </Grid>
              </Grid>
            )}

            <Grid container item xs={12} spacing={1} justify="center">
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={6}>
                  {total}
                </Grid>
                <Grid item xs={6}>
                  {avg}
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={1} justify="center">
              <Grid item xs={8}>
                {hour_month_bargraph}
              </Grid>

              <Grid container item xs={4} spacing={1}>
                {showGraph(data && data.hour_year_bargraph) && (
                  <Grid item xs={12}>
                    {hour_year_bargraph}
                  </Grid>
                )}
                <Grid item xs={12}>
                  {hour_piegraph}
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={1} justify="center">
              <Grid item xs={8}>
                {occ_month_bargraph}
              </Grid>
              <Grid container item xs={4} spacing={1} justify="center">
                {showGraph(data && data.occ_year_bargraph) && (
                  <Grid item xs={12}>
                    {occ_year_bargraph}
                  </Grid>
                )}
                <Grid item xs={12}>
                  {zone_piegraph}
                </Grid>
              </Grid>
            </Grid>

            {showGraph(data && data.time_by_day_bargraph, 0) && (
              <Grid container item xs={12} spacing={1} justify="center">
                <Grid item xs={12}>
                  {daywork_bargraph}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Container>
      ) : (
        <div>
          <Tabs
            className={classes.tabs}
            variant="fullWidth"
            value={tab}
            onChange={(e, newValue) => setTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Chiffres" />
            <Tab label="Types" />
            <Tab label="Occurrences" wrapped />
            <Tab label="Temps de travail" wrapped />
          </Tabs>
          <Container disableGutters maxWidth="sm">
            <ErrorBox errorDict={errors} />
            {tab === 0 && (
              <div>
                {total}
                {avg}
              </div>
            )}
            {tab === 1 && (
              <div>
                {hour_piegraph}
                {showGraph(data && data.hour_month_bargraph) &&
                  hour_month_bargraph}
                {showGraph(data && data.hour_year_bargraph) &&
                  hour_year_bargraph}
              </div>
            )}
            {tab === 2 && (
              <div>
                {zone_piegraph}
                {showGraph(data && data.occ_month_bargraph) &&
                  occ_month_bargraph}
                {showGraph(data && data.occ_year_bargraph) && occ_year_bargraph}
              </div>
            )}
            {tab === 3 && daywork_bargraph}
          </Container>
        </div>
      )}
    </div>
  );
}
