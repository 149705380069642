import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, useLocation } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "mdi-material-ui";

import Footer from "./Footer";
import {
  Drawer,
  IconButton,
  Divider,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@material-ui/core";
import getMenu from "./menu";

import { useLastRenewal } from "hooks/entities";

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  spacer: {
    flexGrow: 1,
  },
  footer: {
    color: theme.palette.text.secondary,
  },
}));

function DrawerMenuItem({ route, icon, search, selected, onClick }) {
  return (
    <MenuItem
      key={route.path}
      component={Link}
      to={{ pathname: route.path, search }}
      selected={selected}
      onClick={onClick}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={route.title} />
    </MenuItem>
  );
}

MyDrawer.propTypes = {
  open: PropTypes.bool,
  stay: PropTypes.bool,
  onClose: PropTypes.func,
};

export default function MyDrawer({ open, stay, onClose, ...props }) {
  const classes = useStyles();
  const theme = useTheme();
  const current_location = useLocation();
  const last_renewal = useLastRenewal();

  const handleLinkClick = () => {
    if (!stay) {
      onClose();
    }
  };

  return (
    <Drawer open={open} onClose={onClose} {...props}>
      <div className={classes.drawerHeader}>
        <IconButton onClick={onClose}>
          {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </div>
      <Divider />
      <MenuList>
        {getMenu(last_renewal).map(({ route, ...destination }, index) => (
          <DrawerMenuItem
            key={index}
            route={route}
            {...destination}
            selected={current_location.pathname === route.path}
            onClick={handleLinkClick}
          />
        ))}
      </MenuList>
      <Divider />
      <div className={classes.spacer} />
      <Box pt={4} pb={2} className={classes.footer}>
        <Footer />
      </Box>
    </Drawer>
  );
}
