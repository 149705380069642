import produce from "immer";
import { ZONE_ENTITY, LOG_ENTITY } from "constants/entities";
import * as actions from "constants/actions/entities";
import { shouldResetData, hasChangedEntities, getStatus } from "../helpers";
import { isRequest, isAction, isSuccess, isEntity } from "actions/helpers";

const LOG_INITIAL_STATE = {
  entities: {},
};

const logReducer = (state = LOG_INITIAL_STATE, action) => {
  const { meta, payload } = action;
  if (shouldResetData(action)) {
    return LOG_INITIAL_STATE;
  }

  //when editing zones, logs should be considered stale
  //because zonecounts are being updated on server side
  if (hasChangedEntities(action, [ZONE_ENTITY])) {
    return LOG_INITIAL_STATE;
  }

  //check that the action is relevant to this reducer
  //check that if the action is a request, the stale indicator must be true
  if (isEntity(action, LOG_ENTITY) && (!isRequest(action) || meta.stale)) {
    if (isAction(action, actions.LIST_ACTION)) {
      //errors for list are handled in "queries" reducer
      return {
        ...state,
        loading: isRequest(action),
        entities: {
          ...state.entities,
          ...(isSuccess(action) ? payload.results : {}),
        },
      };
    } else if (isSuccess(action) && isAction(action, actions.DESTROY_ACTION)) {
      //remove deleted entity from state
      return {
        ...state,
        entities: Object.fromEntries(
          Object.entries(state.entities).filter(
            ([date, log]) => date !== meta.pk
          )
        ),
      };
    } else {
      //replace updated entity in dict
      return {
        ...state,
        entities: {
          ...state.entities,
          [meta.pk]: {
            ...(state.entities[meta.pk] || {}),
            ...getStatus(action, true),
            ...(payload || {}),
          },
        },
      };
    }
  }
  return state;
};

/* local reducer for input page 'usereducer' */
const stepValue = (value, step) => Math.max(value + step, 0);
const HOUR_STEP = 15;
const ZONE_STEP = 1;

const INITIAL_LOCAL_LOG = {
  note: " ",
  marked: false,
  updated_at: undefined,
  hours: [],
  occurrences: [],
};

export const localInputReducer = produce((draft, action) => {
  const { type, target, direction, value, payload } = action;
  if (type === "reset") {
    if (payload) return payload;
    else {
      draft.note = " ";
      draft.marked = false;
      draft.updated_at = undefined;
      for (let h in draft.hours) {
        draft.hours[h].count = 0;
      }
      for (let z in draft.occurrences) {
        draft.occurrences[z].count = 0;
      }
    }
  }
  if (type === "hours") {
    const target_index = draft.hours.findIndex(
      ({ hour_type }) => hour_type === target
    );
    const new_value = stepValue(
      draft.hours[target_index].count,
      direction * HOUR_STEP
    );
    draft.hours[target_index].count = new_value;
  }
  if (type === "occs") {
    const target_index = draft.occurrences.findIndex(
      ({ zone_id }) => zone_id === target
    );
    const new_value = stepValue(
      draft.occurrences[target_index].count,
      direction * ZONE_STEP
    );
    draft.occurrences[target_index].count = new_value;
  }
  if (type === "note") {
    draft.note = value || " ";
  }
  if (type === "mark") {
    draft.marked = !draft.marked;
  }
}, INITIAL_LOCAL_LOG);

export default logReducer;
