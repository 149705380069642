import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Bar } from "react-chartjs-2";

import PageContainer from "components/utils/PageContainer";
import LoadingGraph from "./LoadingGraph";
import { getPalette } from "components/Theming";

TimeBarGraph.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  unit: PropTypes.string,
  format: PropTypes.string,
  formatLabel: PropTypes.func,
  formatValue: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "initial",
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
  },
  skeleton: {
    paddingBottom: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(1),
    minHeight: "200px",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      display: "flex",
      alignItems: "stretch",
    },
  },
}));

export default function TimeBarGraph({
  data,
  title,
  unit,
  format,
  formatLabel,
  formatValue = (a) => a,
  ...props
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [formatedData, setFormatedData] = useState({});

  useEffect(() => {
    if (data) {
      setFormatedData({
        datasets: data.datasets.map((d, i) => {
          const color = getPalette(theme, d.category, i);
          return {
            data: d.data,
            label: formatLabel ? formatLabel(d.category) : d.category,
            borderWidth: 1,
            color,
            backgroundColor: color.light,
            borderColor: color.dark,
            hoverBackgroundColor: color.main,
          };
        }),
      });
    } else {
      setFormatedData({});
    }
  }, [data, theme, formatLabel]);

  const tooltip_callback = {
    label: (tooltipItem, data) =>
      `${data.datasets[tooltipItem.datasetIndex].label} : ${formatValue(
        tooltipItem.value
      )}`,
    footer: (tooltipItems, data) =>
      `Total : ${formatValue(
        tooltipItems.reduce((result, item) => result + item.yLabel, 0)
      )}`,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: false,
    tooltips: {
      mode: "index",
      callbacks: tooltip_callback,
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {},
          gridLines: { display: false },
          type: "time",
          offset: true,
          time: {
            tooltipFormat: format,
            unit: unit,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            callback: formatValue,
          },
          scaleLabel: {
            display: true,
            labelString: "Heures travaillées",
          },
        },
      ],
    },
  };

  return (
    <PageContainer title={title} className={classes.container} {...props}>
      <div className={classes.content}>
        {!data ? (
          <LoadingGraph className={classes.skeleton} />
        ) : (
          <Bar data={formatedData} options={options} />
        )}
      </div>
    </PageContainer>
  );
}
