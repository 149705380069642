import { LOGIN_ACTION, LOGOUT_ACTION } from "constants/actions/auth";
import { READ_ACTION } from "constants/actions/entities";
import { USER_ENTITY } from "constants/entities";
import { isRequest, isSuccess, isAction, isEntity } from "actions/helpers";
import { now } from "middlewares/staleness";

/**
 * `updated_at` attribute is used by staleness middleware to only forward
 * request actions to api if the data is stale
 */

const LOGGED_OUT_TOKEN = { token: "" };

const isLogout = (action) =>
  !isRequest(action) && isAction(action, LOGOUT_ACTION);
//if logout success or fail, apply logout anyway

export const token = (state = LOGGED_OUT_TOKEN, action) => {
  const { payload } = action;
  if (isLogout(action)) {
    return { ...state, ...LOGGED_OUT_TOKEN };
  }
  if (isSuccess(action) && isAction(action, LOGIN_ACTION)) {
    return { ...state, token: payload.auth_token };
  }
  return state;
};

export const me = (state = { email: "" }, action) => {
  const { meta, payload } = action;
  if (isLogout(action)) {
    return { email: "" };
  }
  if (isSuccess(action) && isAction(action, LOGIN_ACTION)) {
    return { ...state, email: meta.body.email, updated_at: now() };
  }
  if (
    isSuccess(action) &&
    isAction(action, READ_ACTION) &&
    isEntity(action, USER_ENTITY)
  ) {
    return {
      ...state,
      ...payload,
      updated_at: now(),
    };
  }
  return state;
};
