import * as actions from "constants/actions/entities";
import * as methods from "constants/methods";
import {
  isAction,
  isRequest,
  isAuth,
  isDownload,
  isReadAction,
  isStat,
  isReset,
  isImport,
} from "actions/helpers";

export const getMethod = (action) => {
  if (isRequest(action) && isReset(action)) return methods.DELETE_METHOD;
  if (isRequest(action) && (isAuth(action) || isImport(action)))
    return methods.POST_METHOD;
  if (
    isRequest(action) &&
    (isReadAction(action) || isDownload(action) || isStat(action))
  ) {
    return methods.GET_METHOD;
  }
  if (isRequest(action) && isAction(action, actions.CREATE_ACTION)) {
    return methods.POST_METHOD;
  }
  if (isRequest(action) && isAction(action, actions.UPDATE_ACTION)) {
    return methods.PUT_METHOD;
  }
  if (isRequest(action) && isAction(action, actions.DESTROY_ACTION)) {
    return methods.DELETE_METHOD;
  }
};
