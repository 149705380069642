import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Typography } from "@material-ui/core";

import PageContainer from "components/utils/PageContainer";

const useStyles = makeStyles((theme) => ({
  badge: {
    maxWidth: "280px",
    height: "auto",
  },
}));

export default function AndroidApp() {
  const classes = useStyles();

  return (
    <PageContainer title="Application Android">
      <Typography align="justify" variant="body2">
        Aussi sous forme d'application pour Android
      </Typography>
      <Link
        href="https://play.google.com/store/apps/details?id=com.icnalog.icnalog&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        align="center"
      >
        <img
          alt="Disponible sur Google Play"
          src="https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png"
          className={classes.badge}
        />
      </Link>
    </PageContainer>
  );
}
