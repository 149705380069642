import { useState, useCallback } from "react";

export function useForm(initialValues = {}, onSubmit) {
  const [values, setValues] = useState(initialValues);
  const [touched, setTouched] = useState({});

  const handleUserInput = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    if (!touched[name]) {
      setTouched({ ...touched, [name]: true });
    }
  };

  const handleSubmit = (e) => {
    onSubmit(values);
    setTouched({});
    e.preventDefault();
  };

  const reset = useCallback((values) => {
    setValues(values);
    setTouched({});
  }, []);

  return { values, touched, handleUserInput, handleSubmit, reset };
}
