import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import { Check, Replay } from "mdi-material-ui";

import ResponsiveButton from "components/utils/ResponsiveButton";
import StickySecondaryToolbar from "components/utils/StickySecondaryToolbar";
import VisibilityToolbox from "components/utils/VisibilityToolbox";
import CalendarToolbox from "./CalendarToolbox";

SecondaryToolbar.propTypes = {
  marked: PropTypes.bool,
  onResetClick: PropTypes.func,
  onMarkClick: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    boxSizing: "border-box",
  },
  inner: {
    maxWidth: `calc(${theme.breakpoints.values.lg}px - ${theme.spacing(4)}px)`,
    margin: "auto",
    boxSizing: "border-box",
    display: "inherit",
    flex: "1",
  },
  toolbarItem: {
    marginRight: theme.spacing(1),
  },
  divider: {
    marginRight: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
}));

export default function SecondaryToolbar({
  marked,
  onResetClick,
  onMarkClick,
}) {
  const classes = useStyles();

  return (
    <StickySecondaryToolbar className={classes.container}>
      <div className={classes.inner}>
        <ResponsiveButton
          edge="start"
          size="small"
          text={marked ? "Marqué" : "Marquer"}
          startIcon={<Check />}
          active={marked}
          onClick={onMarkClick}
          className={classes.toolbarItem}
        />
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <VisibilityToolbox />
        <div className={classes.spacer} />
        <ResponsiveButton
          size="small"
          text="Réinitialiser"
          startIcon={<Replay />}
          onClick={onResetClick}
          className={classes.toolbarItem}
        />
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <CalendarToolbox edge="end" />
      </div>
    </StickySecondaryToolbar>
  );
}
