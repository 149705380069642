import React from "react";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import getMenu from "./menu";

import { isInputPage, ROUTES } from "routes";
import { useLastRenewal } from "hooks/entities";

function usePathName() {
  // force navbar to consider all input pages as today (so that tab is active)
  const { pathname } = useLocation();
  if (isInputPage(pathname)) {
    return ROUTES.today.path;
  } else {
    return pathname;
  }
}

export default function MyBottomNavigation(props) {
  const pathname = usePathName();
  const last_renewal = useLastRenewal();

  return (
    <BottomNavigation showLabels value={pathname} {...props}>
      {getMenu(last_renewal).map(({ route, icon, search }, index) => (
        <BottomNavigationAction
          key={index}
          component={Link}
          to={{ pathname: route.path, search }}
          label={route.title}
          value={route.path}
          icon={icon}
        />
      ))}
    </BottomNavigation>
  );
}
