import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, MenuList, MenuItem } from "@material-ui/core";

import * as filters from "constants/ui";

const CHOICES = [
  {
    label: "Tous",
    value: filters.MARK_FILTER_ALL,
  },
  {
    label: "Marqués",
    value: filters.MARK_FILTER_MARKED,
  },
  {
    label: "Non marqués",
    value: filters.MARK_FILTER_UNMARKED,
  },
];

MarkFilterDialog.propTypes = {
  query: PropTypes.shape({
    mark: PropTypes.string,
  }),
  open: PropTypes.bool,
  onMarkSelect: PropTypes.func,
  onClose: PropTypes.func,
};

export default function MarkFilterDialog({
  query,
  open,
  onMarkSelect,
  onClose,
}) {
  const handleMarkedPick = (value) => {
    onClose();
    onMarkSelect(value);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Filtrer par marquage</DialogTitle>
      <MenuList>
        {CHOICES.map(({ label, value }) => (
          <MenuItem key={value} onClick={() => handleMarkedPick(value)}>
            {label}
          </MenuItem>
        ))}
      </MenuList>
    </Dialog>
  );
}
