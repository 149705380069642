import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { Alert } from "@material-ui/lab";

import PageContainer from "components/utils/PageContainer";
import { DATE_FORMAT_SHORT } from "constants/ui";
import { ROUTES } from "routes";

const useStyles = makeStyles((theme) => ({
  page: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  pageTitle: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      paddingBottom: 0,
    },
  },
  title: {
    margin: 0,
    marginTop: theme.spacing(3),
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "no-wrap",
    justifyContent: "space-between",
  },
  answer: {},
  alert: {},
}));

function Version({ version_number, release_date, ...props }) {
  const classes = useStyles();
  return (
    <Container className={classes.title} maxWidth="xs" {...props}>
      <Typography variant="button" color="secondary" component="span">
        {`Version ${version_number}`}
      </Typography>
      <Typography
        component="span"
        align="right"
        variant="body2"
        color="textSecondary"
      >
        {`(${moment(release_date).format(DATE_FORMAT_SHORT)})`}
      </Typography>
    </Container>
  );
}

function Notes({ children, ...props }) {
  const classes = useStyles();
  return (
    <Typography
      variant="body1"
      align="justify"
      className={classes.answer}
      {...props}
    >
      {children}
    </Typography>
  );
}

function Warning({ children, ...props }) {
  const classes = useStyles();
  return (
    <Alert className={classes.alert} {...props}>
      {children}
    </Alert>
  );
}

export default function ChangelogPage(props) {
  const classes = useStyles();

  return (
    <Container maxWidth="md" disableGutters>
      <Container className={classes.page}>
        <PageContainer>
          <Typography
            component="h2"
            variant="h6"
            color="primary"
            gutterBottom
            className={classes.pageTitle}
          >
            Notes de version (Android)
          </Typography>
          <Version version_number="6.1" release_date="2020-01-10" />
          <Notes>
            <Warning severity="info">
              Si vous avez essayé sans succès de créer un compte Icnalog Web
              courant décembre, le problème a été corrigé !<br />
              Vous pouvez réessayer ici :{" "}
              <Link>https://www.icnalog.com/register</Link>.
            </Warning>
            <ul>
              <li>
                Nouvelle fonctionnalité : personnalisez les valeurs de vos
                objectifs de licence depuis la page "Butées"
              </li>
              <li>
                Nouvelle fonctionnalité : sur les graphiques mensuels, les
                valeurs s'affichent en touchant une colonne.
              </li>
              <li>
                Nouvelle fonctionnalité : forcer la synchronisation depuis la
                page "Mon compte" en supprimant et remplaçant toutes les données
                locales par celles sur le serveur.
              </li>
              <li>
                Nouvelle fonctionnalité : l'administrateur peut maintenant
                envoyer des "Annonces" à tous les utilisateurs de l'application
                (sous forme de notification).
              </li>
              <li>
                Correction : les butées ISP et Simu sont calculées correctement
                (50% de l'objectif 200h et non 50% des heures reportées)
              </li>
              <li>
                Correction : les données sont maintenant supprimées
                correctement, même sans être connecté à Icnalog Web
              </li>
              <li>
                Compatibilité avec les changements de certificats SSL d'Icnalog
                Web
              </li>
              <li>Autres corrections diverses</li>
            </ul>
          </Notes>
          <Version version_number="6.0.6" release_date="2020-10-15" />
          <Notes>
            <ul>
              <li>
                Possibilité de synchroniser les données avec{" "}
                <Link component={RouterLink} to={ROUTES.home.path}>
                  Icnalog Web
                </Link>{" "}
                et ajout d'une page Mon compte.
                <Warning severity="info">
                  Rendez-vous sur <Link>https://www.icnalog.com/register</Link>{" "}
                  pour créer votre compte.
                </Warning>
              </li>
              <li>
                Mise à jour de la mise en page avec un menu en bas d'écran au
                lieu d'un volet de navigation.
              </li>
              <li>
                Ajouter d'un tutoriel à la première installation ou à la mise à
                jour de l'application.
              </li>
              <li>
                Création d'une page Licence pour renseigner les élements de la
                licence et consulter le suivi des butées.
              </li>
              <li>
                Mise à jour de la page Relevés (pour la cohérence avec Icnalog
                Web)
              </li>
              <li>
                Ajout de diagrammes supplémentaire sur la page Statistiques
              </li>
              <li>
                Ajout d'un écran de chargement au lancement de l'application
              </li>
              <li>
                Suppresion de la sauvegarde intégrée (à la place, utilisez la
                synchronisation ou l'export pour sauvegarder manuellement)
              </li>
              <li>
                Suppression temporaire de la fonction Rappels (en attendant la
                mise à jour adaptée d'Icnalog Web)
              </li>
              <li>Corrections diverses</li>
            </ul>
          </Notes>
          <Version version_number="5.2.2" release_date="2019-06-07" />
          <Notes>
            <ul>
              <li>Affichage des durées > 1000h sous la forme 1kh</li>
              <li>
                Export : option d'inclure le total depuis le renouvellement de
                MU et texte explicatif
              </li>
              <li>
                Page Relevé : navigation rapide avec un dérouleur (sur la droite
                de l'écran)
              </li>
              <li>Corrections de bugs</li>
            </ul>
          </Notes>
          <Version version_number="5.1.4" release_date="2019-04-17" />
          <Notes>
            <Warning severity="warning">
              La sauvegarde automatique a pu être désactivée pendant la mise à
              jour.
              <br />
              Rendez-vous sur la page Paramètres pour la réactiver.
            </Warning>
            <ul>
              <li>
                Refonte de la fonction Sauvegarde
                <ul>
                  <li>Périodicité au choix</li>
                  <li>Affichage de la taille du fichier</li>
                  <li>Ecran de paramétrage revu</li>
                </ul>
              </li>
              <li>
                Rappels sous forme de notification quand les données n'ont pas
                été exportées depuis plus de 2 mois (à activer depuis la page
                Paramètres)
              </li>
              <li>
                Page Aujourd'hui : cliquer sur le titre "Aujourd'hui" pour
                changer de jour
              </li>
              <li>Amélioration des rapports de plantages</li>
              <li>
                Corrections diverses
                <ul>
                  <li>
                    Au clic, le curseur se place à la fin du champ texte (et non
                    au début)
                  </li>
                  <li>
                    Meilleure gestion de la navigation "arrière" et affichage de
                    la flèche de retour arrière dans la barre de menu quand
                    pertinent
                  </li>
                  <li>...</li>
                </ul>
              </li>
            </ul>
          </Notes>
          <Version version_number="5.0.3" release_date="2019-01-12" />
          <Notes>
            <ul>
              <li>Nouveau volet de navigation</li>
              <li>
                {" "}
                Page relevé :
                <ul>
                  <li>Actions sur plusieurs lignes à la fois</li>
                  <li>
                    Marquage des lignes (par ex, pour marquer les lignes
                    envoyées à l'administration)
                  </li>
                  <li>Nouvelle colonne 'Total'</li>
                </ul>
              </li>
              <li>Export : possibilité de marque les jours exportés</li>
              <li>
                Retour à l'ancienne version pour la modification des jours
                passés
              </li>
              <li>Refonte de la page 'Aide'</li>
              <li>Affichage des notes de version après une mise à jour</li>
              <li>Corrections diverses</li>
            </ul>
          </Notes>
          <Version version_number="4.1" release_date="2018-07-01" />
          <Notes>
            <ul>
              <li>
                Corrections de bugs
                <ul>
                  <li>
                    Planification de la sauvegarde : environ hebdomadaire (avec
                    une fenêtre d'imprécision qui dépend de la batterie, de la
                    connexion de données...)
                  </li>
                  <li>Divers</li>
                </ul>
              </li>
            </ul>
          </Notes>
          <Version version_number="4.0.3" release_date="2018-05-21" />
          <Notes>
            <ul>
              <li>
                Page relevé :
                <ul>
                  <li>Nouvelle interface pour saisir rapidement une journée</li>
                  <li>Saisie du report des valeurs antérieures</li>
                </ul>
              </li>
              <li>
                Statistiques : les décomptes depuis la dernière MU commencent au
                1er du mois de la MU
              </li>
              <li>
                Notification quand une nouvelle version est disponible sur le
                Playstore
              </li>
              <li>Corrections de bugs diverses</li>
            </ul>
          </Notes>
          <Version version_number="3.2.3" release_date="2018-01-18" />
          <Notes>
            <ul>
              <li>Saisie des heures de double (optionnelle, voir Réglages)</li>
              <li>
                Export :
                <ul>
                  <li>
                    les fichiers sont nommés avec le nom d'utilisateur et la
                    date et heure de l'export
                  </li>
                  <li>
                    les fichiers exportés en CSV utilisent ; comme séparateur
                  </li>
                </ul>
              </li>
              <li>Corrections de bugs divers</li>
            </ul>
          </Notes>
          <Version version_number="3.1.1" release_date="2017-11-22" />
          <Notes>
            <ul>
              <li>Statistiques : nouvelle colonne "Total"</li>
              <li>Aujourd'hui : correction de bug</li>
              <li>
                Sauvegarde : il est maintenant possible de sélectionner le
                dossier racine
              </li>
            </ul>
          </Notes>
        </PageContainer>
      </Container>
    </Container>
  );
}
