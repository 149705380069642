import { push } from "connected-react-router";
import moment from "moment";

import { idFromQuery } from "selectors/queries";
import { ROUTES } from "routes";
import { DATE_FORMAT_DATA } from "constants/ui";
import * as actions from "constants/actions/ui";
import { PREF_ENTITY } from "constants/entities";
import { readEntity, updateEntity } from "./entities";

export const displayMessage = (message) => ({
  type: actions.DISPLAY_MESSAGE_ACTION,
  message,
});

export const clearMessage = () => ({
  type: actions.CLEAR_MESSAGE_ACTION,
});

export const clearStatus = () => ({
  type: actions.CLEAR_STATUS_ACTION,
});

export const readVisibilityPrefs = () => readEntity(PREF_ENTITY);

export const updateVisibilityPrefs = (sim, dual) =>
  updateEntity(PREF_ENTITY, "", { display_sim: sim, display_dual: dual });

export const setDrawerOpen = (open) => ({
  type: actions.DRAWER_STATE_ACTION,
  open,
});

//update router query
export const updateQueryParams = (path, { start, end, ...query }) =>
  push({
    pathName: path,
    search: idFromQuery({
      start: start ? moment(start).format(DATE_FORMAT_DATA) : undefined,
      end: end ? moment(end).format(DATE_FORMAT_DATA) : undefined,
      ...query,
    }),
  });

//update date params
export const updateDateParam = (date) =>
  push(
    ROUTES.input.path.replace(":date", moment(date).format(DATE_FORMAT_DATA))
  );
