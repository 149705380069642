import { createSelector } from "reselect";
import { createMatchSelector, getLocation } from "connected-react-router";
import { ROUTES } from "routes";
import moment from "moment";

export const getLocationParams = (state, route) =>
  createMatchSelector(route)(state) || {};

export const getCurrentDate = createSelector(
  (state) => getLocationParams(state, ROUTES.input),
  (params) => {
    try {
      return moment(params.params.date);
    } catch (TypeError) {
      return moment();
    }
  }
);

export function getQueryParams(state) {
  return Object.fromEntries(new URLSearchParams(getLocation(state).search));
}
