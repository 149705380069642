import React, { Fragment } from "react";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Container,
  Typography,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Menu } from "mdi-material-ui";
import AppIcon from "./utils/AppIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    padding: theme.spacing(3),
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: theme.spacing(4),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export function MaintenancePage() {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <AppIcon />
        <Typography variant="h4">Maintenance</Typography>
        <Typography variant="subtitle1">
          Icnalog est actuellement hors ligne pour maintenance. Réessayez plus
          tard.
        </Typography>
      </div>
    </Container>
  );
}

export default function Maintenance() {
  const classes = useStyles();
  return (
    <Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              className={classes.menuButton}
            >
              <Menu />
            </IconButton>
            <Typography
              variant="h5"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Icnalog Web
            </Typography>
          </Toolbar>
        </AppBar>

        <main className={classes.content}>
          <Maintenance />
        </main>
      </div>
    </Fragment>
  );
}
