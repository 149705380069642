import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Bar } from "react-chartjs-2";

import PageContainer from "components/utils/PageContainer";
import { getPalette } from "components/Theming";
import LoadingGraph from "./LoadingGraph";

DayWorkTimeBarGraph.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "initial",
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
  },
  skeleton: {
    paddingBottom: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(1),
    minHeight: "300px",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      display: "flex",
      alignItems: "stretch",
    },
  },
}));

export default function DayWorkTimeBarGraph({ data, title, ...props }) {
  const classes = useStyles();
  const theme = useTheme();
  const [formatedData, setFormatedData] = React.useState({});

  useEffect(() => {
    if (data) {
      const color = getPalette(theme, "dynamic", 4);
      const result = {
        labels: data.labels,
        datasets: [
          {
            data: data.data,
            borderWidth: 1,
            backgroundColor: color.light,
            borderColor: color.dark,
            hoverBackgroundColor: color.main,
          },
        ],
      };
      setFormatedData(result);
    } else {
      setFormatedData({});
    }
  }, [data, theme]);

  const format_duration = (value, index, values) => {
    const hour = Math.floor(value);
    const minutes = Math.round((value - hour) * 60);
    return `${hour}h${minutes === 0 ? "" : ("00" + minutes).slice(-2)}`;
  };

  const format_daycount = (value, index, values) => `${value} j`;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: false,
    tooltips: {
      mode: "index",
      callbacks: {
        title: (tooltipItem) =>
          `${format_duration(tooltipItem[0].index)} par jour`,
        label: (tooltipItem, data) => format_daycount(tooltipItem.value),
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            callback: format_duration,
          },
          gridLines: { display: false },
          scaleLabel: {
            display: true,
            labelString: "Temps de travail quotidien",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: format_daycount,
            stepSize: 1,
          },
          scaleLabel: {
            display: true,
            labelString: "Nb de jours",
          },
        },
      ],
    },
  };

  return (
    <PageContainer title={title} className={classes.container} {...props}>
      <div className={classes.content}>
        {!data ? (
          <LoadingGraph className={classes.skeleton} />
        ) : (
          <Bar data={formatedData} options={options} />
        )}
      </div>
    </PageContainer>
  );
}
