import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Toolbar,
  IconButton,
  Hidden,
  Link,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { Menu } from "mdi-material-ui";
import UserMenu from "../menus/UserMenu";
import AnonymousMenu from "../menus/AnonymousMenu";

import { useAuthenticated } from "hooks/user";
import { ROUTES, getRouteForPath, getDateFromPath } from "routes";
import { DATE_FORMAT_LONG, DATE_FORMAT_SHORT } from "constants/ui";
import { DEBUG } from "constants/config";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  title: {
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(6),
    },
  },
  spacer: {
    flexGrow: 1,
  },
  end_menu: {},
}));

MyToolbar.propTypes = {
  drawerOpen: PropTypes.bool,
  onDrawerOpenClick: PropTypes.func,
  noButton: PropTypes.bool,
};

function useTitle() {
  const location = useLocation();
  const mdUp = useMediaQuery(useTheme().breakpoints.up("md"));
  const [title, setTitle] = React.useState("404 Page inconnue");
  useEffect(() => {
    const date = getDateFromPath(location.pathname);
    if (date) {
      if (moment(date).isSame(new Date(), "day")) {
        setTitle("Aujourd'hui");
      } else {
        setTitle(date.format(mdUp ? DATE_FORMAT_LONG : DATE_FORMAT_SHORT));
      }
    } else {
      const route = getRouteForPath(location.pathname);
      if (route) {
        setTitle(route.title);
      } else {
        setTitle("404 Page inconnue");
      }
    }
  }, [location.pathname, mdUp]);
  return title;
}

export default function MyToolbar({ noButton, drawerOpen, onDrawerOpenClick }) {
  const classes = useStyles();
  const title = useTitle();
  const is_authenticated = useAuthenticated();

  const app_display = (
    <Link
      component={RouterLink}
      to={ROUTES.home.path}
      className={classes.title}
      variant="h5"
      color="inherit"
      underline="none"
      noWrap
    >
      {`Icnalog ${DEBUG ? "Debug" : "Web"}`}
    </Link>
  );

  const route_title = (
    <Typography
      variant="h6"
      color="inherit"
      className={classes.subtitle}
      noWrap
    >
      {title}
    </Typography>
  );

  return (
    <Toolbar>
      {is_authenticated && !noButton && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={onDrawerOpenClick}
          edge="start"
          className={clsx(classes.menuButton, drawerOpen && classes.hide)}
        >
          <Menu />
        </IconButton>
      )}
      {is_authenticated ? <Hidden smDown>{app_display}</Hidden> : app_display}
      {is_authenticated && route_title}
      <div className={classes.spacer}></div>
      <div className={classes.end_menu}>
        {is_authenticated ? <UserMenu /> : <AnonymousMenu />}
      </div>
    </Toolbar>
  );
}
