import React from "react";
import { useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

import { useDrawerOpen } from "hooks/ui";
import { useAuthenticated } from "hooks/user";
import { usePageTracker } from "hooks";

import { setDrawerOpen } from "actions/ui";

import SlideUnderLayout from "./SlideUnderLayout";
import NoSidebarLayout from "./NoSidebarLayout";
import BottomNavLayout from "./BottomNavLayout";

export default function Layout(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const is_authenticated = useAuthenticated();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const drawer_open = useDrawerOpen();
  usePageTracker();

  const handleDrawerToggle = () => {
    dispatch(setDrawerOpen(!drawer_open));
  };

  return is_authenticated ? (
    mdUp ? (
      <SlideUnderLayout
        {...props}
        open={!!drawer_open}
        onDrawerToggle={handleDrawerToggle}
      />
    ) : (
      <BottomNavLayout {...props} />
    )
  ) : (
    <NoSidebarLayout {...props} />
  );
}
