import React from "react";
import { useDispatch } from "react-redux";
import { Button, TextField, Grid, Link } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import ErrorBox from "components/utils/ErrorBox";
import PasswordTextField from "components/utils/PasswordTextField";
import AuthPage from "./AuthPage";
import { ROUTES } from "routes";
import { login } from "actions/auth";
import { useAuthState } from "hooks";
import { useForm } from "hooks/forms";
import { LOGIN_STATE } from "constants/status";

const useStyles = makeStyles((theme) => ({
  infoBox: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  errorAlert: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, errors } = useAuthState(LOGIN_STATE);
  const { values, touched, handleUserInput, handleSubmit } = useForm(
    {
      email: "",
      password: "",
    },
    ({ email, password }) => dispatch(login(values.email, values.password))
  );

  const errorbox = (
    <div className={classes.infoBox}>
      <ErrorBox
        errorList={[errors.non_field_errors]}
        className={classes.errorAlert}
      />
      <Alert className={classes.errorAlert} severity="info" align="justify">
        Vous venez de vous inscrire ? Cliquez sur le lien fourni dans l'e-mail
        de confirmation pour terminer l'inscription.
        <br />
        Si vous n'avez pas reçu cet e-mail,{" "}
        <Link component={RouterLink} to={ROUTES.re_activate.path}>
          cliquez ici pour en demander un nouveau.
        </Link>
      </Alert>
    </div>
  );

  return (
    <AuthPage loading={loading} title="Connexion">
      {errors.non_field_errors && errorbox}
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Adresse e-mail"
          name="email"
          autoComplete="email"
          autoFocus
          value={values.email}
          error={Boolean(errors.email) && !touched.email}
          helperText={!touched.email && errors.email}
          onChange={handleUserInput}
        />
        <PasswordTextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Mot de passe"
          id="password"
          autoComplete="current-password"
          value={values.password}
          error={Boolean(errors.password) && !touched.password}
          helperText={!touched.password && errors.password}
          onChange={handleUserInput}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Se connecter
        </Button>
        <Grid container>
          <Grid item xs>
            <Link
              component={RouterLink}
              to={ROUTES.reset_password.path}
              variant="body2"
            >
              Mot de passe oublié ?
            </Link>
          </Grid>
          <Grid item>
            <Link
              component={RouterLink}
              to={ROUTES.register.path}
              variant="body2"
            >
              Créer un compte
            </Link>
          </Grid>
        </Grid>
      </form>
    </AuthPage>
  );
}
