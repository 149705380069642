import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, IconButton, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  ChevronRight,
  ChevronLeft,
  PageFirst,
  PageLast,
} from "mdi-material-ui";

import { LIST_PAGE_SIZE } from "constants/ui";
import { useQueryParams } from "hooks/queries";
import { useCurrentQueryCount } from "hooks/queries";
import { ROUTES } from "routes";
import { idFromQuery } from "selectors/queries";

const useStyles = makeStyles((theme) => ({
  label: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  toolbox: {
    flex: "0 0 auto",
  },
}));

export default function PaginationToolbox({ className, ...props }) {
  const classes = useStyles();
  const size = LIST_PAGE_SIZE;
  const params = useQueryParams();
  const count = useCurrentQueryCount();
  const page = Number(params.page) || 1;

  const start_index = () => (page - 1) * size + 1;
  const end_index = () => Math.min(count, page * size) || "?";
  const nb_pages = () => Math.floor(count / size) + 1 || "?";

  const getRoute = (page_number) =>
    `${ROUTES.list.path}${idFromQuery({
      ...params,
      page: page_number,
    })}`;

  const first_page = (
    <Hidden smDown>
      <IconButton size="small" component={Link} to={getRoute(1)}>
        <PageFirst />
      </IconButton>
    </Hidden>
  );
  const last_page = (
    <Hidden smDown>
      <IconButton size="small" component={Link} to={getRoute(nb_pages())}>
        <PageLast />
      </IconButton>
    </Hidden>
  );
  const prev_page = (
    <IconButton size="small" component={Link} to={getRoute(page - 1)}>
      <ChevronLeft />
    </IconButton>
  );

  const next_page = (
    <IconButton size="small" component={Link} to={getRoute(page + 1)}>
      <ChevronRight />
    </IconButton>
  );

  return (
    <div className={clsx(className, classes.toolbox)}>
      {page - 2 > 0 && first_page}
      {page - 1 > 0 && prev_page}
      <Typography variant="overline" className={classes.label}>
        {`${start_index()}-${end_index()} `}
        {count > 0 && `sur ${count}`}
      </Typography>
      {page + 1 <= nb_pages() && next_page}
      {page + 2 <= nb_pages() && last_page}
    </div>
  );
}
