import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, Container, AppBar } from "@material-ui/core";

import MyToolbar from "./navigation/MyToolbar";
import MyBottomNavigation from "./navigation/MyBottomNavigation";
import Notifier from "./Notifier";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    justifyContent: "stretch",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  toolbar: {
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  footer: theme.mixins.toolbar,
  innerContent: { flexGrow: 1, background: theme.palette.background.paper },
  nav: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

NoSidebarLayout.propTypes = {
  children: PropTypes.any,
};

export default function NoSidebarLayout({ children, ...props }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <MyToolbar noButton />
      </AppBar>
      <Notifier />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Container
          maxWidth={false}
          disableGutters
          className={classes.innerContent}
        >
          {children}
        </Container>
        <div className={classes.footer} />
      </main>
      <MyBottomNavigation className={classes.nav} />
    </div>
  );
}
