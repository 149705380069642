import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  LinearProgress,
  Typography,
  Tooltip,
  ListItem,
} from "@material-ui/core";
import { CheckCircleOutline, AlertCircleOutline } from "mdi-material-ui";
import { getPalette } from "components/Theming";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "no-wrap",
    justifyContent: "space-between",
    //color: theme.palette.text.secondary,
  },
  wrong: {
    color: theme.palette.error.main,
  },
  good: {
    color: theme.palette.success.main,
  },
  icon: {
    fontSize: "15px",
    marginRight: theme.spacing(0.5),
    verticalAlign: "text-top",
  },
  value_label: {},
  progress: ({ style, index }) => ({
    backgroundColor: getPalette(theme, style, index).main,
  }),
  no_progress: ({ style, index }) => ({
    backgroundColor: getPalette(theme, style, index)[100],
  }),
}));

const PROGRESS_TYPE_MIN = "mini";
const PROGRESS_TYPE_MAX = "maxi";

export default function Progress({
  value,
  target,
  progress,
  type,
  label,
  units,
  tooltip,
  barStyle,
  disableTarget,
  index,
}) {
  const wrong = progress >= 100 && type === PROGRESS_TYPE_MAX;
  const good = progress >= 100 && type === PROGRESS_TYPE_MIN;
  const classes = useStyles({
    style:
      progress < 100
        ? barStyle || "standard"
        : type === PROGRESS_TYPE_MAX
        ? "error"
        : "success",
    index,
  });
  return (
    <ListItem className={classes.root}>
      <Tooltip title={tooltip || ""}>
        <div className={classes.root}>
          <div className={classes.header}>
            <Typography size="small" variant="body2" component="span">
              {label}
            </Typography>
            <span
              className={clsx(wrong && classes.wrong, good && classes.good)}
            >
              <Typography
                size="small"
                variant="body2"
                component="span"
                color="inherit"
                className={classes.value_label}
                noWrap
              >
                {type === PROGRESS_TYPE_MAX && progress >= 100 && (
                  <AlertCircleOutline className={classes.icon} />
                )}
                {type === PROGRESS_TYPE_MIN && progress >= 100 && (
                  <CheckCircleOutline className={classes.icon} />
                )}
                {`${value}${units}`}
                {!disableTarget && ` / ${target}${units} ${type}`}
              </Typography>
            </span>
          </div>
          <LinearProgress
            variant="determinate"
            value={Math.max(progress, 2)}
            classes={{
              barColorPrimary: classes.progress,
              colorPrimary: classes.no_progress,
            }}
          />
        </div>
      </Tooltip>
    </ListItem>
  );
}

Progress.propTypes = {
  value: PropTypes.number,
  target: PropTypes.number,
  progress: PropTypes.number,
  type: PropTypes.oneOf([PROGRESS_TYPE_MIN, PROGRESS_TYPE_MAX]),
  label: PropTypes.string,
  units: PropTypes.string,
  progressClass: PropTypes.object,
};
