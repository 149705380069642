import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import {
  HourIcon,
  color as hourColor,
  sort as hourSort,
  keySort as hourKeySort,
  label as hourLabel,
} from "./HourUtils";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Text } from "mdi-material-ui";
import { duration } from "./HourUtils";

LogDetailedData.propTypes = {
  log: PropTypes.object,
  disableRightPadding: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  gridItem: {
    paddingTop: 0,
  },
  list: {
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "initial",
    },
  },
  item: {
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  note: {
    flex: "1 1 0%",
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  spacer: {
    flex: "1 1 0%",
  },
  dotted: {
    borderBottom: "1px dotted",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    height: "50%",
    overflow: "hidden",
  },
  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  noteContainer: {
    padding: 0,
  },
  icon: {
    minWidth: "auto",
    marginRight: theme.spacing(2),
  },
  subheader: {
    lineHeight: "initial",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

export function SkeletonDetailedData({
  animation,
  disableRightPadding,
  ...props
}) {
  const classes = useStyles();
  return (
    <Grid container alignItems="flex-start" {...props}>
      <Grid item xs={12} sm={6}>
        <List
          dense
          subheader={
            <ListSubheader className={classes.subheader}>
              <Skeleton animation={animation} width="60%" />
            </ListSubheader>
          }
          className={classes.list}
        >
          {[...Array(4)].map((x, i) => (
            <ListItem dense className={classes.item} key={i}>
              <ListItemText primary={<Skeleton animation={animation} />} />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12} sm={6}>
        <List
          dense
          subheader={
            <ListSubheader className={classes.subheader}>
              <Skeleton animation={animation} width="40%" />
            </ListSubheader>
          }
          className={classes.list}
        >
          {[...Array(4)].map((x, i) => (
            <ListItem dense className={classes.item} key={i}>
              <ListItemText primary={<Skeleton animation={animation} />} />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}

export default function LogDetailedData({
  log,
  disableRightPadding,
  ...props
}) {
  const classes = useStyles();
  const theme = useTheme();

  const generateLine = (key, label, value, icon, color) => (
    <ListItem key={key || label} dense className={classes.item}>
      {icon && (
        <ListItemIcon className={classes.icon} style={{ color }}>
          {icon}
        </ListItemIcon>
      )}
      <ListItemText
        primary={
          <div className={classes.inner}>
            <Typography component="span" noWrap>
              {label}
            </Typography>
            <span className={clsx(classes.dotted, classes.spacer)} />
            <Typography component="span" noWrap>
              {value}
            </Typography>
          </div>
        }
      />
    </ListItem>
  );

  const generateIcon = (type) => {
    return <HourIcon type={type} fontSize="small" color="inherit" />;
  };

  const getHours = () => {
    if (Array.isArray(log.hours)) return hourSort(log.hours);
    else return hourKeySort(log.hours);
  };

  const getOccs = () => {
    if (Array.isArray(log.occurrences)) return log.occurrences;
    else
      return Object.keys(log.occurrences)
        .sort()
        .map((key) => ({
          zone_name: key,
          count: log.occurrences[key],
        }));
  };

  return !log ? (
    <SkeletonDetailedData {...props} />
  ) : (
    <Grid container alignItems="flex-start" {...props}>
      {log.note && (
        <Grid item xs={disableRightPadding ? 12 : 10}>
          <List dense className={clsx(classes.list, classes.noteContainer)}>
            <ListItem dense className={classes.item}>
              <ListItemIcon className={classes.icon}>
                <Text fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <div className={classes.inner}>
                    <Typography
                      component="span"
                      variant="subtitle2"
                      color="textSecondary"
                      noWrap
                    >
                      Note:
                    </Typography>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.note}
                      align="justify"
                    >
                      {log.note}
                    </Typography>
                  </div>
                }
              />
            </ListItem>
          </List>
        </Grid>
      )}
      <Grid
        item
        xs={disableRightPadding ? 12 : 10}
        sm={disableRightPadding ? 6 : 5}
        md={6}
      >
        <List
          dense
          subheader={
            <ListSubheader className={classes.subheader}>
              <div className={classes.inner}>
                <span>Temps de travail</span>
                <span className={classes.spacer} />
                <span>{duration(log.total_time)}</span>
              </div>
            </ListSubheader>
          }
          className={classes.list}
        >
          {log.hours &&
            getHours().map(({ hour_type, count }) =>
              generateLine(
                hour_type,
                hourLabel(hour_type),
                duration(count),
                generateIcon(hour_type),
                hourColor(theme, hour_type)
              )
            )}
        </List>
      </Grid>
      <Grid
        item
        xs={disableRightPadding ? 12 : 10}
        sm={disableRightPadding ? 6 : 5}
        md={6}
      >
        <List
          dense
          subheader={
            <ListSubheader className={classes.subheader}>
              <div className={classes.inner}>
                <span>Occurrences</span>
                <span className={classes.spacer} />
                <span>
                  {log.occurrences &&
                    getOccs()
                      .map((z) => z.count)
                      .reduce((a, b) => a + b, 0)}
                </span>
              </div>
            </ListSubheader>
          }
          className={classes.list}
        >
          {log.occurrences &&
            getOccs().map(({ zone_id, zone_name, count }) =>
              generateLine(zone_id, zone_name, count)
            )}
        </List>
      </Grid>
    </Grid>
  );
}
