import React from "react";
import { Radar, Headset, AccountSupervisor, School } from "mdi-material-ui";
import * as hours from "constants/hours";

export function HourIcon({ type, ...props }) {
  switch (type) {
    case hours.HOUR_STANDARD.type:
      return <Headset {...props} />;
    case hours.HOUR_OJTI.type:
      return <Radar {...props} />;
    case hours.HOUR_SIM.type:
      return <School {...props} />;
    case hours.HOUR_DUAL.type:
      return <AccountSupervisor {...props} />;
    default:
      return;
  }
}

export const rank = (type) => hours.HOURS[type].order;

export const label = (type) => hours.HOURS[type].label;

export const color = (theme, type) => theme.palette[type].main;

export const sort = (hours) =>
  hours.slice().sort((a, b) => rank(a.hour_type) - rank(b.hour_type));
//when using immer, local state is immutable so sort must be used on a copy

export const keySort = (hour_dict) =>
  Object.keys(hour_dict)
    .sort((a, b) => rank(a) - rank(b))
    .map((key) => ({ hour_type: key, count: hour_dict[key] }));

export const filterVisible = (list, sim, dual) =>
  list.filter(({ hour_type }) => {
    return (
      (hour_type !== hours.HOURS.sim.type || sim) &&
      (hour_type !== hours.HOURS.dual.type || dual)
    );
  });

export const duration = (minutes) => {
  let hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  if (hours === 0) {
    return minutes + " min";
  } else {
    return hours + "h" + ("00" + minutes).slice(-2);
  }
};
