import React from "react";
import moment from "moment";
import {
  Pencil,
  FormatListBulleted,
  CardAccountDetails,
  ChartBar,
} from "mdi-material-ui";

import { ROUTES } from "routes";
import { idFromQuery } from "selectors/queries";
import { DATE_FORMAT_DATA } from "constants/ui";

export default function getMenu(last_renewal) {
  return [
    { route: ROUTES.today, icon: <Pencil /> },
    { route: ROUTES.licence, icon: <CardAccountDetails /> },
    {
      route: ROUTES.list,
      icon: <FormatListBulleted />,
      search: idFromQuery({
        start: moment()
          .subtract(1, "month")
          .startOf("month")
          .format(DATE_FORMAT_DATA),
        page: 1,
      }),
    },
    {
      route: ROUTES.stat,
      icon: <ChartBar />,
      search: last_renewal
        ? idFromQuery({ start: last_renewal, page: 1 })
        : undefined,
    },
  ];
}
