import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Button,
} from "@material-ui/core";
import PasswordTextField from "components/utils/PasswordTextField";
import ErrorBox from "components/utils/ErrorBox";
import { useAuthState } from "hooks";
import { useDialog } from "hooks/ui";
import { useForm } from "hooks/forms";

export function useConfirmationWithPasswordDialog({
  action_type,
  title,
  message,
  onConfirm,
  keep_open = false,
}) {
  const { isOpen, open, close } = useDialog();
  const state = useAuthState(action_type);
  const errors = state ? state.errors : {};
  const loading = state ? state.loading : false;
  const { values, touched, handleUserInput, reset } = useForm({ password: "" });

  const onClose = (confirmed) => {
    if (confirmed) {
      onConfirm(values.password);
      reset({ password: "" });
    }
    if (!confirmed || !keep_open) {
      close();
    }
  };

  const display = (
    <Dialog
      open={isOpen}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <DialogContentText>
          Si vous êtes sûr de votre décision, indiquez votre mot de passe
          ci-dessous:
        </DialogContentText>
        <ErrorBox errorDict={errors} noKeys />
        {values && (
          <PasswordTextField
            margin="dense"
            id="password"
            name="password"
            label="Mot de passe"
            required
            autoComplete="current-password"
            value={values.password}
            error={Boolean(errors.current_password) && !touched}
            helperText={errors.current_password}
            onChange={handleUserInput}
            autoFocus
          />
        )}
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress size={24} />}
        <Button
          onClick={() => onClose(false)}
          color="primary"
          disabled={loading}
        >
          Annuler
        </Button>
        <Button
          onClick={() => onClose(true)}
          color="primary"
          disabled={loading}
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );

  return { open, state, display };
}
