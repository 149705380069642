import { combineReducers } from "redux";
import * as actions from "constants/actions/auth";
import {
  CREATE_ACTION,
  READ_ACTION,
  DESTROY_ACTION,
  EXPORT_ACTION,
  IMPORT_ACTION,
} from "constants/actions/entities";
import { USER_ENTITY } from "constants/entities";
import * as status from "constants/status";
import { isRequest, isAction, isEntity } from "actions/helpers";
import * as utils from "./helpers";

const logout = (state = { loading: false }, action) => {
  //if logout success or fail, apply logout anyway
  if (isAction(action, actions.LOGOUT_ACTION)) {
    return {
      ...state,
      loading: isRequest(action),
    };
  }
  return state;
};

const register = utils.getStatusReducer(
  (action) => isAction(action, CREATE_ACTION) && isEntity(action, USER_ENTITY)
);

const readMe = utils.getStatusReducer(
  (action) => isAction(action, READ_ACTION) && isEntity(action, USER_ENTITY)
);

const deleteMe = utils.getStatusReducer(
  (action) => isAction(action, DESTROY_ACTION) && isEntity(action, USER_ENTITY)
);

const status_reducer = combineReducers({
  [status.LOGIN_STATE]: utils.getSimpleStatusReducer(actions.LOGIN_ACTION),
  [status.SET_PASSWORD_STATE]: utils.getSimpleStatusReducer(
    actions.SET_PASSWORD_ACTION
  ),
  [status.RESEND_ACTIVATION_STATE]: utils.getSimpleStatusReducer(
    actions.RE_ACTIVATE_ACTION
  ),
  [status.ACTIVATION_STATE]: utils.getSimpleStatusReducer(
    actions.ACTIVATE_ACTION
  ),
  [status.RESET_PASSWORD_STATE]: utils.getSimpleStatusReducer(
    actions.RESET_PASSWORD_ACTION
  ),
  [status.CONFIRM_PASSWORD_STATE]: utils.getSimpleStatusReducer(
    actions.RESET_PASSWORD_CONFIRM_ACTION
  ),
  [status.LOGOUT_STATE]: logout,
  [status.REGISTER_STATE]: register,
  [status.READ_PROFILE_STATE]: readMe,
  [status.DELETE_PROFILE_STATE]: deleteMe,
  [status.DOWNLOAD_ALL_STATE]: utils.getSimpleStatusReducer(
    actions.DOWNLOAD_ALL_ACTION
  ),
  [status.EXPORT_STATE]: utils.getSimpleStatusReducer(EXPORT_ACTION),
  [status.RESET_ACCOUNT_STATE]: utils.getSimpleStatusReducer(
    actions.RESET_ACTION
  ),
  [status.SEND_ADMIN_MESSAGE_STATE]: utils.getSimpleStatusReducer(
    actions.CONTACT_ACTION
  ),
  [status.IMPORT_CSV_STATE]: utils.getSimpleStatusReducer(IMPORT_ACTION),
});

export default status_reducer;
