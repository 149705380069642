import React from "react";
import { Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HelpCircleOutline } from "mdi-material-ui";
import { Link as RouterLink } from "react-router-dom";
import { ROUTES } from "routes";

const useStyles = makeStyles((theme) => ({
  menu: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  menuItem: {
    marginRight: theme.spacing(1),
  },
  toolbarButton: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.dark,
    "&:hover": {
      color: theme.palette.primary.light,
      backgroundColor: theme.palette.background.default,
    },
  },
}));

export default function AnonymousMenu() {
  const classes = useStyles();

  return (
    <div className={classes.menu}>
      <IconButton
        component={RouterLink}
        to={ROUTES.help.path}
        color="inherit"
        className={classes.menuItem}
      >
        <HelpCircleOutline />
      </IconButton>
      <Button
        variant="contained"
        size="small"
        component={RouterLink}
        to={ROUTES.login.path}
        className={classes.toolbarButton}
      >
        Connexion
      </Button>
    </div>
  );
}
