import { combineReducers } from "redux";
import * as entityNames from "constants/entities";
import entityReducer from "./entities";
import uniqueReducer from "./uniques";
import logReducer from "./logs";

const entities = combineReducers({
  // users: getReducer(entityNames.USER_ENTITY), not needed, USER actions are handled in auth
  //prefs : not needed, handled in ui
  [entityNames.ZONE_ENTITY]: entityReducer(entityNames.ZONE_ENTITY),
  [entityNames.LOG_ENTITY]: logReducer,
  [entityNames.ARCHIVE_ENTITY]: entityReducer(entityNames.ARCHIVE_ENTITY),
  [entityNames.LICENCE_ENTITY]: uniqueReducer(entityNames.LICENCE_ENTITY),
});

export default entities;
