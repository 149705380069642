import { isEntity, targetsSingleEntity, isRequest } from "actions/helpers";
import {
  DEFAULT_ERRORS,
  shouldResetData,
  getStatus,
  shouldClearStatus,
  clearStatus,
} from "../helpers";

const INITIAL_STATE = {
  loading: false,
  errors: DEFAULT_ERRORS,
  hasErrors: false,
};

const uniqueReducer = (entityName) => (state = INITIAL_STATE, action) => {
  if (shouldResetData(action)) {
    return INITIAL_STATE;
  }
  if (shouldClearStatus(action)) {
    return clearStatus(state);
  }
  //check that the action is relevant to this reducer
  //check that if the action is a request, the stale indicator must be true
  if (
    isEntity(action, entityName) &&
    targetsSingleEntity(action) &&
    (!isRequest(action) || action.meta.stale)
  ) {
    return {
      ...getStatus(action, true),
      ...(action.payload || {}),
    };
  }
  return state;
};

export default uniqueReducer;
