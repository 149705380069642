import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Container, Paper, Typography, useMediaQuery } from "@material-ui/core";

import AppIcon from "components/utils/AppIcon";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(4),
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
}));

AuthPage.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.any,
};

export default function AuthPage({ loading, title, children, ...props }) {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const ParentTag = smUp ? Paper : "div";

  return (
    <Container component="main" maxWidth="sm">
      <ParentTag className={classes.paper}>
        <AppIcon loading={loading} />
        <Typography component="h1" variant="h5">
          {title}
        </Typography>
        {children}
      </ParentTag>
    </Container>
  );
}
