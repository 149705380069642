import moment from "moment";

import { LOG_ENTITY, LICENCE_ENTITY, TARGET_ENTITY } from "constants/entities";
import { DATE_FORMAT_DATA } from "constants/ui";
import {
  readEntity,
  updateEntity,
  destroyEntity,
  listEntities,
} from "actions/entities";

const _dateToPk = (date) => moment(date).format(DATE_FORMAT_DATA);

export const readLogAtDate = (date) => readEntity(LOG_ENTITY, _dateToPk(date));

export const updateLogAtDate = (date, body) =>
  updateEntity(LOG_ENTITY, _dateToPk(date), body);

export const destroyLogAtDate = (date) =>
  destroyEntity(LOG_ENTITY, _dateToPk(date));

export const listLogs = ({ page, ...params }) =>
  listEntities(LOG_ENTITY, {
    page: page || 1,
    ...params,
  });

export const readLicence = () => readEntity(LICENCE_ENTITY, "");

export const updateLicence = (body) =>
  updateEntity(LICENCE_ENTITY, "", { ...body, date: _dateToPk(body.date) });

export const updateTargets = (body) => updateEntity(TARGET_ENTITY, "", body);
