import React from "react";
import { render } from "react-dom";

import Root from "./components/Root";
import configureStore from "./configureStore";
import configureLocale from "./configureLocale";
import configureMatomo from "./configureMatomo";
import { MAINTENANCE_MODE } from "constants/config";
import * as serviceWorker from "./serviceWorker";

configureLocale();
const { store, persistor, history } = configureStore();
const matomo = configureMatomo(history);

render(
  <Root
    store={store}
    history={history}
    persistor={persistor}
    maintenance={MAINTENANCE_MODE}
    matomo={matomo}
  />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
