import { useMemo } from "react";
import { useSelector } from "react-redux";

import { getAuthToken, getMe } from "selectors/user";

export function useAuthToken() {
  return useSelector(getAuthToken);
}

export function useAuthenticated() {
  const token = useAuthToken();
  return useMemo(() => token !== "", [token]);
}

export function useMe() {
  return useSelector(getMe);
}
