import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, Container, AppBar, Box } from "@material-ui/core";

import MyToolbar from "./navigation/MyToolbar";
import Notifier from "./Notifier";
import Footer from "./navigation/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    justifyContent: "stretch",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      background: theme.palette.background.paper,
    },
  },
  drawerHeader: {
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  innerContent: { flexGrow: 1 },
  footer: {
    color: theme.palette.text.secondary,
  },
}));

NoSidebarLayout.propTypes = {
  children: PropTypes.any,
};

export default function NoSidebarLayout({ children, ...props }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <MyToolbar />
      </AppBar>
      <Notifier />
      <main className={classes.content}>
        <div className={classes.drawerHeader} />
        <Container
          maxWidth={false}
          disableGutters
          className={classes.innerContent}
        >
          {children}
        </Container>
        <Box pt={4} pb={2} className={classes.footer}>
          <Footer />
        </Box>
      </main>
    </div>
  );
}
