import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import entities from "./entities";
import { token, me } from "./user";
import status from "./status";
import ui from "./ui";
import queries from "./queries";
import stats from "./stats";

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        token,
        me,
        ui,
        status,
        entities,
        queries,
        stats,
    });

export default createRootReducer;
