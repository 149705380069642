import React from "react";
import { useDispatch } from "react-redux";
import { Snackbar, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close } from "mdi-material-ui";

import { useMessage } from "hooks/ui";
import { clearMessage } from "actions/ui";

const useStyles = makeStyles((theme) => ({
  root: {
    top: theme.mixins.toolbar.minHeight,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      top:
        theme.mixins.toolbar[
          `${theme.breakpoints.up("xs")} and (orientation: landscape)`
        ].minHeight,
    },
    [theme.breakpoints.up("sm")]: {
      top: theme.mixins.toolbar[theme.breakpoints.up("sm")].minHeight,
    },
    marginTop: 12,
    right: 12,
  },
}));

export default function Notifier() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const message = useMessage();
  const isOpen = message !== "";

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    dispatch(clearMessage());
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      message={message}
      open={isOpen}
      autoHideDuration={5000}
      onClose={handleClose}
      className={classes.root}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      }
    />
  );
}
