export const HOUR_STANDARD = { type: "standard", label: "Réel", order: 0 };
export const HOUR_OJTI = { type: "ojti", label: "ISP", order: 1 };
export const HOUR_SIM = { type: "sim", label: "Simu", order: 2 };
export const HOUR_DUAL = { type: "dual", label: "Double", order: 3 };
export const HOURS = {
  standard: HOUR_STANDARD,
  ojti: HOUR_OJTI,
  sim: HOUR_SIM,
  dual: HOUR_DUAL,
};
