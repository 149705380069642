import * as actions from "constants/actions/entities";
import { isRequest, isAction, isEntity } from "actions/helpers";
import {
  DEFAULT_ERRORS,
  getUpdatedAt,
  getEntityPk,
  shouldResetData,
  getStatus,
  clearStatus,
} from "../helpers";

const INITIAL_STATE = {
  loading: false,
  errors: DEFAULT_ERRORS,
  hasError: false,
  entities: [],
};

/**
 * `updated_at` attribute is used by staleness middleware to only forward
 * request actions to api if the data is stale
 *
 * `meta.stale` attribute on request actions indicates that the action has been
 * forwarded to API and waiting for response. For fresh data, `meta.stale` is
 * false and there is no call to API.
 * Reducers should therefore ignore this action
 *
 */

const entityReducer = (entityName) => (state = INITIAL_STATE, action) => {
  const { meta, payload } = action;
  if (shouldResetData(action)) {
    return INITIAL_STATE;
  }
  //check that the action is relevant to this reducer
  //check that if the action is a request, the stale indicator must be true
  if (isEntity(action, entityName) && (!isRequest(action) || meta.stale)) {
    const status = getStatus(action, true);
    //reset errors on all entities
    const entities_without_errors = state.entities.map((entity) =>
      clearStatus(entity)
    );
    const updated_at = getUpdatedAt(action);
    if (isAction(action, actions.LIST_ACTION)) {
      //status is applied globally and payload replace entities
      return {
        ...state,
        ...status,
        entities: [...(payload || state.entities)],
        updated_at,
      };
    } else if (isAction(action, actions.CREATE_ACTION)) {
      //status is applied globally, errors are reset at entity level and new entity is appended to entities
      return {
        ...state,
        ...status,
        entities: payload
          ? [...entities_without_errors, { ...payload, updated_at, ...status }]
          : entities_without_errors,
      };
    } else if (isAction(action, actions.DESTROY_ACTION)) {
      //status is applied globally, errors are reset at entity level and target entity is removed from entities
      return {
        ...state,
        ...status,
        entities: entities_without_errors.filter(
          (entity) => getEntityPk(entity) !== meta.pk
        ),
      };
    } else if (isAction(action, actions.READ_ACTION)) {
      //errors are reset at entity level, target entity is removed from list then re-appended with status and new_date from payload
      return {
        ...state,
        entities: [
          ...entities_without_errors.filter(
            (entity) => getEntityPk(entity) !== meta.pk
          ),
          {
            ...status,
            ...(payload || {}),
            updated_at,
          },
        ],
      };
    } else if (isAction(action, actions.UPDATE_ACTION)) {
      //errors are reset at entity level, target entity is updated with status and new data from payload
      return {
        ...state,
        entities: [
          ...entities_without_errors.map((entity) =>
            getEntityPk(entity) !== meta.pk
              ? entity
              : {
                  ...entity,
                  ...status,
                  ...(payload || {}),
                  updated_at,
                }
          ),
        ],
      };
    }
  }
  return state;
};

export default entityReducer;
