import React, { useEffect, useCallback, useReducer } from "react";
import { useDispatch } from "react-redux";
import { Grid, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { HOURS } from "constants/hours";
import { useCurrentDate, useStateWithRef, useDebounce } from "hooks";
import { useSimVisibility, useDualVisibility } from "hooks/ui";
import { useLogForDate } from "hooks/entities";
import { localInputReducer as logReducer } from "reducers/entities/logs";
import { readLogAtDate, updateLogAtDate } from "actions";

import { sort as hourSort, filterVisible } from "components/utils/HourUtils";
import ErrorBox from "components/utils/ErrorBox";

import HourInput from "./HourInput";
import ZoneInput from "./ZoneInput";
import NoteInput from "./NoteInput";
import SecondaryToolbar from "./SecondaryToolbar";

const useStyles = makeStyles((theme) => ({
  alert: {
    width: "100%",
  },
  wrapper: {
    background: theme.palette.background.default,
    height: "100%",
  },
  content: {
    padding: 0,
    [theme.breakpoints.up("sm")]: {
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0.5),
    },
  },
  item: {
    [theme.breakpoints.up("sm")]: {
      paddingRight: 0,
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0.5),
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
}));

const DEBOUNCE_DELAY_IN_MS = 500;

const countDisplayedTypes = (sim, dual) => 2 + (sim ? 1 : 0) + (dual ? 1 : 0);

function SkeletonLog({ sim, dual }) {
  const classes = useStyles();
  const displayed_types = () => countDisplayedTypes(sim, dual);
  return (
    <Grid component="main" container className={classes.content}>
      <Grid container item xs={12}>
        {Object.values(HOURS)
          .sort((a, b) => a.order - b.order)
          .filter(({ type }) => {
            return (
              (type !== HOURS.sim.type || sim) &&
              (type !== HOURS.dual.type || dual)
            );
          })
          .map(({ type }) => (
            <Grid
              item
              xs={12}
              md={Math.max(12 / displayed_types(), 6)}
              lg={Math.max(12 / displayed_types(), 3)}
              key={type}
              className={classes.item}
            >
              <HourInput type={type} />
            </Grid>
          ))}
      </Grid>
      <Grid container item xs={12}>
        {[...Array(2)].map((x, i) => (
          <Grid item xs={12} md={6} lg={6} key={i} className={classes.item}>
            <ZoneInput />
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} lg={6} className={classes.item}>
        <NoteInput />
      </Grid>
    </Grid>
  );
}

export default function InputPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sim = useSimVisibility();
  const dual = useDualVisibility();
  const current_date = useCurrentDate();
  const stored_log = useLogForDate(current_date);
  const [isWaiting, setWaiting, isWaitingRef] = useStateWithRef(false); //no log in state yet
  const save = useDebounce(_save, DEBOUNCE_DELAY_IN_MS);

  //must not be an arrow function
  function _save(log) {
    dispatch(updateLogAtDate(log.date, log));
  }

  const custom_reducer = (state, { from_server, ...action }) => {
    const result = logReducer(state, action);
    if (!from_server) {
      save(result);
    }
    return result;
  };

  const [local_log, dispatchLocalLog] = useReducer(custom_reducer, {
    loading: true,
  });

  const displayed_zones = () => local_log.occurrences.length;

  const displayed_types = () => countDisplayedTypes(sim, dual);

  //do not update local_log on user actions if fetch is pending
  const updateLocalLog = useCallback(
    (action) => {
      if (!isWaiting) {
        dispatchLocalLog(action);
      }
    },
    [isWaiting]
  );

  // on date change, request read log from server
  useEffect(() => {
    //prevent effect to trigger again by using a ref of isWaiting instead of state directly
    if (!isWaitingRef.current) {
      setWaiting(true);
      dispatch(readLogAtDate(current_date));
    }
  }, [dispatch, current_date, isWaitingRef, setWaiting]);

  // on stored_log change, update local_log
  useEffect(() => {
    if (stored_log.loading === false) {
      dispatchLocalLog({
        type: "reset",
        from_server: true,
        payload: stored_log,
      });
      setWaiting(false);
    }
  }, [stored_log, setWaiting, dispatchLocalLog]);

  return (
    <div className={classes.wrapper}>
      <SecondaryToolbar
        marked={local_log.marked}
        onMarkClick={() => updateLocalLog({ type: "mark" })}
        onResetClick={() => updateLocalLog({ type: "reset" })}
      />
      <Container disableGutters maxWidth="lg">
        {isWaiting ? (
          <SkeletonLog sim={sim} dual={dual} />
        ) : (
          <Grid component="main" container className={classes.content}>
            {local_log.hasErrors && (
              <Grid item xs={12} className={classes.item}>
                <ErrorBox
                  className={classes.alert}
                  errorDict={local_log.errors}
                />
              </Grid>
            )}
            <Grid container item xs={12}>
              {local_log.hasOwnProperty("hours") &&
                filterVisible(hourSort(local_log.hours), sim, dual).map(
                  ({ hour_type, count }) => (
                    <Grid
                      item
                      xs={12}
                      md={Math.max(12 / displayed_types(), 6)}
                      lg={Math.max(12 / displayed_types(), 3)}
                      key={hour_type}
                      className={classes.item}
                    >
                      <HourInput
                        value={count}
                        type={hour_type}
                        onStepClick={(direction) =>
                          updateLocalLog({
                            type: "hours",
                            target: hour_type,
                            direction,
                          })
                        }
                      />
                    </Grid>
                  )
                )}
            </Grid>
            <Grid container item xs={12}>
              {local_log.hasOwnProperty("occurrences") &&
                local_log.occurrences.map(({ zone_id, zone_name, count }) => (
                  <Grid
                    item
                    xs={12}
                    md={Math.max(12 / displayed_zones(), 6)}
                    lg={Math.max(12 / displayed_zones(), 3)}
                    key={zone_id}
                    className={classes.item}
                  >
                    <ZoneInput
                      id={zone_id}
                      value={count}
                      name={zone_name}
                      onStepClick={(direction) =>
                        updateLocalLog({
                          type: "occs",
                          target: zone_id,
                          direction,
                        })
                      }
                    />
                  </Grid>
                ))}
            </Grid>
            <Grid item xs={12} lg={6} className={classes.item}>
              {(!local_log.hasErrors || !local_log.errors.note) && (
                <NoteInput
                  value={local_log.note || ""}
                  loading={isWaiting || local_log.loading}
                  onEdited={(value) => updateLocalLog({ type: "note", value })}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
}
