import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getQueryFromParams,
  getQueryWithLogFromParams,
} from "selectors/queries";

export function useQueryParams() {
  return Object.fromEntries(new URLSearchParams(useLocation().search));
}

export function useCurrentQuery() {
  return useSelector(getQueryFromParams(useQueryParams()));
}

export function useCurrentQueryCount() {
  return useCurrentQuery().count || 0;
}

export function useCurrentQueryWithLogs() {
  return useSelector(getQueryWithLogFromParams(useQueryParams()));
}
