import React from "react";
import { useDispatch } from "react-redux";
import { Grid, Button, Typography, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

import { useAuthState } from "hooks";
import { useForm } from "hooks/forms";
import { SET_PASSWORD_STATE } from "constants/status";
import { set_password } from "actions/auth";

import PageContainer from "components/utils/PageContainer";
import PasswordTextField from "components/utils/PasswordTextField";
import ErrorBox from "components/utils/ErrorBox";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  input: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    marginLeft: 8,
    color: "inherit",
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
}));

export default function SetPassword() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, success, errors } = useAuthState(SET_PASSWORD_STATE);
  const { values, touched, handleUserInput, handleSubmit } = useForm(
    {
      current_password: "",
      new_password: "",
      re_new_password: "",
    },
    () =>
      dispatch(
        set_password(
          values.current_password,
          values.new_password,
          values.re_new_password
        )
      )
  );

  const info_box = (
    <Typography className={classes.alert}>
      Vous serez déconnecté automatiquement une fois le nouveau mot de passe
      enregistré.
    </Typography>
  );

  const success_box = (
    <Alert className={classes.alert} severity="success">
      Votre nouveau mot de passe est enregistré !
    </Alert>
  );

  return (
    <PageContainer title="Mot de passe">
      {info_box}
      {success && success_box}
      <ErrorBox
        className={classes.alert}
        errorList={[errors.non_field_errors]}
      />
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PasswordTextField
              variant="outlined"
              required
              fullWidth
              id="current_password"
              label="Mot de passe actuel"
              name="current_password"
              value={values.current_password}
              error={
                Boolean(errors.current_password) && !touched.current_password
              }
              helperText={errors.current_password}
              onChange={handleUserInput}
              autoComplete="current-password"
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordTextField
              variant="outlined"
              required
              fullWidth
              name="new_password"
              label="Nouveau mot de passe"
              id="new_password"
              autoComplete="new-password"
              value={values.new_password}
              error={Boolean(errors.new_password) && !touched.new_password}
              helperText={errors.new_password}
              onChange={handleUserInput}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordTextField
              variant="outlined"
              required
              fullWidth
              name="re_new_password"
              label="Confirmation de mot de passe"
              id="re_new_password"
              autoComplete="new-password"
              value={values.re_new_password}
              error={
                Boolean(errors.re_new_password) && !touched.re_new_password
              }
              helperText={errors.re_new_password}
              onChange={handleUserInput}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={loading}
        >
          Changer le mot de passe
          {loading && (
            <CircularProgress size={16} className={classes.buttonProgress} />
          )}
        </Button>
      </form>
    </PageContainer>
  );
}
