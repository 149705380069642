import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { List, Hidden } from "@material-ui/core";

import SecondaryToolbar from "./toolbar/SecondaryToolbar";
import LogListItem, { SkeletonLogListItem } from "./log/ListItem";
import LogDetailCard, { SkeletonDetailCard } from "./log/DetailCard";
import { useDeleteConfirmDialog } from "./dialogs/DeleteConfirmDialog";
import { useNoteEditDialog } from "./dialogs/NoteEditDialog";
import EmptyMessage from "./EmptyMessage";
import ErrorBox from "components/utils/ErrorBox";

import { LIST_PAGE_SIZE, DRAWER_WIDTH } from "constants/ui";
import { updateLogAtDate, destroyLogAtDate, listLogs } from "actions";
import { useDrawerOpen } from "hooks/ui";
import { useQueryParams, useCurrentQueryWithLogs } from "hooks/queries";
import { isStale } from "middlewares/staleness";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    [theme.breakpoints.up("md")]: {
      background: theme.palette.background.default,
    },
  },
  listContainer: {
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      overflow: "hidden",
      overflowY: "auto",
      height: `calc(100% - 64px - 48px)`, //appbar + dense secondary toolbar
      width: "auto",
      minWidth: "20%",
    },
    [theme.breakpoints.down("sm")]: {
      flex: "1",
    },
    transition: theme.transitions.create("min-width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  listPlaceholder: {
    flex: "0 0 250px", //this is measured min width for list item with content
    minWidth: "20%",
  },
  listContainerWithDrawer: {
    [theme.breakpoints.up("md")]: {
      minWidth: `calc(20% - 0.2*${DRAWER_WIDTH}px)`,
    },
    transition: theme.transitions.create("min-width", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  list: {
    background: theme.palette.background.paper,
    width: "100%",
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  detailContainer: {
    flex: "1 1 0%",
    alignSelf: "flex-start",
  },
  detail: {},
}));

function SkeletonList(props) {
  return (
    <List {...props}>
      {[...Array(LIST_PAGE_SIZE)].map((x, i) => (
        <SkeletonLogListItem key={i} />
      ))}
    </List>
  );
}

export default function ListPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { page, ...params } = useQueryParams();
  const {
    loading,
    errors,
    hasErrors,
    updated_at,
    entities: logs,
  } = useCurrentQueryWithLogs();
  const drawer_open = useDrawerOpen();
  const deleteRowDialog = useDeleteConfirmDialog((log) =>
    dispatch(destroyLogAtDate(log.date))
  );
  const noteRowDialog = useNoteEditDialog((log, new_note) =>
    dispatch(updateLogAtDate(log.date, { ...log, note: new_note }))
  );
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    //request list refresh from server if query has been forced stale (no 'updated_at')
    if (!loading && !hasErrors && isStale(updated_at)) {
      dispatch(listLogs({ page: page || 1, ...params }));
    }
    //Note : this will also be fired when query changes because logs selector is triggered by query
  }, [loading, updated_at, dispatch, hasErrors, params, page]);

  useEffect(() => {
    //refresh selected log in detail card if it has changed
    if (selected) {
      setSelected(
        logs.find(
          (log) => (log && moment(log.date).isSame(selected.date)) || null
        )
      );
    }
  }, [logs, selected]);

  const handleOnMarkClick = (log) => {
    dispatch(updateLogAtDate(log.date, { ...log, marked: !log.marked }));
  };

  const handleOnListItemClick = (log, e) => {
    if (selected === log) {
      setSelected(null);
    } else {
      setSelected(log);
    }
  };

  const list_display = (
    <div
      className={clsx(
        classes.listContainer,
        !!drawer_open && classes.listContainerWithDrawer
      )}
    >
      {loading && logs.length === 0 ? (
        <SkeletonList className={classes.list} disablePadding />
      ) : logs.length > 0 ? (
        <List className={classes.list} disablePadding>
          {logs.map(
            (log) =>
              log && (
                <LogListItem
                  key={log.id}
                  log={log}
                  selected={selected && selected.id === log.id}
                  onClick={(e) => handleOnListItemClick(log, e)}
                  onMarkClick={handleOnMarkClick}
                  onNoteClick={noteRowDialog.open}
                  onDeleteClick={deleteRowDialog.open}
                />
              )
          )}
        </List>
      ) : (
        <Hidden mdUp>
          <EmptyMessage count={logs.length} />
        </Hidden>
      )}
    </div>
  );

  return (
    <div className={classes.root}>
      <SecondaryToolbar query={{ page, ...params }} />
      <div className={classes.content}>
        <Hidden smDown>
          {(loading || logs.length > 0) && (
            <div className={classes.listPlaceholder} />
          )}
          <div className={classes.detailContainer}>
            <ErrorBox errorDict={errors} />
            {!selected && loading ? (
              <SkeletonDetailCard
                className={classes.detail}
                loading={loading}
              />
            ) : !selected ? (
              <EmptyMessage className={classes.detail} count={logs.length} />
            ) : (
              <LogDetailCard
                log={selected}
                onNoteClick={noteRowDialog.open}
                onDeleteClick={deleteRowDialog.open}
                onMarkClick={handleOnMarkClick}
                className={classes.detail}
              />
            )}
          </div>
        </Hidden>
        {list_display}
        {noteRowDialog.display}
        {deleteRowDialog.display}
      </div>
    </div>
  );
}
