import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import { Button, IconButton, Tooltip, useMediaQuery } from "@material-ui/core";

ResponsiveButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.element,
  props: PropTypes.any,
  active: PropTypes.bool,
};

export default function ResponsiveButton({
  active,
  text,
  startIcon,
  endIcon,
  ...props
}) {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  return smUp ? (
    <Button
      variant={active ? "contained" : "outlined"}
      color={active ? "primary" : "default"}
      startIcon={startIcon}
      endIcon={endIcon}
      {...props}
    >
      {text}
    </Button>
  ) : (
    <Tooltip title={text} arrow>
      <IconButton color={active ? "primary" : "default"} {...props}>
        {startIcon || endIcon}
      </IconButton>
    </Tooltip>
  );
}
