const API_VERSION = "1.0";
export const HEADERS = new Headers({
  "Content-Type": "application/json",
  Accept: `application/json; version=${API_VERSION}`,
});
export const CONTENT_TYPE_HEADER_SUFFIX = `; version=${API_VERSION}`;
export const MIMETYPE_JSON = "application/json;charset=utf-8";
export const MIMETYPE_PDF = "application/pdf;charset=utf-8";
export const MIMETYPE_CSV = "text/csv;charset=utf-8";

export const API_URI = `${process.env.REACT_APP_BACKEND_HOST}/api`;

export const USER_URI = "auth/users/";
export const USER_ME_ID = "me";

export const LOGIN_URI = "auth/token/login/";
export const LOGOUT_URI = "auth/token/logout/";
export const ACTIVATION_URI = "auth/users/activation/";
export const ACTIVATION_RE_URI = "auth/users/resend_activation/";
export const PASSWORD_SET_URI = "auth/users/set_password/";
export const PASSWORD_RESET_URI = "auth/users/reset_password/";
export const PASSWORD_RESET_CONFIRM_URI = "auth/users/reset_password_confirm/";

export const EVERYTHING_URI = "all/";
export const CONTACT_URI = "contact/";
export const EXPORT_PDF_URI = "export/pdf/";
export const EXPORT_CSV_URI = "export/csv/";
export const IMPORT_URI = "import/";

export const STATS_DEADLINES_URI = "stats/milestones/";
export const STATS_CHARTS_URI = "stats/charts/";
