import { getReduxEntityType } from "./helpers";
import * as actions from "constants/actions/entities";
import * as status from "constants/status";

/**
 * Computes action creator
 * @param  {string} entityName - Any entity type in the system e.g. 'user', 'group' e.t.c
 * @param  {integer} entityId  - The id of the entity to be read
 * @param  {Object} options    - onSuccess and onFail function could be passed here
 * @param  {String} actionType - 'read' | 'list' | 'update' | 'create' | 'destroy'
 * @param  {Object} body       - Optional param
 */

export const commonActionCreator = (
  entityName,
  entityId,
  options = {},
  actionType,
  body
) => ({
  type: getReduxEntityType(status.REQUEST_STATUS, actionType, entityName),
  params: { pk: entityId },
  body,
  meta: {
    pk: entityId,
    entityName,
    body,
  },
  options,
});

const DEFAULT_ACTION = {
  //onSuccess: [() => displayMessage("Enregistré !")],
};

/** Action creator to get a single entity */
export const readEntity = (entityName, entityId, options) =>
  commonActionCreator(entityName, entityId, options, actions.READ_ACTION);

/** Action creator to get a list of entities*/
export const listEntities = (entityName, params, options = {}) => ({
  type: getReduxEntityType(
    status.REQUEST_STATUS,
    actions.LIST_ACTION,
    entityName
  ),
  params,
  meta: {
    entityName,
  },
  options,
});

/** Action creator to create a single entity */
export const createEntity = (entityName, body, options = DEFAULT_ACTION) => ({
  type: getReduxEntityType(
    status.REQUEST_STATUS,
    actions.CREATE_ACTION,
    entityName
  ),
  meta: {
    entityName,
    body,
  },
  options,
});

/** Action creator to update a single entity */
export const updateEntity = (
  entityName,
  entityId,
  body,
  options = DEFAULT_ACTION
) =>
  commonActionCreator(
    entityName,
    entityId,
    options,
    actions.UPDATE_ACTION,
    body
  );

/** Action creator to delete a single entity */
export const destroyEntity = (
  entityName,
  entityId,
  options = {
    //onSuccess: [() => displayMessage("Supprimé !")],
  }
) => commonActionCreator(entityName, entityId, options, actions.DESTROY_ACTION);
