import React, { Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Paper, Typography, Divider, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      background: theme.palette.background.paper,
    },
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  title: {
    flexGrow: 1,
  },
  titleAddOn: {
    padding: 0,
    paddingRight: theme.spacing(1),
  },
  divider: {},
}));

PageContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  titleAddOn: PropTypes.node,
  divider: PropTypes.bool,
};

export default function PageContainer({
  title,
  children,
  titleAddOn,
  divider,
  className,
  ...props
}) {
  const classes = useStyles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const title_div = titleAddOn ? (
    <div className={classes.titleContainer}>
      <Typography
        component="h2"
        variant="h6"
        color="primary"
        className={classes.title}
      >
        {title}
      </Typography>
      <div className={classes.titleAddOn}>{titleAddOn}</div>
    </div>
  ) : (
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      {title}
    </Typography>
  );

  const ParentTag = mdUp ? Paper : "div";

  return (
    <Fragment>
      <ParentTag className={clsx(classes.content, className)}>
        {title_div}
        {children}
      </ParentTag>
      {divider && !mdUp && <Divider className={classes.divider} />}
    </Fragment>
  );
}
