import React from "react";
import { Typography } from "@material-ui/core";
import { DEBUG } from "constants/config";

export default function Footer() {
  return (
    <Typography variant="body2" align="center">
      Icnalog Web {`${DEBUG ? "(DEBUG)" : ""}`}
    </Typography>
  );
}
