import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, CircularProgress } from "@material-ui/core";
import { Check, AlertCircleOutline } from "mdi-material-ui";

MarkedAvatar.propTypes = {
  log: PropTypes.object,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  default: {
    color: "white",
    backgroundColor: theme.palette.grey[300],
  },
  defaultSelected: {
    color: "white",
    backgroundColor: theme.palette.grey[400],
  },
  error: {
    color: theme.palette.error.dark,
    backgroundColor: theme.palette.error.light,
  },
  errorSelected: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
  },
  marked: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
  },
  markedSelected: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  loading: {
    color: theme.palette.text.disabled,
    padding: "2px",
  },
}));

export default function MarkedAvatar({ log, selected, onClick, ...props }) {
  const classes = useStyles();

  const avatarClass = () => {
    if (log.hasErrors) {
      return selected ? classes.errorSelected : classes.error;
    } else if (log.marked) {
      return selected ? classes.markedSelected : classes.marked;
    } else {
      return selected ? classes.defaultSelected : classes.default;
    }
  };

  const handleOnClick = (event) => {
    if (!log.loading && !log.hasErrors) {
      onClick(log);
    }
    event.stopPropagation();
    return false;
  };

  return (
    <Avatar {...props} onClick={handleOnClick} className={avatarClass()}>
      {!log || log.loading ? (
        <CircularProgress size={20} className={classes.loading} />
      ) : log.hasErrors ? (
        <AlertCircleOutline />
      ) : (
        <Check />
      )}
    </Avatar>
  );
}
