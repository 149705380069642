import * as status from "constants/status";
import { AUTH_TYPE, RESET_ACTION } from "constants/actions/auth";
import {
  READ_ACTION,
  LIST_ACTION,
  UPDATE_ACTION,
} from "constants/actions/entities";
import { STAT_TYPE } from "constants/actions/stats";
import {
  LICENCE_ENTITY,
  PREF_ENTITY,
  USER_ENTITY,
  TARGET_ENTITY,
} from "constants/entities";

/** returns action type for this action */
export const getReduxEntityType = (status, method, entityName) =>
  `${status}_${method}_${entityName}`.toUpperCase();

export const getReduxType = (status, action) =>
  `${status}_${action}`.toUpperCase();

export const isRequest = ({ type }) =>
  type.startsWith(`${status.REQUEST_STATUS}_`);
export const isSuccess = ({ type }) =>
  type.startsWith(`${status.SUCCESS_STATUS}`);
export const isFail = ({ type }) => type.startsWith(status.FAIL_STATUS);

export const isAuth = ({ type }) => type.includes(`_${AUTH_TYPE}_`);
export const isAction = ({ type }, action) => type.includes(`_${action}`);
export const isDownload = ({ meta }) => Boolean(meta && meta.mimeType);
export const isImport = ({ file }) => Boolean(file);
export const isStat = ({ type }) => type.includes(`_${STAT_TYPE}_`);
export const isReset = (action) => isAction(action, RESET_ACTION);

export const isReadAction = (action) =>
  isAction(action, READ_ACTION) ||
  isAction(action, LIST_ACTION) ||
  isStat(action);

export const isEntity = ({ meta }, entity) =>
  meta && meta.entityName === entity;

export const isUnique = (action) =>
  isEntity(action, LICENCE_ENTITY) ||
  isEntity(action, PREF_ENTITY) ||
  isEntity(action, USER_ENTITY) ||
  isEntity(action, TARGET_ENTITY) ||
  isStat(action);

export const targetsSingleEntity = (action) =>
  isAction(action, READ_ACTION) || isAction(action, UPDATE_ACTION);
