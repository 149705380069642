import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  useMediaQuery,
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  Slide,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ArrowLeft } from "mdi-material-ui";
import ErrorBox from "components/utils/ErrorBox";
import StateButton from "components/utils/StateButton";

import { DEFAULT_TARGETS } from "constants/targets";
import { useTargets } from "hooks/entities";
import { useDialog } from "hooks/ui";
import { useForm } from "hooks/forms";
import { updateTargets } from "actions";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  leftButton: {
    marginRight: "auto",
  },
  grid: {
    marginTop: theme.spacing(2),
  },
  numberInput: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getTitle(target_key) {
  if (target_key === "yearly_total_hours") return "Nombre d'heures mini par an";
  if (target_key === "yearly_ojti_percent") return "Part max des heures ISP";
  if (target_key === "yearly_sim_percent")
    return "Part max des heures simulateur";
  if (target_key === "yearly_zone_occurrences")
    return "Nombre d'occurrences mini par zone";
  if (target_key === "days_between_logs")
    return "Délai max entre jours travaillés";
}

function getUnit(target_key) {
  if (target_key === "yearly_total_hours") return "h";
  if (target_key === "yearly_ojti_percent") return "%";
  if (target_key === "yearly_sim_percent") return "%";
  if (target_key === "days_between_logs") return "j";
}

export default function useUpdateTargetDialog() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const { isOpen, open, close } = useDialog();
  const targets = useTargets();
  const { values, touched, handleUserInput, handleSubmit, reset } = useForm(
    DEFAULT_TARGETS,
    () => {
      if (!targets.loading) {
        dispatch(updateTargets(values));
      }
    }
  );

  useEffect(() => {
    if (!targets.loading && !targets.hasErrors) {
      reset(targets.data);
    }
  }, [targets, reset]);

  const resetValues = () => {
    reset(DEFAULT_TARGETS);
  };

  const hasErrors = (attribute) =>
    Boolean(targets.errors && targets.errors[attribute]) && !touched[attribute];

  const dialog_content = (
    <DialogContent>
      <DialogContentText align="justify">
        Vous pouvez modifier ci-dessous les objectifs d'heures de contrôle et
        d'occurrences pour les adapter aux valeurs en vigueur pour votre mention
        d'unité.
      </DialogContentText>
      <DialogContentText align="justify">
        Revenez aux valeurs nationales grâce au bouton "Valeurs par défaut".
      </DialogContentText>
      {targets.success && (
        <Alert className={classes.alert} severity="success">
          Vos objectifs ont été enregistrés !
        </Alert>
      )}
      <ErrorBox errorList={[targets.errors.non_field_errors]} />
      <Grid container spacing={2} className={classes.grid}>
        {Object.keys(DEFAULT_TARGETS).map((key) => (
          <Grid item xs={12} md={6} lg={4} key={key}>
            <TextField
              name={key}
              label={getTitle(key)}
              value={values[key]}
              type="number"
              error={hasErrors(key)}
              helperText={hasErrors(key) && targets.errors[key]}
              onChange={handleUserInput}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: getUnit(key) && (
                  <InputAdornment position="end">{getUnit(key)}</InputAdornment>
                ),
              }}
              className={classes.numberInput}
              variant="outlined"
            />
          </Grid>
        ))}
      </Grid>
    </DialogContent>
  );

  const dialog_actions = (
    <DialogActions>
      <Button
        onClick={() => resetValues()}
        className={classes.leftButton}
        color="primary"
      >
        Valeurs par défaut
      </Button>
      {mdUp && (
        <Button onClick={close} color="primary">
          Fermer
        </Button>
      )}
      <StateButton
        state={targets}
        onClick={handleSubmit}
        color="primary"
        title="Enregistrer"
      />
    </DialogActions>
  );

  const small_display = (
    <Dialog
      open={isOpen}
      fullScreen
      onClose={close}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Tooltip title="Fermer" arrow>
            <IconButton
              edge="start"
              color="inherit"
              className={classes.leftButton}
              onClick={close}
            >
              <ArrowLeft />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      {dialog_content}
      {dialog_actions}
    </Dialog>
  );

  const large_display = (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="md"
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Modifier les butées</DialogTitle>
      {dialog_content}
      {dialog_actions}
    </Dialog>
  );

  const display = mdUp ? large_display : small_display;

  return { open, display };
}
