export const REQUEST_STATUS = "REQUEST";
export const SUCCESS_STATUS = "SUCCESS";
export const FAIL_STATUS = "FAIL";

export const READ_PROFILE_STATE = "readMe";
export const RESET_ACCOUNT_STATE = "reset";
export const DELETE_PROFILE_STATE = "deleteMe";
export const SEND_ADMIN_MESSAGE_STATE = "contact";
export const RESET_PASSWORD_STATE = "reset_password";
export const CONFIRM_PASSWORD_STATE = "confirm_password_reset";
export const SET_PASSWORD_STATE = "set_password";
export const LOGIN_STATE = "login";
export const LOGOUT_STATE = "logout";
export const REGISTER_STATE = "register";
export const RESEND_ACTIVATION_STATE = "resend_activation";
export const ACTIVATION_STATE = "activate";
export const DOWNLOAD_ALL_STATE = "download_all";
export const EXPORT_STATE = "export";
export const IMPORT_CSV_STATE = "import_csv";
