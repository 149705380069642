import { createBrowserHistory } from "history";
import { createStore, compose, applyMiddleware } from "redux";
import { logger } from "redux-logger";
import { routerMiddleware } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import localForage from "localforage"; //localstorage by default
import { encryptTransform } from "redux-persist-transform-encrypt";

import { ENCRYPTION_KEY, DEBUG } from "./constants/config";
import createRootReducer from "./reducers";
import middlewares from "./middlewares";

//Single Page App : simulates history and back browser actions
const history = createBrowserHistory();

//Persists part of state in local storage and encrypted
const encryptor = encryptTransform({
  secretKey: ENCRYPTION_KEY,
  onError: (error) => {
    if (persistor) persistor.purge();
    console.log(error);
    throw new Error(
      "Error while decrypting stored state. State has been purged."
    );
  },
});
const persistConfig = {
  key: "root",
  storage: localForage,
  whitelist: ["token", "me", "ui"],
  transforms: [encryptor],
};
const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

let persistor;

const middleware_list = [routerMiddleware(history), ...middlewares];
if (DEBUG) {
  middleware_list.push(logger);
}

//DevTools in browser
const composeEnhancers = /*DEBUG
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  :*/ compose;

const configureStore = () => {
  let store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middleware_list))
  );
  persistor = persistStore(store);
  return { store, persistor, history };
};

export default configureStore;
