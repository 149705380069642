import React from "react";
import { useDispatch } from "react-redux";
import { Button, TextField, Grid, Typography, Link } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import AuthPage from "./AuthPage";
import { ROUTES } from "routes";
import { useAuthState } from "hooks";
import { useForm } from "hooks/forms";
import { RESEND_ACTIVATION_STATE } from "constants/status";
import { resend_activation } from "actions/auth";

const useStyles = makeStyles((theme) => ({
  infoBox: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  alert: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function RequestActivation() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, errors, success } = useAuthState(RESEND_ACTIVATION_STATE);
  const { values, handleUserInput, handleSubmit } = useForm(
    {
      email: "",
    },
    ({ email }) => dispatch(resend_activation(email))
  );

  const errorBox = (
    <div className={classes.infoBox}>
      <Alert className={classes.alert} severity="error" align="justify">
        Il n'existe pas de compte non validé pour cette adresse.
      </Alert>
      <Alert className={classes.alert} severity="info" align="justify">
        Si vous avez déjà validé votre compte,{" "}
        <Link component={RouterLink} to={ROUTES.login.path}>
          cliquez ici pour vous connecter
        </Link>
      </Alert>
    </div>
  );

  const successBox = (
    <div className={classes.infoBox}>
      <Typography className={classes.alert} align="justify">
        Nous vous avons envoyé un e-mail de validation à l'adresse{" "}
        {values.email}. Cliquez sur le lien fourni dans l'e-mail pour terminer
        l'inscription.
      </Typography>
      <Typography className={classes.alert} align="justify">
        Vérifiez votre dossier "Spam". Si vous ne le recevez pas d'ici quelques
        minutes, rafraîchissez cette page pour demander un nouvel e-mail.
      </Typography>
    </div>
  );

  return (
    <AuthPage loading={loading} title="Renvoyer l'email de validation">
      {errors.non_field_errors && errorBox}
      {success && successBox}
      {!success && (
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Adresse e-mail"
            name="email"
            autoComplete="email"
            autoFocus
            value={values.email}
            onChange={handleUserInput}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Se connecter
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                component={RouterLink}
                to={ROUTES.reset_password.path}
                variant="body2"
              >
                Mot de passe oublié ?
              </Link>
            </Grid>
            <Grid item>
              <Link
                component={RouterLink}
                to={ROUTES.login.path}
                variant="body2"
              >
                Se connecter
              </Link>
            </Grid>
          </Grid>
        </form>
      )}
    </AuthPage>
  );
}
