import { batch } from "react-redux";
import api from "services/api";
import { getMethod } from "./helpers";
import * as status from "constants/status";
import { getAuthToken } from "selectors/user";
import { force_logout } from "actions/auth";
import { displayMessage } from "actions/ui";
import { DEBUG } from "constants/config";

/**
 * Middleware to do the api call and send success or fail action
 * depending on response
 */
const apiMiddleware = (store) => (next) => (action) => {
  const { meta } = action;
  let method = getMethod(action);
  if (method) {
    if (meta.stale) {
      api(method, action, getAuthToken(store.getState()))
        .then((response) => {
          store.dispatch({
            type: action.type.replace(
              status.REQUEST_STATUS,
              status.SUCCESS_STATUS
            ),
            payload: response,
            meta: { ...action.meta, params: action.params },
          });
          return response;
        })
        .then((response) => {
          handleSuccessOptions(action, response, store);
          return response;
        })
        .catch((error) => {
          const { response } = error;
          if (DEBUG) {
            console.error(error, response, action);
          }
          if (response && response.status === 503) {
            // 503 Service Unavailable
            window.location.reload(true);
            return;
          }
          if (response && response.status === 401) {
            //401 Unauthorized
            store.dispatch(force_logout());
            return;
          }
          const error_message = generateErrorMessage(error);
          store.dispatch({
            type: action.type.replace(
              status.REQUEST_STATUS,
              status.FAIL_STATUS
            ),
            error: error_message,
            meta: { ...action.meta, params: action.params },
          });
          handleFailOptions(action, error_message, store);
        });
    }
  }

  next(action);
};

function handleSuccessOptions({ options }, response, store) {
  if (options && options.onSuccess) {
    const { onSuccess } = options;
    if (Array.isArray(onSuccess)) {
      batch(() => {
        onSuccess.forEach((callback) => {
          store.dispatch(callback(response));
        });
      });
    } else {
      store.dispatch(options.onSuccess(response));
    }
  }
}

function handleFailOptions({ options, error }, error_message, store) {
  if (options) {
    const { onFail, notifyOnFail } = options;
    if (onFail) {
      onFail(error);
    }
    if (notifyOnFail) {
      let message = error_message;
      if (!(typeof message === "string" || message instanceof String)) {
        message = Object.values(message).join(", ");
      }
      store.dispatch(displayMessage(`${notifyOnFail} : ${message}`));
    }
  }
}

function generateErrorMessage(error) {
  const { response, message } = error;
  if (response) {
    if (response.data) {
      const errors = {};
      if (
        typeof response.data === "string" ||
        response.data instanceof String
      ) {
        errors[
          "non_field_errors"
        ] = `${response.status} - ${response.statusText}`;
      } else {
        Object.keys(response.data).forEach((key) => {
          const value = response.data[key];
          errors[key] = Array.isArray(value) ? value.join(" ") : value;
        });
      }
      return errors;
    } else if (response.status >= 500) {
      return `Erreur serveur (${response.status} ${response.statusText}) : rafraîchissez la page ou réessayez plus tard.`;
    } else {
      return `${response.status} - ${response.statusText}`;
    }
  } else {
    return `Erreur serveur (${message}) : rafraîchissez la page ou réessayez plus tard.`;
  }
}

export default apiMiddleware;
