import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import pick from "lodash.pick";
import { Typography, Link } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link as RouterLink, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import AuthPage from "./AuthPage";
import ErrorBox from "components/utils/ErrorBox";
import { ROUTES } from "routes";
import { useAuthState } from "hooks";
import { ACTIVATION_STATE } from "constants/status";
import { activate } from "actions/auth";

const useStyles = makeStyles((theme) => ({
  infoBox: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  alert: {
    marginTop: theme.spacing(1),
    width: "100%",
    align: "justify",
  },
}));

export default function Activate() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { uid, token } = useParams();
  const { loading, success, hasErrors, errors } = useAuthState(
    ACTIVATION_STATE
  );

  const errorBox = (
    <div className={classes.infoBox}>
      <ErrorBox
        className={classes.alert}
        errorDict={pick(errors, ["non_field_errors", "uid", "token"])}
        noKeys
      />
      <Typography className={classes.alert}>
        Si vous avez déjà confirmé votre adresse,{" "}
        <Link component={RouterLink} to={ROUTES.login.path}>
          cliquez ici pour vous connecter
        </Link>
      </Typography>
      <Typography className={classes.alert}>
        Sinon, ce lien a vraisemblablement expiré.{" "}
        <Link component={RouterLink} to={ROUTES.re_activate.path}>
          Cliquez ici pour demander un nouvel email de validation.
        </Link>
      </Typography>
    </div>
  );

  const successBox = (
    <div className={classes.infoBox}>
      <Alert className={classes.alert} severity="success">
        Votre adresse est validée !
      </Alert>
      <Typography className={classes.alert}>
        <Link component={RouterLink} to={ROUTES.login.path}>
          Cliquez ici pour vous connecter
        </Link>
      </Typography>
    </div>
  );

  const loadingBox = (
    <div className={classes.infoBox}>
      <Alert className={classes.alert} severity="info">
        Validation en cours...
      </Alert>
    </div>
  );

  useEffect(() => {
    dispatch(activate(uid, token));
  }, [dispatch, uid, token]);

  return (
    <AuthPage loading={loading} title="Validation de l'adresse e-mail">
      {hasErrors && errorBox}
      {success && successBox}
      {!success && !hasErrors && loadingBox}
    </AuthPage>
  );
}
