import { getReduxType } from "./helpers";
import * as status from "constants/status";
import * as actions from "constants/actions/auth";
import { displayMessage } from "./ui";

const authActionCreator = (actionType, body, options = {}) => ({
  type: getReduxType(status.REQUEST_STATUS, actionType),
  body,
  meta: {
    body,
  },
  options,
});

export const login = (email, password) =>
  authActionCreator(actions.LOGIN_ACTION, { email, password });
export const logout = () =>
  authActionCreator(
    actions.LOGOUT_ACTION,
    {},
    { onSuccess: () => displayMessage("Vous avez été déconnecté.") }
  );

export const force_logout = () => ({
  type: getReduxType(status.SUCCESS_STATUS, actions.LOGOUT_ACTION),
});

export const activate = (uid, token) =>
  authActionCreator(actions.ACTIVATE_ACTION, { uid, token });
export const resend_activation = (email) =>
  authActionCreator(actions.RE_ACTIVATE_ACTION, { email });

export const set_password = (current_password, new_password, re_new_password) =>
  authActionCreator(actions.SET_PASSWORD_ACTION, {
    current_password,
    new_password,
    re_new_password,
  });
export const reset_password = (email) =>
  authActionCreator(actions.RESET_PASSWORD_ACTION, { email });

export const confirm_password_reset = (
  uid,
  token,
  new_password,
  re_new_password
) =>
  authActionCreator(actions.RESET_PASSWORD_CONFIRM_ACTION, {
    uid,
    token,
    new_password,
    re_new_password,
  });
