import { useState } from "react";
import { useSelector } from "react-redux";
import {
  isDrawerOpen,
  getMessage,
  getSimVisibility,
  getDualVisibility,
} from "selectors/ui";

export function useDrawerOpen() {
  return useSelector(isDrawerOpen);
}

export function useMessage() {
  return useSelector(getMessage);
}

export function useSimVisibility() {
  return useSelector(getSimVisibility).visible;
}

export function useDualVisibility() {
  return useSelector(getDualVisibility).visible;
}

export function useDialog() {
  const [isOpen, setOpen] = useState(false);

  const open = () => setOpen(true);

  const close = () => setOpen(false);

  return { isOpen, open, close };
}

export function useTab(default_tab) {
  const [tab, setTab] = useState(default_tab);

  const onChange = (e, new_tab) => setTab(new_tab);

  return { tab, onChange };
}
