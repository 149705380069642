import React, { useEffect, Fragment, useCallback } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  List,
  ListItem,
  ListSubheader,
  Tooltip,
  Fab,
  CircularProgress,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Pencil, AlertCircleOutline } from "mdi-material-ui";

import PageContainer from "components/utils/PageContainer";
import ErrorBox from "components/utils/ErrorBox";
import Progress from "./Progress";
import useUpdateTargetDialog from "./TargetDialog";

import { readDeadlines } from "actions/stats";
import { useDeadlines, useTargets } from "hooks/entities";
import { isStale } from "middlewares/staleness";

const useStyles = makeStyles((theme) => ({
  content: {},
  list: {},
  skeleton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const SkeletonDeadlines = ({ size }) => {
  const classes = useStyles();
  return [...Array(size)].map((x, i) => (
    <ListItem key={i}>
      <Skeleton height={10} width="100%" className={classes.skeleton} />
    </ListItem>
  ));
};

export default function Deadlines() {
  const dispatch = useDispatch();
  const deadlines = useDeadlines();
  const targets = useTargets();
  const targetDialog = useUpdateTargetDialog();
  const classes = useStyles();

  const isLoading = useCallback(() => deadlines.loading || targets.loading, [
    deadlines,
    targets,
  ]);

  useEffect(() => {
    if (isStale(deadlines.updated_at) || isStale(targets.updated_at))
      dispatch(readDeadlines());
  }, [dispatch, deadlines.updated_at, targets.updated_at]);

  const title_button = (
    <Tooltip title="Modifier les butées" arrow placement="top">
      <span>
        <Fab
          color={targets.hasErrors ? "default" : "primary"}
          size="small"
          onClick={targetDialog.open}
          disabled={isLoading()}
          className={classes.titleButton}
        >
          {targets.loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : targets.hasErrors ? (
            <AlertCircleOutline color="error" fontSize="small" />
          ) : (
            <Pencil fontSize="small" />
          )}
        </Fab>
      </span>
    </Tooltip>
  );

  return (
    <PageContainer title="Butées" titleAddOn={title_button}>
      <ErrorBox errorDict={deadlines.errors} />
      <Grid container className={classes.content} justify="center">
        <Grid item xs={12} md={6} lg={4}>
          <List dense disablePadding className={classes.list}>
            <ListSubheader>Général</ListSubheader>
            {!deadlines.data ? (
              <SkeletonDeadlines size={3} />
            ) : (
              <Fragment>
                <Progress
                  label="Total d'heures"
                  units="h"
                  {...deadlines.data.total_hours}
                />
                <Progress
                  label="Non travaillé"
                  units="j"
                  {...deadlines.data.days_since_last_log}
                  barStyle="dual"
                  tooltip="Nombre de jours écoulés depuis le dernier jour de travail"
                />
                <Progress
                  label="Renouvellement dans"
                  units="j"
                  {...deadlines.data.days_to_renewal}
                  barStyle="secondary"
                  disableTarget
                />
              </Fragment>
            )}
          </List>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <List dense disablePadding className={classes.list}>
            <ListSubheader>Types d'heures</ListSubheader>
            {!deadlines.data ? (
              <SkeletonDeadlines size={2} />
            ) : (
              <Fragment>
                <Progress
                  label="Heures ISP"
                  units="%"
                  {...deadlines.data.ojti_part}
                  barStyle="ojti"
                  tooltip="Part des heures ISP sur le total d'heures"
                />
                <Progress
                  label="Heures Simu"
                  units="%"
                  {...deadlines.data.sim_part}
                  barStyle="sim"
                  tooltip="Part des heures de simu sur le total d'heures"
                />
              </Fragment>
            )}
          </List>
        </Grid>
        {(!deadlines.data ||
          Object.keys(deadlines.data.occurrences).length > 0) && (
          <Grid item xs={12} md={6} lg={4}>
            <List dense disablePadding className={classes.list}>
              <ListSubheader>Occurrences</ListSubheader>
              {!deadlines.data ? (
                <SkeletonDeadlines size={2} />
              ) : (
                Object.entries(
                  deadlines.data.occurrences
                ).map(([key, occ], i) => (
                  <Progress
                    key={key}
                    label={key}
                    {...occ}
                    units=""
                    barStyle="dynamic"
                    index={i}
                  />
                ))
              )}
            </List>
          </Grid>
        )}
      </Grid>
      {targetDialog.display}
    </PageContainer>
  );
}
