import React, { useState } from "react";
import { useDialog } from "hooks/ui";
import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";

export default function useDeleteDialog(onConfirm) {
  const { isOpen, open, close } = useDialog();
  const [zone, setZone] = useState({ id: "", name: "" });

  const onClose = (confirmed) => {
    if (confirmed && zone && zone.id) {
      onConfirm(zone);
    }
    close();
  };

  const openWithZone = (zone) => {
    setZone(zone);
    open();
  };

  const display = (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Supprimer la zone {`${zone.name}`} ?
      </DialogTitle>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Annuler
        </Button>
        <Button onClick={() => onClose(true)} color="primary" autoFocus>
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );

  return { open: openWithZone, display };
}
