import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Tooltip } from "@material-ui/core";
import { Text } from "mdi-material-ui";

import { totalTime, totalOcc } from "./utils";

const useStyles = makeStyles((theme) => ({
  noteIndicator: {
    verticalAlign: "middle",
    fontSize: 20,
  },
}));

export default function LogSummary({ log, ...props }) {
  const classes = useStyles();
  return (
    <Fragment>
      <Typography
        {...props}
        variant="body2"
        color="textSecondary"
        component="span"
      >
        {`${totalTime(log)} • ${totalOcc(log)} occurences`}
        {log.note && ` • `}
      </Typography>
      {log.note && (
        <Tooltip title={log.note} arrow>
          <Text color="disabled" className={classes.noteIndicator} />
        </Tooltip>
      )}
    </Fragment>
  );
}
