import { combineReducers } from "redux";
import * as ui_actions from "constants/actions/ui";
import { PREF_ENTITY } from "constants/entities";
import { UPDATE_ACTION, READ_ACTION } from "constants/actions/entities";
import { isRequest, isSuccess, isAction, isEntity } from "actions/helpers";
import { getUpdatedAt, getStatus } from "./helpers";

const INITIAL_STATE = {
  message: "",
};

const message = (state = INITIAL_STATE, action) => {
  const { type } = action;
  if (type === ui_actions.DISPLAY_MESSAGE_ACTION) {
    return { ...state, message: action.message };
  }
  if (type === ui_actions.CLEAR_MESSAGE_ACTION) {
    return { ...state, message: "" };
  }
  return state;
};

const PREF_INITIAL_STATE = {
  visible: true,
  errors: {},
  hasErrors: false,
  loading: false,
};

const displayPref = (pref) => (state = PREF_INITIAL_STATE, action) => {
  //store data in state on request. Don't change it back on success
  const { body, payload } = action;
  if (isEntity(action, PREF_ENTITY)) {
    let visible = state.visible;
    if (isRequest(action) && isAction(action, UPDATE_ACTION))
      visible = body[pref];
    else if (isSuccess(action) && isAction(action, READ_ACTION)) {
      visible = payload[pref];
    }
    return {
      ...getStatus(action),
      visible,
      updated_at: getUpdatedAt(action),
    };
  }
  return state;
};

const drawer = (state = { open: false }, action) => {
  const { type } = action;
  if (type === ui_actions.DRAWER_STATE_ACTION) {
    return { ...state, open: action.open };
  }
  return state;
};

const ui = combineReducers({
  message,
  drawer,
  visibility_sim: displayPref("display_sim"),
  visibility_dual: displayPref("display_dual"),
});

export default ui;
