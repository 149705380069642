import React, { useState } from "react";
import moment from "moment";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useDialog } from "hooks/ui";

export const useNoteEditDialog = (onConfirm) => {
  const { isOpen, open, close } = useDialog();
  const [current_log, setCurrentLog] = useState();
  const [value, setValue] = React.useState("");

  const handleUserInput = (e) => {
    setValue(e.target.value);
  };

  const handleOpen = (log) => {
    setCurrentLog(log);
    setValue(log ? log.note : "");
    open();
  };

  const onClose = (confirmed) => {
    if (confirmed) {
      onConfirm(current_log, value);
      setValue("");
    }
    close();
  };

  const display = (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="sm"
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Annoter le log du{" "}
        {current_log && `${moment(current_log.date).format("DD/MM/YYYY")}`}
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          id="name"
          name="name"
          label="Note"
          required
          value={value}
          onChange={handleUserInput}
          autoFocus={open}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Annuler
        </Button>
        <Button onClick={() => onClose(true)} color="primary">
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  );
  return { open: handleOpen, display };
};
