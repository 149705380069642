import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

import { ROUTES, ERROR_ROUTES } from "routes";
import { useAuthenticated } from "hooks/user";

/** Redirects to login if private and not authenticated
 *   Redirects to home if public and authenticated
 */
function AuthRoute({
  children,
  is_private,
  is_public,
  is_authenticated,
  ...rest
}) {
  if (is_private === undefined && is_public === undefined) {
    return <Route {...rest} render={({ location }) => children} />;
  } else if (is_private) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          is_authenticated === true ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: ROUTES.login.path,
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  } else if (is_public) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          is_authenticated === false ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: ROUTES.home.path,
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }
}

AuthRoute.propTypes = {
  children: PropTypes.any,
  is_private: PropTypes.bool,
  is_authenticated: PropTypes.bool,
  rest: PropTypes.object,
};

export default function Routing() {
  const is_authenticated = useAuthenticated();
  return (
    <Switch>
      {Object.values(ROUTES).map(
        ({ path, exact, is_public, is_private, component }, i) => (
          <AuthRoute
            key={i}
            path={path}
            exact={exact || false}
            is_authenticated={is_authenticated}
            is_private={is_private}
            is_public={is_public}
          >
            {component}
          </AuthRoute>
        )
      )}
      {Object.values(ERROR_ROUTES).map(({ path, component }, i) => (
        <Route key={i} path={path}>
          {component}
        </Route>
      ))}
    </Switch>
  );
}
