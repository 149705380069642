import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@material-ui/core";
import { useDialog } from "hooks/ui";
import { useForm } from "hooks/forms";

export default function useCreateDialog(onConfirm) {
  const { isOpen, open, close } = useDialog();
  const { values, handleUserInput, reset } = useForm({ name: "" });

  const onClose = (confirmed) => {
    if (confirmed && values.name) {
      onConfirm(values.name);
      reset({ name: "" });
    }
    close();
  };

  const display = (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="sm"
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Créer une nouvelle zone</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          id="name"
          name="name"
          label="Nom de la zone"
          required
          value={values.name}
          onChange={handleUserInput}
          autoFocus={isOpen}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Annuler
        </Button>
        <Button onClick={() => onClose(true)} color="primary">
          Créer
        </Button>
      </DialogActions>
    </Dialog>
  );
  return { open, display };
}
