import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import {
  Menu,
  MenuItem,
  CircularProgress,
  IconButton,
  ListSubheader,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  AlertCircleOutline,
  AccountCircle,
  HelpCircleOutline,
} from "mdi-material-ui";

import { Link as RouterLink } from "react-router-dom";
import { ROUTES } from "routes";
import { READ_PROFILE_STATE } from "constants/status";
import { logout } from "actions/auth";
import { read_me } from "actions/user";
import { useAuthState } from "hooks";
import { useMe } from "hooks/user";

const useStyles = makeStyles((theme) => ({
  menu: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  menuItem: {
    //marginRight: theme.spacing(1),
  },
  username: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

export default function UserMenu() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { email } = useMe();
  const { hasErrors, loading, errors } = useAuthState(READ_PROFILE_STATE);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    if (!loading) {
      dispatch(read_me());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleUserMenuClose();
    dispatch(logout());
  };

  const subheader = (
    <ListSubheader>
      {hasErrors && (
        <IconButton size="small" disabled>
          <AlertCircleOutline color="error" />
        </IconButton>
      )}
      {hasErrors ? errors.non_field_errors : email}
    </ListSubheader>
  );

  const popmenu = (
    <Menu
      id="usermenu-appbar"
      anchorEl={anchorEl}
      keepMounted
      getContentAnchorEl={null} /* to correct bug with anchorOrigin*/
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleUserMenuClose}
      MenuListProps={{
        subheader,
      }}
    >
      <MenuItem
        component={RouterLink}
        to={ROUTES.account.path}
        onClick={handleUserMenuClose}
      >
        Mon compte
      </MenuItem>
      <MenuItem onClick={handleLogoutClick}>Déconnexion</MenuItem>
    </Menu>
  );

  const error_display = <AlertCircleOutline />;
  const loading_display = <CircularProgress color="inherit" size={20} />;

  return (
    <div className={classes.menu}>
      <Link
        component={RouterLink}
        to={ROUTES.account.path}
        color="inherit"
        className={clsx(classes.menuItem, classes.username)}
      >
        {email}
      </Link>
      <IconButton
        aria-label="account of current user"
        aria-controls="usermenu-appbar"
        aria-haspopup="true"
        onClick={handleUserMenuOpen}
        className={classes.menuItem}
        color="inherit"
      >
        {hasErrors && error_display}
        {loading && loading_display}
        {!loading && !hasErrors && <AccountCircle />}
      </IconButton>
      <IconButton
        component={RouterLink}
        to={ROUTES.help.path}
        color="inherit"
        edge="end"
      >
        <HelpCircleOutline />
      </IconButton>
      {popmenu}
    </div>
  );
}
