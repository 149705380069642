import { useState, useRef, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import debounce from "lodash.debounce";

import { getAuthState } from "selectors/status";

export function useStateWithRef(initial_value) {
  const ref = useRef(initial_value);
  const [value, setValue] = useState(initial_value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return [value, setValue, ref];
}

export function useAuthState(type) {
  return useSelector(getAuthState(type));
}

export function useCurrentDate() {
  return useParams().date;
}

export function useDebounce(callback, delay) {
  // Memoizing the callback because if it's an arrow function
  // it would be different on each render
  // FIXME dependency array must be empty so that function does not change on each render but it triggers lint
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCallback = useCallback(callback, []);
  const debouncedFn = useRef(debounce(memoizedCallback, delay));

  useEffect(() => {
    debouncedFn.current = debounce(memoizedCallback, delay);
  }, [memoizedCallback, debouncedFn, delay]);

  return debouncedFn.current;
}

export function usePageTracker() {
  const { trackPageView } = useMatomo();
  const location = useLocation();

  useEffect(() => {
    trackPageView({ href: location.pathname });
  }, [location, trackPageView]);
}
