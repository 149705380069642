import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Doughnut } from "react-chartjs-2";

import PageContainer from "components/utils/PageContainer";
import LoadingGraph from "./LoadingGraph";
import { getPalette } from "components/Theming";

PieGraph.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  formatLabel: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "initial",
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
  },
  skeleton: {
    paddingBottom: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      height: "100%",
      display: "flex",
      alignItems: "stretch",
    },
  },
}));

export default function PieGraph({ data, title, formatLabel, ...props }) {
  const classes = useStyles();
  const theme = useTheme();
  const [formatedData, setFormatedData] = useState({});

  useEffect(() => {
    if (data) {
      const colors = data.label.map((l, i) => getPalette(theme, l, i));
      setFormatedData({
        datasets: [
          {
            data: data.data,
            total: data.total,
            label: data.label.map((l) => (formatLabel ? formatLabel(l) : l)),
            borderWidth: 1,
            backgroundColor: colors.map((c) => c.light),
            borderColor: colors.map((c) => c.dark),
            hoverBackgroundColor: colors.map((c) => c.main),
          },
        ],
      });
    } else {
      setFormatedData({});
    }
  }, [data, formatLabel, theme]);

  const format_percentage = (value, total) =>
    `${((value * 100) / total).toFixed(2)}%`;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const dataset = data.datasets[0];
          const i = tooltipItem.index;
          return `${dataset.label[i]} : ${format_percentage(
            dataset.data[i],
            dataset.total
          )}`;
        },
      },
    },
    title: {
      display: false,
    },
    legend: {
      position: "right",
      labels: {
        usePointStyle: true,
        boxWidth: 5,
        generateLabels: (chart) => {
          const data = chart.data.datasets[0];
          return !data
            ? []
            : data.data.map((d, i) => ({
                text: data.label[i],
                fillStyle: data.backgroundColor[i],
              }));
        },
      },
    },
  };

  return (
    <PageContainer title={title} className={classes.container} {...props}>
      <div className={classes.content}>
        {!data ? (
          <LoadingGraph className={classes.skeleton} />
        ) : (
          <Doughnut data={formatedData} options={options} />
        )}
      </div>
    </PageContainer>
  );
}
