import { getReduxType } from "./helpers";
import * as status from "constants/status";
import * as actions from "constants/actions/stats";

export const readDeadlines = () => ({
  type: getReduxType(status.REQUEST_STATUS, actions.STATS_DEADLINES_ACTION),
  meta: {},
});

export const readStats = (params = {}) => ({
  type: getReduxType(status.REQUEST_STATUS, actions.STATS_CHARTS_ACTION),
  params,
  meta: {},
});
