import React, { useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import ResponsiveButton from "./ResponsiveButton";
import { HourIcon } from "./HourUtils";
import { HOUR_SIM, HOUR_DUAL } from "constants/hours";

import { useSimVisibility, useDualVisibility } from "hooks/ui";
import {
  readVisibilityPrefs as read,
  updateVisibilityPrefs as update,
} from "actions/ui";

const useStyles = makeStyles((theme) => ({
  toolbarItem: {
    marginRight: theme.spacing(1),
  },
}));

export default function VisibilityToolbox() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sim = useSimVisibility();
  const dual = useDualVisibility();

  //request fresh data
  useEffect(() => {
    dispatch(read());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSimClick = () => {
    dispatch(update(!sim, dual));
  };

  const onDualClick = () => {
    dispatch(update(sim, !dual));
  };

  return (
    <Fragment>
      <ResponsiveButton
        size="small"
        text="Simu"
        startIcon={<HourIcon type={HOUR_SIM.type} />}
        active={sim}
        className={classes.toolbarItem}
        onClick={onSimClick}
      />
      <ResponsiveButton
        size="small"
        text="Double"
        active={dual}
        startIcon={<HourIcon type={HOUR_DUAL.type} />}
        className={classes.toolbarItem}
        onClick={onDualClick}
      />
    </Fragment>
  );
}
